import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import {
  headingBold,
  pText,
  mainRed,
  screen,
  pTextBold,
  mainGreen,
} from "../common/variables";
import background from "../../images/footer/footer-background.png";
import logo from "../../images/footer/footer-logo.svg";
import { FaFacebookSquare, FaTwitterSquare, FaInstagram } from "react-icons/fa";

const Wrapper = styled.div`
  display: none;
  position: relative;
  width: 100%;
  background: white;
  z-index: 2;
  @media ${screen.medium} {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .top-section {
    position: absolute;
    bottom: 80px;
    right: 0;
    height: 550px;
    width: 400px;
    background: url(${background}) no-repeat bottom;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media ${screen.medium} {
      width: 500px;
      height: 400px;
      bottom: 59px;
    }
    @media ${screen.xLarge} {
      width: calc(400px + 15vw);
    }

    &__box {
      width: 400px;
      padding-left: 80px;
      padding-right: 20px;
      @media ${screen.xLarge} {
        width: calc(400px + 11vw);
      }
      h1 {
        font-family: ${headingBold};
        font-size: 1.5625rem;
        color: ${mainRed};
        @media ${screen.xLarge} {
          font-size: 2.2rem;
        }
      }

      &__first-line {
        padding-top: 20px;
        font-family: ${pText};
        font-size: 1rem;
        color: ${mainRed};
        @media ${screen.xLarge} {
          font-size: 1.1rem;
        }
      }

      &__second-line {
        padding-top: 10px;
        padding-bottom: 20px;
        font-family: ${pText};
        font-size: 1rem;
        color: ${mainRed};
        @media ${screen.xLarge} {
          font-size: 1.1rem;
          padding-bottom: 30px;
        }
      }

      span {
        font-family: ${pText};
        font-size: 1rem;
        color: ${mainRed};
        width: auto;
        padding: 10px 44px;
        border: solid 2px ${mainRed};
        transition: color, background-color 0.2s linear;
        :hover {
          cursor: pointer;
          color: white;
          background-color: ${mainRed};
        }
      }
    }
  }

  .middle-section {
    width: 100%;
    height: 500px;
    background-color: ${mainRed};
    display: flex;
    justify-content: center;
    align-items: center;
    @media ${screen.medium} {
      height: 350px;
    }

    &__container {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 1100px;
      height: 100%;
      background-color: ${mainRed};
      margin: 0 auto;
      padding: 20px 340px 20px 0;
      @media ${screen.medium} {
        align-items: flex-start;
      }
      @media ${screen.large} {
        align-items: center;
      }
      &__logo-box {
        margin: 0;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        background-color: ${mainRed};
        @media ${screen.medium} {
          margin: 0 20px 20px 20px;
        }

        img {
          max-width: 96px;
          width: 100%;
          max-height: 136px;
          height: 100%;
        }
      }

      &__flex-area {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        @media ${screen.large} {
          flex-direction: row;
        }

        &__foundation-box {
          max-width: 380px;
          margin-bottom: 20px;
          background-color: ${mainRed};
          @media ${screen.medium} {
            margin: 0 20px 20px 20px;
          }
          h1 {
            font-family: ${pTextBold};
            font-size: 1.125rem;
            color: white;
            padding-bottom: 10px;
            @media ${screen.medium} {
              padding-bottom: 20px;
            }
          }

          p {
            font-family: ${pText};
            font-size: 0.8rem;
            line-height: 1.8;
            color: white;
          }
        }

        &__contact-box {
          max-width: 380px;
          margin-bottom: 20px;
          background-color: ${mainRed};
          @media ${screen.medium} {
            margin: 0 20px 20px 20px;
          }

          &__text-area {
            width: 100%;
            padding-bottom: 10px;
            h1 {
              font-family: ${pTextBold};
              font-size: 1.125rem;
              color: white;
              padding-bottom: 10px;
              @media ${screen.medium} {
                padding-bottom: 20px;
              }
            }

            p {
              font-family: ${pText};
              font-size: 0.8rem;
              line-height: 1.8;
              color: white;
              padding-bottom: 5px;
            }

            a {
              padding-bottom: 2px;
              font-family: ${pText};
              font-size: 0.8rem;
              color: white;
              :hover {
                border-bottom: solid 1px white;
              }
            }
          }

          &__icon-area {
            width: 100%;

            &__icon {
              width: 35px;
              height: 35px;
              margin-right: 15px;
              color: white;
              transition: color 0.2s linear;
              :hover {
                color: ${mainGreen};
              }
            }
          }
        }
      }
    }
  }

  .bottom-section {
    width: 100%;
    height: 80px;
    padding: 0 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media ${screen.medium} {
      height: 60px;
      flex-direction: row;
      justify-content: space-around;
    }

    &__first-line {
      text-align: center;
      padding-bottom: 10px;
      color: ${mainRed};
      font-family: ${pText};
      font-size: 0.8rem;
      @media ${screen.medium} {
        padding: 0;
      }

      span {
        white-space: nowrap;
      }
    }

    &__second-line {
      text-align: center;
      color: ${mainRed};
      font-family: ${pText};
      font-size: 0.8rem;

      a {
        color: black;
      }
    }
  }
`;

const MainFooter = () => {
  return (
    <Wrapper>
      <div className="top-section">
        <div className="top-section__box">
          <h1>Help fund the work of</h1>
          <h1>The Lysicrates Foundation</h1>
          <p className="top-section__box__first-line">
            The Lysicrates Foundation is a not-for-profit organisation and its
            events are free to attend.
          </p>
          <p className="top-section__box__second-line">
            If you would like to help fund the performing arts and visual arts
            scene in Australia, please donate by clicking on the button below.
          </p>
          <Link to="/donate/" activeClassName="active">
            <span>DONATE NOW</span>
          </Link>
        </div>
      </div>
      <div className="middle-section">
        <div className="middle-section__container">
          <div className="middle-section__container__logo-box">
            <Link to="/" activeClassName="active">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div className="middle-section__container__flex-area">
            <div className="middle-section__container__flex-area__foundation-box">
              <h1>The Lysicrates Foundation</h1>
              <p>
                The Lysicrates Foundation is an Australian public company
                limited by guarantee and is the trustee of a public fund known
                as The Lysicrates Cultural Fund, which is endorsed by the
                Australian Taxation Office as a deductible gift recipient.
              </p>
            </div>
            <div className="middle-section__container__flex-area__contact-box">
              <div className="middle-section__container__flex-area__contact-box__text-area">
                <h1>Contact us</h1>
                <p>
                  Level 40, Governor Macquarie Tower, 1 Farrer Place, Sydney NSW
                  2000
                </p>
                <a
                  title="For all enquiries"
                  href="mailto:hello@lysicratesfoundation.org.au"
                >
                  hello@lysicratesfoundation.org.au
                </a>
              </div>
              <div className="middle-section__container__flex-area__contact-box__icon-area">
                <a
                  href="https://www.instagram.com/the_lysicrates_foundation/"
                  target="blank"
                >
                  <FaInstagram className="middle-section__container__flex-area__contact-box__icon-area__icon" />
                </a>
                <a
                  href="https://www.facebook.com/The-Lysicrates-Foundation-624455484612898/"
                  target="blank"
                >
                  <FaFacebookSquare className="middle-section__container__flex-area__contact-box__icon-area__icon" />
                </a>
                <a href="https://twitter.com/lysicrates" target="blank">
                  <FaTwitterSquare className="middle-section__container__flex-area__contact-box__icon-area__icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-section">
        <div className="bottom-section__first-line">
          © 2019 The Lysicrates Foundation Limited ABN{" "}
          <span>19 605 412 422</span> and The Lysicrates Cultural Fund ABN{" "}
          <span>36 914 534 256</span>. All rights Reserved.
        </div>
        <div className="bottom-section__second-line">
          Website Built by
          <a target="blank" href="https://www.hellohandsome.com.au/">
            &nbsp;Handsome Creative.
          </a>
        </div>
      </div>
    </Wrapper>
  );
};

export default MainFooter;
