import React from "react";
import Layout from "../layout";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import {
  headingBold,
  headingNormal,
  mainBlack,
  mainGrey,
  pText,
  pTextLighter,
  mainRed,
  titleBlack,
  screen,
  innerWidth,
  transHover,
} from "../components/common/variables";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

const Wrapper = styled.div`
  width: 100%;
  padding: 0 30px 120px;

  @media ${screen.small} {
    padding: 0 45px 120px;
  }
  @media ${screen.medium} {
    padding: 0 60px;
  }
  @media ${screen.large} {
    padding: 0 20px;
  }
  @media ${screen.xLarge} {
    padding: 0;
  }

  .title-section {
    width: 100%;
    padding-top: 80px;
    padding-bottom: 10px;
    &__area {
      max-width: ${innerWidth};
      margin: 0 auto;
      &__box {
        max-width: 500px;
        margin-left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media ${screen.small} {
          display: block;
        }
        @media ${screen.medium} {
          margin-left: 40px;
        }
        .first-line {
          display: block;
          text-align: center;
          font-family: ${headingBold};
          font-size: 2.1875rem;
          color: ${mainRed};
          margin-left: 0px;
          margin-bottom: -10px;
          @media ${screen.small} {
            text-align: left;
          }
        }
        .second-line {
          display: block;
          text-align: center;
          margin-left: 0px;
          font-family: ${headingNormal};
          font-size: 3rem;
          color: ${titleBlack};
          @media ${screen.small} {
            margin-left: 50px;
            text-align: left;
            font-size: 3.75rem;
          }
          @media ${screen.medium} {
            font-size: 4.375rem;
          }
        }
      }
      .third-line {
        margin-left: 0px;
        margin-top: -10px;
        padding-top: 40px;
        @media ${screen.medium} {
          margin-left: 40px;
        }
        p {
          font-family: ${pTextLighter};
          font-size: 1.125rem;
          line-height: 30px;
          color: ${mainBlack};
          span {
            white-space: nowrap;
          }
        }
      }
    }
  }
  .resource-section {
    width: 100%;
    &__container {
      max-width: ${innerWidth};
      margin: 0 auto;
      &__box {
        width: 100%;
        padding: 40px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media ${screen.medium} {
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: center;
          align-items: flex-start;
        }
        .img-container {
          flex: 50%;
          max-width: 530px;
          width: 100%;
          img {
            @media ${screen.medium} {
              max-height: 240px;
              height: 100%;
            }
          }
        }
        .text-container {
          padding-left: 0px;
          flex: 50%;
          @media ${screen.medium} {
            padding-left: 10px;
          }
          h2 {
            padding-top: 30px;
            font-family: ${pText};
            font-size: 1.75rem;
            color: ${mainBlack};
            line-height: 1.1;
            text-align: left;
          }
          p {
            padding-top: 20px;
            padding-bottom: 30px;
            font-family: ${pTextLighter};
            font-size: 1.125rem;
            line-height: 30px;
            color: ${mainBlack};
          }
          a {
            padding: 10px 30px;
            font-family: ${pText};
            font-size: 0.875rem;

            background-color: ${mainRed};
            text-decoration: none;
            color: white;
            word-wrap: break-all;
            transition: ${transHover};
            :hover {
              background-color: ${mainGrey};
            }
          }
        }
      }
    }
  }
`;

const Resources = ({ data }) => {
  // content from prismic
  const titleTag = data.content.data.title_tag
    ? data.content.data.title_tag
    : `Lysicrates`;
  const metaDescription = data.content.data.meta_description
    ? data.content.data.meta_description
    : `Lysicrates`;
  const resources = data.content.data.resources;
  const headingText = data.content.data.heading_description
    ? data.content.data.heading_description.html
    : ``;
  return (
    <Layout>
      <SEO title={titleTag} description={metaDescription} />
      <Wrapper>
        <div className="title-section">
          <div className="title-section__area">
            <div className="title-section__area__box">
              <h1>
                <span className="first-line">Lysicrates</span>
                <span className="second-line">Resources</span>
              </h1>
            </div>
            <div className="third-line">
              <div dangerouslySetInnerHTML={{ __html: headingText }} />
            </div>
          </div>
        </div>
        <div className="resource-section">
          <div className="resource-section__container">
            {resources.map((resource, i) => (
              <div className="resource-section__container__box" key={i}>
                <div className="img-container">
                  <a
                    href={resource.downloadable_file.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <GatsbyImage
                      image={resource.image.gatsbyImageData}
                      alt={resource.image.alt}
                    />
                  </a>
                </div>
                <div className="text-container">
                  <h2> {resource.title}</h2>
                  <p>{resource.description}</p>
                  <a
                    href={resource.downloadable_file.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {resource.button_label}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default Resources;

export const dataQuery = graphql`
  {
    content: prismicResourcesPage {
      data {
        title_tag
        meta_description
        heading_description {
          html
        }
        resources {
          title
          description
          button_label
          image {
            alt
            gatsbyImageData(layout: FULL_WIDTH)
          }
          downloadable_file {
            url
          }
        }
      }
    }
  }
`;
