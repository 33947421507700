import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as Scroll from "react-scroll";
import { scrollerGrey, mainRed } from "../common/variables";
import { FaAngleUp } from "react-icons/fa";

const Wrapper = styled.div`
  position: fixed;
  right: 40px;
  bottom: 0;
  width: 48px;
  height: 35px;
  border-radius: 4px 4px 0 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${scrollerGrey};
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  :hover {
    background-color: ${mainRed};
    cursor: pointer;
  }
  .arrow {
    color: white;
  }
`;

const Scroller = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const detectScroll = () => {
      if (window.scrollY > 700) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", detectScroll);

    return () => {
      window.removeEventListener("scroll", detectScroll);
    };
  }, [scrolled]);

  const scrollToTop = () => {
    Scroll.animateScroll.scrollToTop();
  };

  return (
    <Wrapper
      style={{
        opacity: scrolled ? 0.9 : 0,
        pointerEvents: scrolled ? `all` : `none`,
      }}
      onClick={scrollToTop}
    >
      <FaAngleUp className="arrow" />
    </Wrapper>
  );
};

export default Scroller;
