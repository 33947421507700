import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import {
  headingBold,
  pText,
  mainRed,
  mainGreen,
  fontButton,
  screen,
  pTextBold,
} from "../common/variables";
import mobile_background from "../../images/footer/mobile-footer-background.png";
import logo from "../../images/footer/footer-logo.svg";
import { FaFacebookSquare, FaTwitterSquare, FaInstagram } from "react-icons/fa";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  @media ${screen.medium} {
    display: none;
  }
  .top-section {
    /* height: 400px; */
    padding: 60px 0 90px;
    width: 100%;
    /* background: url(${mobile_background}) no-repeat center bottom; */
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &__bg-img {
      background: url(${mobile_background}) no-repeat center top;
      background-size: cover;
      position: absolute;
      z-index: 1;
      bottom: 0;
      width: 100%;
      height: 120%;
    }

    &__box {
      width: 400px;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 2;
      h1 {
        font-family: ${headingBold};
        font-size: 1.5625rem;
        color: ${mainRed};
      }
      &__first-line {
        text-align: center;
        padding-top: 30px;
        font-family: ${pText};
        font-size: 1rem;
        color: ${mainRed};
      }
      &__second-line {
        text-align: center;
        padding-top: 10px;
        padding-bottom: 40px;
        font-family: ${pText};
        font-size: 1rem;
        color: ${mainRed};
      }
      span {
        font-family: ${pText};
        font-size: 1rem;
        font-weight: bold;
        color: ${mainRed};
        width: auto;
        padding: 10px 42px;
        border: solid 2px ${mainRed};
        transition: color, background-color 0.2s linear;
        :hover {
          cursor: pointer;
          color: white;
          background-color: ${mainRed};
        }
      }
    }
  }
  .middle-section {
    width: 100%;
    height: 600px;
    background-color: ${mainRed};
    &__container {
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 1400px;
      height: 100%;
      background-color: ${mainRed};
      margin: 0 auto;
      &__logo-box {
        margin-top: 10px;
        img {
          max-width: 96px;
          width: 100%;
          max-height: 136px;
          height: 100%;
        }
      }
      &__foundation-box {
        max-width: 400px;
        padding: 35px 0;
        text-align: center;
        background-color: ${mainRed};
        h1 {
          font-family: ${pTextBold};
          font-size: 1.25rem;
          color: white;
          padding-bottom: 20px;
        }
        p {
          font-family: ${pText};
          font-size: 0.8rem;
          line-height: 1.8;
          color: white;
        }
      }
      &__contact-box {
        text-align: center;
        background-color: ${mainRed};
        &__text-area {
          width: 100%;
          padding-bottom: 20px;
          h1 {
            font-family: ${pTextBold};
            font-size: 1.25rem;
            color: white;
            padding-bottom: 20px;
          }
          p {
            font-family: ${pText};
            font-size: 0.8rem;
            line-height: 1.8;
            color: white;
            padding-bottom: 10px;
          }
          a {
            padding-bottom: 2px;
            font-family: ${pText};
            font-size: 0.8rem;
            color: white;
            :hover {
              border-bottom: solid 1px white;;
            }
          }
        }
        &__icon-area {
          width: 100%;
          &__icon {
            width: 35px;
            height: 35px;
            margin: 10px;
            color: white;
            transition: color 0.2s linear;
            :hover {
              color: ${mainGreen};
            }
          }
        }
      }
    }
  }
  .bottom-section {
    width: 100%;
    height: 120px;
    padding: 0 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__first-line {
      text-align: center;
      color: ${mainRed};
      font-family: ${pText};
      font-size: 0.8rem;
      line-height: 18px;
      padding-bottom: 10px;
      span {
        white-space: nowrap;
      }
    }
    &__second-line {
      text-align: center;
      color: ${mainRed};
      font-family: ${pText};
      font-size: 0.8rem;
      a {
        color: black;
      }
    }
  }
`;

const MainFooter = () => {
  return (
    <Wrapper>
      <div className="top-section">
        <div className="top-section__bg-img" alt="Lysicrates" />
        <div className="top-section__box">
          <h1>Help fund the work of</h1>
          <h1>The Lysicrates Foundation</h1>
          <p className="top-section__box__first-line">
            The Lysicrates Foundation is a not-for-profit organisation and its
            events are free to attend.
          </p>
          <p className="top-section__box__second-line">
            If you would like to help fund the performing arts and visual arts
            scene in Australia, please donate by clicking on the button below.
          </p>
          <Link to="/donate/" activeClassName="active">
            <span>DONATE NOW</span>
          </Link>
        </div>
      </div>
      <div className="middle-section">
        <div className="middle-section__container">
          <div className="middle-section__container__logo-box">
            <Link to="/" activeClassName="active">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div className="middle-section__container__foundation-box">
            <h1>The Lysicrates Foundation</h1>
            <p>
              The Lysicrates Foundation is an Australian public company limited
              by guarantee and is the trustee of a public fund known as The
              Lysicrates Cultural Fund, which is endorsed by the Australian
              Taxation Office as a deductible gift recipient.
            </p>
          </div>
          <div className="middle-section__container__contact-box">
            <div className="middle-section__container__contact-box__text-area">
              <h1>Contact us</h1>
              <p>Level 2, 50 Margaret Street, Sydney NSW 2000 Australia</p>
              <a
                title="For all enquiries"
                href="mailto:hello@lysicratesfoundation.org.au"
              >
                hello@lysicratesfoundation.org.au
              </a>
            </div>
            <div className="middle-section__container__contact-box__icon-area">
              <a
                href="https://www.instagram.com/the_lysicrates_foundation/"
                target="blank"
              >
                <FaInstagram className="middle-section__container__contact-box__icon-area__icon" />
              </a>
              <a
                href="https://www.facebook.com/The-Lysicrates-Foundation-624455484612898/"
                target="blank"
              >
                <FaFacebookSquare className="middle-section__container__contact-box__icon-area__icon" />
              </a>
              <a href="https://twitter.com/lysicrates" target="blank">
                <FaTwitterSquare className="middle-section__container__contact-box__icon-area__icon" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-section">
        <div className="bottom-section__first-line">
          © 2019 The Lysicrates Foundation Limited ABN{" "}
          <span>19 605 412 422</span> and The Lysicrates Cultural Fund ABN{" "}
          <span>36 914 534 256</span>. All rights Reserved.
        </div>
        <div className="bottom-section__second-line">
          Website Built by
          <a target="blank" href="https://www.hellohandsome.com.au/">
            &nbsp;Handsome Creative.
          </a>
        </div>
      </div>
    </Wrapper>
  );
};

export default MainFooter;
