import React from "react";
import Layout from "../layout";
import SEO from "../components/seo";
import {
  headingBold,
  headingNormal,
  mainBlack,
  pText,
  pTextLighter,
  mainRed,
  titleBlack,
  screen,
  innerWidth,
} from "../components/common/variables";
import { graphql } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

const Wrapper = styled.div`
  width: 100%;
  padding: 0 30px 120px;
  @media ${screen.small} {
    padding: 0 45px 120px;
  }
  @media ${screen.medium} {
    padding: 0 60px;
  }
  @media ${screen.large} {
    padding: 0 20px;
  }
  @media ${screen.xLarge} {
    padding: 0;
  }

  .title-section {
    width: 100%;
    padding-top: 80px;
    padding-bottom: 20px;
    &__area {
      max-width: ${innerWidth};
      margin: 0 auto;
      &__box {
        max-width: 600px;
        margin-left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media ${screen.small} {
          display: block;
          margin-left: 40px;
        }
        .first-line {
          display: block;
          font-family: ${headingBold};
          font-size: 2.1875rem;
          color: ${mainRed};
          margin-left: 0px;
          margin-bottom: -10px;
          text-align: center;
          @media ${screen.small} {
            margin-left: 40px;
            margin-bottom: -10px;
            text-align: left;
          }
        }

        .second-line {
          display: block;
          font-family: ${headingNormal};
          font-size: 3rem;
          color: ${titleBlack};
          text-align: center;
          @media ${screen.small} {
            font-size: 3.75rem;
            text-align: left;
          }
          @media ${screen.medium} {
            font-size: 4.375rem;
          }
        }

        .third-line {
          display: block;
          font-family: ${headingBold};
          font-size: 2.1875rem;
          color: ${mainRed};
          margin-left: 0px;
          margin-top: -10px;
          text-align: center;
          @media ${screen.small} {
            margin-left: 120px;
            margin-top: -10px;
            text-align: left;
          }
        }
        &__top {
          margin-left: 0px;
          margin-bottom: -10px;
          @media ${screen.small} {
            margin-left: 40px;
            margin-bottom: -10px;
          }
          h1 {
            font-family: ${headingBold};
            font-size: 2.1875rem;
            color: ${mainRed};
          }
        }

        &__middle {
          margin-left: 0px;
          h1 {
            text-align: center;
            font-family: ${headingNormal};
            font-size: 3rem;
            color: ${titleBlack};
            @media ${screen.small} {
              font-size: 3.75rem;
              text-align: left;
            }
            @media ${screen.medium} {
              font-size: 4.375rem;
            }
          }
        }
        &__bottom {
          margin-left: 0px;
          margin-top: -10px;
          @media ${screen.small} {
            margin-left: 120px;
            margin-bottom: -10px;
          }
          h1 {
            text-align: center;
            font-family: ${headingBold};
            font-size: 2.1875rem;
            color: ${mainRed};
            @media ${screen.small} {
              text-align: left;
            }
          }
        }
      }
    }
  }
  .member-section {
    width: 100%;
    padding: 40px 0;
    &__container {
      max-width: ${innerWidth};
      margin: 0 auto;
      &__box {
        width: 100%;
        padding: 20px 30px;
        @media ${screen.small} {
          padding: 20px 45px;
        }
        @media ${screen.medium} {
          padding: 20px 60px;
        }
        @media ${screen.large} {
          padding: 20px 20px;
        }
        @media ${screen.xLarge} {
          padding: 20px 0;
        }
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media ${screen.small} {
          flex-direction: row;
          align-items: flex-start;
        }

        ul,
        ol {
          margin-left: 18px;
        }
        .img-container {
          flex: 20%;
          height: auto;
          width: 100%;
          margin-bottom: 20px;
          @media ${screen.small} {
            margin-bottom: 0;
          }
          img {
            width: 100%;
            height: 100%;
            @media ${screen.small} {
              max-width: 264px;
              max-height: 264px;
            }
          }
        }
        .text-container {
          padding-left: 0px;
          flex: 70%;
          @media ${screen.small} {
            padding-left: 40px;
          }
          h1 {
            padding-bottom: 20px;
            font-family: ${pText};
            font-size: 1.75rem;
            color: ${mainBlack};
            line-height: 1.1;
          }
          p {
            padding-bottom: 20px;
            font-family: ${pTextLighter};
            font-size: 1.125rem;
            line-height: 30px;
            color: ${mainBlack};
          }
          a {
            display: block;
            font-family: ${pTextLighter};
            font-size: 1.125rem;
            line-height: 30px;
            color: ${mainBlack};
            text-decoration: none;
            transition: color 0.2s linear;
            word-break: break-all;
            :hover {
              color: ${mainRed};
            }
          }
          ul {
            padding-bottom: 20px;
            padding-left: 20px;
          }
          li {
            font-family: ${pTextLighter};
            font-size: 1.125rem;
            line-height: 30px;
            list-style-type: disc;
            color: ${mainBlack};
          }
        }
      }
    }
  }
`;

const Board = ({ data }) => {
  // content from prismic
  const members = data.content.data.members;
  const titleTag = data.content.data.title_tag
    ? data.content.data.title_tag
    : `Lysiscrates`;
  const metaDescription = data.content.data.meta_description
    ? data.content.data.meta_description
    : `Lysicrates`;
  return (
    <Layout>
      <SEO title={titleTag} description={metaDescription} />
      <Wrapper>
        <div className="title-section">
          <div className="title-section__area">
            <div className="title-section__area__box">
              <h1>
                <span className="first-line">The</span>
                <span className="second-line">Board Members</span>
                <span className="third-line">of the Lysicrates Foundation</span>
              </h1>
            </div>
          </div>
        </div>
        <div className="member-section">
          <div className="member-section__container">
            {members.map((member, i) => (
              <div className="member-section__container__box" key={i}>
                <div className="img-container">
                  {member.image.gatsbyImageData && (
                    <GatsbyImage
                      image={member.image.gatsbyImageData}
                      alt={member.image.alt}
                      loading="eager"
                    />
                  )}
                </div>
                <div className="text-container">
                  <h1>{member.name}</h1>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: member.description.html,
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default Board;

export const dataQuery = graphql`
  {
    content: prismicBoardMembersPage {
      data {
        members {
          name
          description {
            html
          }
          image {
            alt
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        title_tag
        meta_description
      }
    }
  }
`;
