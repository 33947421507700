import { keyframes } from "styled-components";

export const moveDash = keyframes`
  from {
    stroke-dashoffset: 1300;
  }
  to {
    stroke-dashoffset: 0;
  }
`;

export const enterTooltip = keyframes`
0% {
  transform: scale(0);
}

75% {
  transform: scale(1.15);
}

100% {
  transform: scale(1);
}
`;
