import React, { useState, useEffect } from "react";
import styled from "styled-components";
import addToMailchimp from "gatsby-plugin-mailchimp";
import {
  emailRegex,
  mainRed,
  mainGrey,
  mainBlack,
  mainWhite,
  backgroundGrey,
  headingNormal,
  pText,
  pTextBold,
  transHover,
  screen,
} from "../common/variables";
import { IoIosClose } from "react-icons/io";

const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;

  form {
    max-width: 358px;
    padding: 40px 20px 50px 20px;
    background-color: ${backgroundGrey};
    margin: auto;
    position: relative;
    width: 100%;
    @media ${screen.small} {
      max-width: 800px;
      padding: 50px 40px;
    }

    .heading {
      color: ${mainRed};
      font-family: ${headingNormal};
      font-size: 1.9rem;
      font-weight: 900;
      @media ${screen.small} {
        font-size: 3rem;
      }
    }

    .subheading {
      color: ${mainGrey};
      font-family: ${pText};
      font-size: 0.95rem;
      margin-top: 5px;
      @media ${screen.small} {
        font-size: 1.05rem;
      }
    }

    .names-row {
      display: flex;
      input {
        &:first-child {
          margin-right: 12px;
        }
      }
    }

    input {
      border: 1px ${mainGrey} solid;
      border-radius: 0;
      padding: 10px 12px;
      font-family: ${pText};
      outline: none;
      margin-top: 15px;
      width: 100%;
      @media ${screen.small} {
        padding: 12px 14px;
        margin-top: 20px;
        width: 500px;
      }
    }

    button {
      background-color: ${mainRed};
      border: 0;
      color: ${mainWhite};
      cursor: pointer;
      padding: 10px 38px;
      margin-top: 14px;
      font-family: ${pTextBold};
      transition: ${transHover};
      width: 100%;
      outline: none;
      @media ${screen.small} {
        padding: 14px 58px;
        margin-left: 14px;
        margin-top: 0;
        width: initial;
      }

      @media ${screen.withCursor} {
        &:hover {
          background-color: ${mainGrey};
        }
      }
    }

    .error-text {
      color: ${mainRed};
      font-family: ${pText};
      font-size: 0.8rem;
      position: absolute;
      width: 100%;
      left: 20px;
      bottom: 26px;
      @media ${screen.small} {
        left: 40px;
        bottom: 28px;
        font-size: 0.85rem;
      }
    }

    .close {
      color: ${mainRed};
      cursor: pointer;
      font-size: 3.3rem;
      position: absolute;
      top: 0;
      right: 0;
      transition: ${transHover};
      @media ${screen.small} {
        font-size: 3.8rem;
      }
      &:hover {
        color: ${mainBlack};
      }
    }
  }
`;

const NewsletterPopup = () => {
  const [email, setEmail] = useState("");
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [errorFeedback, setErrorFeedback] = useState(false);
  const [successFeedback, setSuccessFeedback] = useState(false);
  const [errorMsg, setErrorMsg] = useState(
    "Please complete all required fields."
  );

  const firstNameInput = e => {
    setFName(e.target.value);
  };

  const lastNameInput = e => {
    setLName(e.target.value);
  };

  const emailInput = e => {
    setEmail(e.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (!fName.length || !lName.length || !emailRegex.test(email)) {
      // if email is invalid
      setErrorFeedback(true);
      setErrorMsg("Please complete all required fields.");
      setEmail("");
      setFName("");
      setLName("");
    } else {
      addToMailchimp(email, {
        FNAME: fName,
        LNAME: lName,
      }).then(data => {
        const checkEmail = data.msg.toLowerCase().split(" ");
        if (
          checkEmail.includes(email.toLowerCase()) ||
          checkEmail.includes(`"${email.toLowerCase()}"`)
        ) {
          // if email already in the list
          setErrorFeedback(true);
          setErrorMsg("Looks like you're already subscribed.");
          setEmail("");
          setFName("");
          setLName("");
        } else {
          // if email successfully added
          setSuccessFeedback(true);
          setErrorFeedback(false);
          setEmail("");
          setFName("");
          setLName("");
        }
      });
    }
  };

  useEffect(() => {
    if (localStorage.getItem("lysicrates-newsletter")) {
      // don't show popup if already on the local storage
      setShowPopup(false);
    } else {
      // delay newsletter popup
      setTimeout(() => {
        setShowPopup(true);
      }, 4500);
    }
  }, []);

  const preventPopupShow = () => {
    // when user click the close icon, add this to their local storage
    localStorage.setItem("lysicrates-newsletter", true);
  };

  // close form popup
  const closePopup = () => {
    setShowPopup(false);
    preventPopupShow();
  };

  return (
    <React.Fragment>
      {showPopup && (
        <Wrapper className="animated fadeIn">
          <form onSubmit={handleSubmit} className="animated fadeInUpBig">
            <h6 className="heading">
              {successFeedback ? `Thank you for subscribing!` : `Stay Updated!`}
            </h6>
            <p className="subheading">
              {successFeedback
                ? `You'll receive our Newsletters and get notified for upcoming events`
                : `Receive our Newsletters and get notified for upcoming events`}
            </p>
            {!successFeedback && (
              <p className="names-row">
                <input
                  type="text"
                  placeholder="First Name*"
                  value={fName}
                  onChange={firstNameInput}
                />
                <input
                  type="text"
                  placeholder="Last Name*"
                  value={lName}
                  onChange={lastNameInput}
                />
              </p>
            )}

            <input
              placeholder="Email Address*"
              onChange={emailInput}
              value={email}
              style={{ visibility: successFeedback ? `hidden` : `visible` }}
            />
            <button
              style={{ visibility: successFeedback ? `hidden` : `visible` }}
            >
              SUBSCRIBE
            </button>
            {errorFeedback && <span className="error-text">{errorMsg}</span>}
            <span className="close" onClick={closePopup}>
              <IoIosClose />
            </span>
          </form>
        </Wrapper>
      )}
    </React.Fragment>
  );
};

export default NewsletterPopup;
