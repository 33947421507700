import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import SEO from "../components/seo";
import styled from "styled-components";
import {
  headingBold,
  headingNormal,
  mainBlack,
  backgroundGrey,
  pText,
  pTextLighter,
  pTextBold,
  mainRed,
  titleBlack,
  screen,
  mainBlue,
  innerWidth,
} from "../components/common/variables";
import Lightbox from "react-image-lightbox";
import { GatsbyImage } from "gatsby-plugin-image";
import dashline from "../images/events/2015/ebook/lysicrates-dashed-line.png";

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;

  .title-section {
    width: 100%;
    padding-top: 80px;

    &__area {
      max-width: ${innerWidth};
      margin: 0 auto;

      &__box {
        margin-left: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media ${screen.small} {
          max-width: 350px;
          display: block;
          margin-left: 40px;
        }

        .first-line {
          display: block;
          text-align: center;
          margin-bottom: -20px;
          font-family: ${headingBold};
          font-size: 2.1875rem;
          color: ${mainRed};
          @media ${screen.small} {
            text-align: left;
          }
        }
        .second-line {
          display: block;
          text-align: center;
          margin-left: 0px;
          font-family: ${headingNormal};
          font-size: 3rem;
          color: ${titleBlack};
          @media ${screen.small} {
            text-align: left;
            margin-left: 50px;
            font-size: 3.75rem;
          }
          @media ${screen.medium} {
            font-size: 4.375rem;
          }
        }
        .third-line {
          display: block;
          text-align: center;
          margin-left: 0px;
          margin-top: -10px;
          font-family: ${headingBold};
          font-size: 2.1875rem;
          color: ${mainRed};
          @media ${screen.small} {
            text-align: left;
            margin-left: 100px;
            margin-top: -10px;
          }
        }
      }
    }
  }
  .banner-section {
    margin: 40px 0 60px 0;
    align-items: center;
    justify-content: center;
    width: 140%;
    @media ${screen.small} {
      width: 100%;
    }

    img {
      width: 100%;
    }
  }

  .article-section {
    width: 100%;
    padding-bottom: 80px;

    &__container {
      max-width: ${innerWidth};
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 30px;
      @media ${screen.small} {
        padding: 0 45px;
      }
      @media ${screen.medium} {
        padding: 0 60px;
      }
      @media ${screen.large} {
        padding: 0 20px;
      }
      @media ${screen.xLarge} {
        padding: 0;
      }
      @media ${screen.medium} {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
      }

      .text {
        max-width: 703px;

        p {
          color: ${mainBlack};
          font-family: ${pTextLighter};
          letter-spacing: 0px;
          font-size: 1.05rem;
          line-height: 32px;
          padding-bottom: 20px;
          @media ${screen.small} {
            font-size: 1.25rem;
          }
        }

        img {
          width: 100%;
          height: auto;
          padding-bottom: 20px;
        }

        a {
          color: ${mainBlack};
          text-decoration: none;
          font-family: ${pTextBold};
          &:hover {
            color: ${mainRed};
          }
        }

        strong {
          font-weight: 900;
        }
        div {
          div {
            flex: 50%;
            width: 100%;
            padding: 30% 0;
            box-sizing: border-box;
            position: relative;
            iframe {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }

        .winner {
          font-family: ${pTextBold};
          font-size: 1.15rem;
          @media ${screen.small} {
            font-size: 1.25rem;
          }

          &--name {
            color: ${mainRed};
          }
        }
      }
    }
  }

  .finalists-section {
    background-color: ${backgroundGrey};
    padding: 0 30px;
    @media ${screen.small} {
      padding: 0 45px;
    }
    @media ${screen.medium} {
      padding: 0 60px;
    }
    @media ${screen.large} {
      padding: 0 20px;
    }
    @media ${screen.xLarge} {
      padding: 0;
    }

    &__container {
      max-width: ${innerWidth};
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      &__title-area {
        padding: 40px 0 10px 0;

        h1 {
          text-align: center;
          font-family: ${headingBold};
          font-size: 2.875rem;
          line-height: 1.5;
          letter-spacing: 0px;
          color: ${mainRed};
        }
      }

      &__event-area {
        width: 100%;
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media ${screen.small} {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-start;
        }

        .img-container {
          flex: 50%;
          height: auto;
          width: 100%;

          img {
            max-width: 530px;
            width: 100%;
            max-height: 345px;
            height: 100%;
          }
        }

        .text {
          padding-left: 0px;
          flex: 50%;
          margin-top: 12px;
          @media ${screen.small} {
            padding-left: 40px;
            margin-top: 0;
          }

          h1 {
            font-family: ${pText};
            font-size: 1.75rem;
            line-height: 32px;
          }

          p {
            color: ${mainBlack};
            font-family: ${pTextLighter};
            letter-spacing: 0px;
            font-size: 1.125rem;
            line-height: 30px;
            padding-bottom: 20px;
          }

          span {
            font-family: ${pText};
            font-style: italic;
          }
        }
      }
    }
  }

  .gallery-section {
    width: 100%;
    padding: 40px 30px 120px;
    @media ${screen.small} {
      padding: 40px 45px 120px;
    }
    @media ${screen.medium} {
      padding: 40px 60px;
    }
    @media ${screen.large} {
      padding: 40px 20px;
    }
    @media ${screen.xLarge} {
      padding: 40px 0;
    }

    &__container {
      max-width: ${innerWidth};
      margin: 0 auto;

      &__title-area {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 40px 0 10px 0;

        h1 {
          font-family: ${headingBold};
          font-size: 2.875rem;
          color: #333563;
        }
      }

      &__img-area {
        display: flex;
        flex-wrap: wrap;
        padding: 20px 0;
        justify-content: center;
        @media ${screen.small} {
          justify-content: flex-start;
        }

        .img-container {
          max-width: 50%;
          width: 100%;
          @media ${screen.small} {
            max-width: 33.33%;
          }
          @media ${screen.medium} {
            max-width: 25%;
          }
          img {
            padding: 1px;
            width: 100%;
            max-height: 185px;
            height: 100%;
            cursor: pointer;
            object-fit: cover;
          }
        }
      }
    }
  }

  .ebook-section {
    width: 100%;
    padding-top: 40px;
    padding: 0 30px 120px 30px;
    @media ${screen.small} {
      padding: 100px 45px 80px 45px;
    }
    @media ${screen.medium} {
      padding: 100px 60px 80px 60px;
    }
    @media ${screen.large} {
      padding: 100px 20px 80px 20px;
    }
    @media ${screen.xLarge} {
      padding: 100px 0 80px 0;
    }

    &__dashline-container {
      width: 100%;
      background-image: url(${dashline});
      background-position: center top;
      background-repeat: repeat-x;
      padding-top: 0px;
      padding-right: 30px;
      padding-bottom: 40px;
      padding-left: 30px;
    }

    &__content-container {
      max-width: ${innerWidth};
      margin: -60px auto 0 auto;
      background-color: white;

      &__box {
        max-width: 900px;

        .first-line {
          padding: 10px;
          text-align: center;
          @media ${screen.small} {
            text-align: left;
            padding-left: 60px;
          }

          p {
            max-width: 500px;
            font-family: ${headingBold};
            font-size: 2.5rem;
            color: ${mainBlue};
          }

          p > a {
            text-decoration: none;
            color: ${mainRed};
          }
        }

        .second-line {
          img {
            width: 100%;
          }
        }
      }
    }
  }

  /* tricky style for hacked lightbox captions */
  .img-caption {
    color: #fff;
    font-family: sans-serif;
    position: fixed;
    bottom: 120px;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    z-index: 9999;
    max-width: 320px;
    width: 100%;
    bottom: 27%;
    @media ${screen.small} {
      max-width: 1010px;
      bottom: 300px;
    }
    @media ${screen.medium} {
      bottom: calc(46vh - 390px);
    }

    p {
      font-size: 0.9rem;
      text-align: center;
      @media ${screen.small} {
        font-size: 1.2rem;
      }
    }
  }
`;

const EventPage = ({ data }) => {
  const titleTag = data.event.data.title_tag
    ? data.event.data.title_tag
    : "Lysicrates";
  const metaDescription = data.event.data.meta_description
    ? data.event.data.meta_description
    : "Lysicrates";
  const eventTitle = data.event.data.title.text;
  const htmlContent = data.event.data.main_content.html;
  const winnerName = data.event.data.winner_name
    ? data.event.data.winner_name
    : "";
  const imageBanner =
    data.event.data.image_banner.gatsbyImageData &&
    data.event.data.image_banner.gatsbyImageData;

  const imageBannerAlt = data.event.data.image_banner
    ? data.event.data.image_banner.alt
    : "Lysicrates";
  const finalists = data.event.data.finalists;
  const eBookImage =
    data.event.data.image1.gatsbyImageData &&
    data.event.data.image1.gatsbyImageData;

  const eBookImageAlt = data.event.data.image1.alt
    ? data.event.data.image1.alt
    : "Lysicrates";
  const downloadableFile = data.event.data.downloadable_file
    ? data.event.data.downloadable_file.url
    : "";
  const galleryImages = data.event.data.image ? data.event.data.image : [];

  const [lightboxController, setLightbox] = useState({
    isOpen: false,
    photoIndex: 0,
  });

  return (
    <Layout>
      <SEO title={titleTag} description={metaDescription} />
      <Wrapper>
        <div className="title-section">
          <div className="title-section__area">
            <div className="title-section__area__box">
              <h1>
                <span className="first-line">The</span>
                <span className="second-line">{eventTitle}</span>
                <span className="third-line">Lysicrates Event</span>
              </h1>
            </div>
          </div>
        </div>
        <div className="banner-section">
          <div className="image-container">
            {imageBanner ? (
              <GatsbyImage
                image={imageBanner}
                alt={imageBannerAlt}
                loading="eager"
              />
            ) : (
              <img src="/x" alt="not found" />
            )}
          </div>
        </div>
        <div className="article-section">
          <div className="article-section__container">
            <div className="text">
              <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
              {winnerName && (
                <p className="winner">
                  {eventTitle} Winner:{" "}
                  <span className="winner--name">{winnerName}</span>
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="finalists-section">
          <div className="finalists-section__container">
            <div className="finalists-section__container__title-area">
              <h1>The {eventTitle} Finalists</h1>
            </div>

            {finalists.map(finalist => (
              <div
                className="finalists-section__container__event-area"
                key={finalist.title1}
              >
                <div className="img-container">
                  {finalist.image.gatsbyImageData ? (
                    <GatsbyImage
                      image={finalist.image.gatsbyImageData}
                      alt={finalist.title1}
                      loading="eager"
                    />
                  ) : (
                    <img src="/x" alt="not found " />
                  )}
                </div>
                <div className="text">
                  <h1>{finalist.title1}</h1>
                  <p>Playwright: {finalist.playwright}</p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: finalist.description.html,
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="gallery-section">
          <div className="gallery-section__container">
            <div className="gallery-section__container__title-area">
              <h1>Photo Gallery</h1>
            </div>
            <div className="gallery-section__container__img-area">
              {galleryImages.map((photo, index) => (
                <div key={index} className="img-container">
                  <img
                    src={photo.image2.url}
                    alt={photo.image2.alt}
                    onClick={() =>
                      setLightbox({ isOpen: true, photoIndex: index })
                    }
                  />
                </div>
              ))}
              {lightboxController.isOpen && (
                <Lightbox
                  mainSrc={
                    galleryImages[lightboxController.photoIndex].image2.url
                  }
                  nextSrc={
                    galleryImages[
                      (lightboxController.photoIndex + 1) % galleryImages.length
                    ].image2.url
                  }
                  prevSrc={
                    galleryImages[
                      (lightboxController.photoIndex +
                        galleryImages.length -
                        1) %
                        galleryImages.length
                    ].image2.url
                  }
                  onCloseRequest={() =>
                    setLightbox({ ...lightboxController, isOpen: false })
                  }
                  onMovePrevRequest={() =>
                    setLightbox({
                      ...lightboxController,
                      photoIndex:
                        (lightboxController.photoIndex +
                          galleryImages.length -
                          1) %
                        galleryImages.length,
                    })
                  }
                  onMoveNextRequest={() =>
                    setLightbox({
                      ...lightboxController,
                      photoIndex:
                        (lightboxController.photoIndex + 1) %
                        galleryImages.length,
                    })
                  }
                />
              )}
            </div>
          </div>
        </div>
        {downloadableFile && (
          <div className="ebook-section">
            <div className="ebook-section__dashline-container"></div>
            <div className="ebook-section__content-container">
              <div className="ebook-section__content-container__box">
                <div className="first-line">
                  <p>
                    To find out more about the {eventTitle} prize, please
                    download our event eBook{" "}
                    <a href={downloadableFile} target="blank">
                      here
                    </a>
                  </p>
                </div>
                <div className="second-line">
                  <a href={downloadableFile} target="blank">
                    <GatsbyImage image={eBookImage} alt={eBookImageAlt} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        {lightboxController.isOpen && (
          <div className="img-caption">
            <p>{galleryImages[lightboxController.photoIndex].image2.alt}</p>
          </div>
        )}
      </Wrapper>
    </Layout>
  );
};

export default EventPage;

export const data = graphql`
  query($id: String!) {
    event: prismicEvent(id: { eq: $id }) {
      data {
        title {
          text
        }
        main_content {
          html
        }
        winner_name
        image_banner {
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
        finalists {
          title1
          playwright
          description {
            html
          }
          image {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        title_tag
        meta_description
        downloadable_file {
          url
        }
        image1 {
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
        image {
          image2 {
            alt
            url
          }
        }
      }
    }
  }
`;
