import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../layout";
import SEO from "../components/seo";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  mainRed,
  mainGrey,
  mainBlack,
  headingNormal,
  pTextLighter,
  pTextBold,
  pText,
  transHover,
  screen,
  innerWidth,
  headingBold,
} from "../components/common/variables";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";

const Wrapper = styled.div`
  margin-top: 70px;
  padding-bottom: 150px;
  @media ${screen.small} {
    padding-bottom: 150px;
    margin-top: 100px;
  }
  @media ${screen.medium} {
    padding-bottom: 120px;
  }

  p {
    line-height: 1.7;
  }
  .main-content {
    max-width: ${innerWidth};
    margin: 0 auto;
    display: flex;
    padding: 0 30px;
    flex-direction: column;
    @media ${screen.small} {
      padding: 0 45px;
      flex-direction: row;
    }
    @media ${screen.medium} {
      padding: 0 60px;
    }
    @media ${screen.large} {
      padding: 0 20px;
    }
    @media ${screen.xLarge} {
      padding: 0;
    }

    &__image {
      width: 100%;
      /* max-width: 180px; */
      @media ${screen.small} {
        min-width: 320px;
        max-width: 410px;
        margin-right: 30px;
      }
      @media ${screen.large} {
        min-width: 410px;
        margin-right: 60px;
      }

      &--desktop {
        display: none;
        @media ${screen.small} {
          display: block;
        }
      }

      &--mobile {
        @media ${screen.small} {
          display: none;
        }
      }
    }

    &__text {
      font-family: ${pTextLighter};

      .playwright-heading {
        color: ${mainBlack};
        font-family: ${headingNormal};
        font-weight: 900;
        font-size: 1.7rem;
        @media ${screen.small} {
          font-size: 3rem;
        }
        &--red {
          color: ${mainRed};
          font-family: ${headingBold};
        }
      }

      .playwright-subheading {
        font-family: ${headingNormal};
        color: ${mainRed};
        font-weight: 900;
        font-size: 1.43rem;
        margin-top: 15px;
        margin-bottom: 20px;
        @media ${screen.small} {
          font-size: 2.4rem;
          margin-top: 30px;
          margin-bottom: 0;
        }
      }

      .playwright-description {
        margin-top: 15px;
        @media ${screen.small} {
          margin-top: 30px;
        }

        h1 {
          font-size: 1.35rem;
          margin-bottom: 16px;
          line-height: 1.4;
          @media ${screen.small} {
            font-size: 1.6rem;
            margin-bottom: 20px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        h2 {
          font-size: 1.25rem;
          margin-bottom: 16px;
          line-height: 1.4;
          @media ${screen.small} {
            font-size: 1.5rem;
            margin-bottom: 20px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        h3 {
          font-size: 1.2rem;
          margin-bottom: 16px;
          line-height: 1.4;
          @media ${screen.small} {
            font-size: 1.42rem;
            margin-bottom: 20px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        h4 {
          font-size: 1.17rem;
          margin-bottom: 16px;
          line-height: 1.4;
          @media ${screen.small} {
            font-size: 1.35rem;
            margin-bottom: 20px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        h5 {
          font-size: 1.14rem;
          margin-bottom: 16px;
          line-height: 1.4;
          @media ${screen.small} {
            font-size: 1.3rem;
            margin-bottom: 20px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        h6 {
          font-size: 1.12rem;
          margin-bottom: 16px;
          line-height: 1.4;
          @media ${screen.small} {
            font-size: 1.25rem;
            margin-bottom: 20px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        p {
          font-size: 1.05rem;
          margin-bottom: 16px;
          @media ${screen.small} {
            font-size: 1.125rem;
            margin-bottom: 20px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        strong {
          font-family: ${pTextBold};
        }

        a {
          font-family: ${pTextBold};
          color: ${mainBlack};
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    max-width: ${innerWidth};
    padding: 0 30px;
    @media ${screen.small} {
      padding: 0 45px;
    }
    @media ${screen.medium} {
      padding: 0 60px;
    }
    @media ${screen.large} {
      padding: 0 20px;
    }
    @media ${screen.xLarge} {
      padding: 0;
    }

    &__next {
      color: ${mainGrey};
      font-family: ${pText};
      font-size: 1rem;
      cursor: pointer;
      transition: ${transHover};

      &:hover {
        color: ${mainRed};
      }
      svg {
        font-size: 1.5rem;
        vertical-align: middle;
      }
    }

    &__all {
      color: ${mainGrey};
      font-family: ${pText};
      font-size: 1rem;
      cursor: pointer;
      text-decoration: underline;
    }

    &__prev {
      color: ${mainGrey};
      font-family: ${pText};
      font-size: 1rem;
      cursor: pointer;
      transition: ${transHover};

      &:hover {
        color: ${mainRed};
      }

      svg {
        font-size: 1.5rem;
        vertical-align: middle;
        margin-bottom: 3px;
      }
    }
  }
`;

const PlaywrightPage = props => {
  const {
    full_name,
    year,
    title_tag,
    meta_description,
    image,
    description,
  } = props.data.content.data;

  const { next, prev } = props.pageContext;

  return (
    <Layout>
      <SEO title={title_tag} description={meta_description} />
      <Wrapper>
        <main className="main-content">
          <div className="main-content__image main-content__image--desktop">
            {image.localFile && (
              <GatsbyImage
                image={image.localFile.childImageSharp.gatsbyImageData}
                alt={image.alt}
              />
            )}
          </div>
          <div className="main-content__text">
            <h1 className="playwright-heading">
              <span className="playwright-heading--red">The {year}</span> <br />{" "}
              Lysicrates Prize Finalists
            </h1>
            <h2 className="playwright-subheading">Meet {full_name.text}</h2>
            <div className="main-content__image main-content__image--mobile">
              {image.localFile && (
                <GatsbyImage
                  image={image.localFile.childImageSharp.gatsbyImageData}
                  alt={image.alt}
                />
              )}
            </div>
            <div
              className="playwright-description"
              dangerouslySetInnerHTML={{ __html: description.html }}
            />
          </div>
        </main>
        <div className="navigation">
          <Link
            className="navigation__prev"
            to={`/playwright/${next}`}
            style={{ visibility: next ? `visible` : `hidden` }}
          >
            <MdNavigateBefore /> Previous
          </Link>

          <Link
            className="navigation__next"
            to={`/playwright/${prev}`}
            style={{ visibility: prev ? `visible` : `hidden` }}
          >
            Next <MdNavigateNext />
          </Link>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default PlaywrightPage;

export const data = graphql`
  query($id: String!) {
    content: prismicPlaywright(id: { eq: $id }) {
      data {
        full_name {
          text
        }
        title_tag
        meta_description
        year
        image {
          alt
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        description {
          html
        }
      }
    }
  }
`;
