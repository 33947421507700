import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  headerBlue,
  lightGrey,
  menuBorderGrey,
  pText,
  fontTagLight,
  fontTagBold,
  mainRed,
  screen,
  pTextLighter,
  pTextBold,
  brightGreen,
} from "../common/variables";
import { Link } from "gatsby";
import { IoIosClose } from "react-icons/io";
import header_background from "../../images/header/lysicrates-torn-parper-nav-bar-V2.png";
import top_background from "../../images/header/lysicrates-torn-parper-nav-bar-bottom-V2.png";
import logo from "../../images/header/logo-2024.svg";

const Wrapper = styled.div`
  display: none;
  @media ${screen.small} {
    display: block;
  }
  .header-spacing {
    position: relative;
    width: 100%;
    height: 150px;
    background-color: #ededed;
    @media ${screen.large} {
      height: 100px;
    }
    &__top-background {
      display: none;
      top: 120px;
      left: 0;
      position: absolute;
      width: 100%;
      height: 60px;
      background: url(${top_background}) no-repeat center;
      background-size: 100% 100%;
      z-index: 9;

      @media ${screen.large} {
        top: 60px;
      }
    }
    &__top-background-home {
      display: none;
      top: 150px;
      left: 0;
      position: absolute;
      width: 100%;
      height: 60px;
      background: url(${top_background}) no-repeat center;
      background-size: 100% 100%;
      z-index: 9;
      @media ${screen.large} {
        top: 100px;
      }
    }
  }
  .banner-spacing {
    position: relative;
    width: 100%;
    height: 40px;
    background-color: grey;
    @media ${screen.large} {
      display: none;
    }
  }
  .header-tag {
    display: none;
    z-index: 11;
    position: fixed;
    top: 0px;
    right: 40px;
    width: 160px;
    @media ${screen.large} {
      display: block;
    }
    &__box {
      width: 160px;
      height: 140px;
      background-color: ${headerBlue};
      padding: 15px 10px 0 10px;
      &__first-line {
        color: white;
        font-family: ${pTextLighter};
        font-size: 0.8rem;
        line-height: 1.05;
      }
      &__second-line {
        color: white;
        font-family: ${fontTagBold};
        font-size: 1.35rem;
        line-height: 1.05;
      }
      &__third-line {
        color: white;
        font-family: ${fontTagBold};
        font-size: 1.35rem;
        line-height: 1.05;
      }
      &__fourth-line {
        color: white;
        font-family: ${fontTagLight};
        font-size: 1.35rem;
        line-height: 1.05;
        padding-bottom: 15px;
      }
      a {
        text-decoration: none;
        color: white;
        font-family: ${fontTagBold};
        font-size: 0.88rem;
        transition: all 0.3s ease-out;
        :hover {
          color: ${brightGreen};
        }
      }
    }
    &__tail {
      border-style: solid;
      border-width: 0 80px 15px 80px;
      border-color: transparent ${headerBlue} transparent ${headerBlue};
    }
  }
  .header-container {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    background: url(${header_background}) no-repeat center;
    background-size: 100% 100%;

    .top-banner {
      display: block;
      width: 100%;
      height: 40px;
      background: ${headerBlue};
      padding: 0 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media ${screen.large} {
        display: none;
      }
      p {
        overflow: hidden;
        white-space: nowrap;
        color: white;
        font-family: ${fontTagLight};
        font-size: 0.8rem;
        display: inline-block;
        span {
          font-family: ${fontTagBold};
        }
      }
      a {
        color: white;
        font-family: ${fontTagLight};
        font-size: 0.8rem;
        display: inline-block;
        padding: 2px 10px;
        border: solid 1px white;
        transition: all 0.3s ease-out;
        :hover {
          color: ${headerBlue};
          background: white;
        }
      }
      &__close {
        width: 30px;
        height: 30px;
        position: absolute;
        right: 20px;
        color: white;
        :hover {
          cursor: pointer;
        }
      }
    }
    .header-area {
      max-width: 1100px;
      height: 150px;
      margin: auto auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media ${screen.large} {
        flex-direction: row;
        justify-content: space-between;
        height: 100px;
      }
      &__link-list {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 0;
        margin-bottom: 7px;
        @media ${screen.large} {
          padding-right: 110px;
        }
      }

      &__link {
        padding: 7px 0;
        margin: 0 10px;
        border-bottom: dashed 1px transparent;
        transition: border 0.3s linear;
        @media ${screen.medium} {
          margin: 0 12px;
        }
        a {
          color: ${mainRed};
          font-family: ${pText};
          font-size: 0.9rem;
          white-space: nowrap;
        }

        &:hover {
          border-bottom: dashed 1px ${mainRed};
        }
        .active {
          font-family: ${pTextBold};
        }
        &__dropdown {
          display: relative;
          padding: 7px 0;
          margin: 0 16px;
          border-bottom: dashed 1px transparent;
          a {
            color: ${mainRed};
            font-family: ${pText};
            font-size: 0.9rem;
            white-space: nowrap;
          }
          &:hover {
            border-bottom: dashed 1px ${mainRed};
          }
          &:hover .header-area__link__dropdown__content {
            height: auto;
            opacity: 1;
          }
          .active {
            font-family: ${pTextBold};
          }

          &__content {
            position: absolute;
            margin-top: 7px;
            border-top: solid 1px ${mainRed};
            background-color: ${lightGrey};
            min-width: 100px;
            height: 0;
            overflow: hidden;
            opacity: 0;
            transition: opacity 0.3s linear;
            a {
              display: block;
              font-family: ${pText};
              font-size: 1rem;
              padding: 10px;
              border-bottom: solid 1px ${menuBorderGrey};
              transition: background-color 0.3s linear;
            }
            a:hover {
              background-color: white;
            }
          }
        }
      }

      &__logo {
        width: 220px;
        max-height: 60px;
        height: 100%;
        margin: 10px;
      }
    }
  }
`;

const MainNav = ({ events }) => {
  const [path, setPath] = useState("normal");
  const [bannerController, setBannerController] = useState(true);

  // check if current path
  useEffect(() => {
    if (path && window.location.pathname === "/") {
      setPath("/");
    } else if (path) {
      setPath("normal");
    }
  }, [path]);

  // get latest event
  const latestEvent = events[0];

  return (
    <Wrapper>
      <div
        className="banner-spacing"
        style={bannerController ? {} : { display: "none" }}
      />
      <div className="header-spacing">
        <div
          style={{ display: path === "normal" ? `block` : `none` }}
          className="header-spacing__top-background"
        />
        <div
          style={{ display: path === "/" ? `block` : `none` }}
          className="header-spacing__top-background-home"
        />
      </div>
      <div className="header-tag">
        <div className="header-tag__box">
          <div className="header-tag__box__first-line">THE</div>
          <div className="header-tag__box__second-line">MARTIN-</div>
          <div className="header-tag__box__third-line">LYSICRATES</div>
          <div className="header-tag__box__fourth-line">PRIZE</div>
          <a href="https://martinlysicrates.com.au/" target="blank">
            VISIT SITE >
          </a>
        </div>
        <div className="header-tag__tail"></div>
      </div>
      <div className="header-container">
        <div
          style={bannerController ? {} : { display: "none" }}
          className="top-banner"
        >
          <p>
            THE <span>MARTIN-LYSICRATES</span> PRIZE celebrates the best of
            Australian playwrights.
          </p>
          <a href="https://martinlysicrates.com.au/" target="blank">
            Visit site
          </a>
          <IoIosClose
            className="top-banner__close"
            onClick={() => setBannerController(false)}
            alt="close"
          />
        </div>
        <div className="header-area">
          <Link to="/" activeClassName="active">
            <img className="header-area__logo" src={logo} alt="logo" />
          </Link>
          <div className="header-area__link-list">
            <div className="header-area__link">
              <Link to="/" activeClassName="active">
                HOME
              </Link>
            </div>
            <div className="header-area__link__dropdown">
              <Link to="/about/">ABOUT +</Link>
              <div className="header-area__link__dropdown__content">
                <Link to="/about/" activeClassName="active">
                  ABOUT
                </Link>
                <Link to="/board/" activeClassName="active">
                  THE BOARD
                </Link>
                <Link to="/accolades/" activeClassName="active">
                  ACCOLADES
                </Link>
              </div>
            </div>
            <div className="header-area__link__dropdown">
              <Link
                to={`/the-${latestEvent}-lysicrates-event`}
                activeClassName="active"
              >
                EVENTS +
              </Link>
              <div className="header-area__link__dropdown__content">
                {events.map((event) => (
                  <Link
                    to={`/the-${event}-lysicrates-event`}
                    activeClassName="active"
                    key={event}
                  >
                    {event}
                  </Link>
                ))}
              </div>
            </div>
            <div className="header-area__link">
              <Link to="/resources/" activeClassName="active">
                RESOURCES
              </Link>
            </div>
            <div className="header-area__link">
              <Link to="/history/" activeClassName="active">
                LYSICRATES
              </Link>
            </div>
            <div className="header-area__link">
              <Link to="/james-martin/" activeClassName="active">
                JAMES MARTIN
              </Link>
            </div>
            <div className="header-area__link">
              <Link to="/orations/" activeClassName="active">
                ORATIONS
              </Link>
            </div>
            <div className="header-area__link">
              <Link to="/news/" activeClassName="active">
                NEWS
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default MainNav;
