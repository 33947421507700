import React, { useState } from "react";
import { Link, graphql } from "gatsby";
import Layout from "../layout";
import SEO from "../components/seo";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  headingBold,
  headingNormal,
  transHover,
  pTextLighter,
  pTextBold,
  pText,
  borderGrey,
  mainRed,
  titleBlack,
  screen,
  mainGrey,
  mainWhite,
  innerWidth,
} from "../components/common/variables";
import { format } from "date-fns";
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from "react-icons/md";
import { toSlug } from "../functions";

const Wrapper = styled.div`
  width: 100%;
  background-color: white;
  padding: 0 30px 130px 30px;
  @media ${screen.small} {
    padding: 0 45px 120px 45px;
  }
  @media ${screen.medium} {
    padding: 0 60px 80px 60px;
  }
  @media ${screen.large} {
    padding: 0 20px 100px 20px;
  }

  .title-section {
    width: 100%;
    padding-top: 80px;
    &__area {
      max-width: ${innerWidth};
      margin: 0 auto;
      &__box {
        max-width: 500px;
        margin-left: 0px;
        @media ${screen.small} {
          margin-left: 20px;
        }
        .first-line {
          display: block;
          font-family: ${headingBold};
          font-size: 2.6rem;
          color: ${mainRed};
          margin-bottom: -10px;
          @media ${screen.small} {
            font-size: 3.2rem;
          }
        }
        .second-line {
          display: block;
          font-family: ${headingNormal};
          font-size: 3rem;
          color: ${titleBlack};
          @media ${screen.small} {
            font-size: 3.75rem;
          }
          @media ${screen.medium} {
            font-size: 4.375rem;
          }
        }
      }
    }
  }

  .filter-section {
    display: flex;
    justify-content: flex-end;
    max-width: ${innerWidth};
    margin: 25px auto 0 auto;
    @media ${screen.small} {
      margin: 35px 20px 0 auto;
    }
    @media ${screen.medium} {
      margin: 35px 20px 0 auto;
      padding-right: 20px;
    }
    @media ${screen.large} {
      margin: 35px auto 0 auto;
    }

    &__btn {
      width: 155px;
      border: 1px ${mainGrey} solid;
      padding: 10px 0 7px 20px;
      position: relative;
      color: ${mainGrey};
      @media ${screen.small} {
        padding: 10px 0 7px 30px;
        width: 190px;
      }
      @media ${screen.withCursor} {
        &:hover {
          color: ${mainRed};
          border: 1px ${mainRed} solid;
        }
      }

      .heading {
        font-family: ${pTextLighter};
        font-size: 1.15rem;
        cursor: pointer;
        @media ${screen.small} {
          font-size: 1.25rem;
        }

        .icon {
          font-size: 1.85rem;
          vertical-align: middle;
          margin-left: 6px;
          @media ${screen.small} {
            font-size: 2rem;
            margin-left: 10px;
          }
        }
      }

      .filter-list {
        position: absolute;
        z-index: 5;
        background: ${mainWhite};
        width: 155px;
        left: 50%;
        transform: translateX(-50%);
        border-left: 1px ${mainGrey} solid;
        border-right: 1px ${mainGrey} solid;
        border-bottom: 1px ${mainGrey} solid;
        word-wrap: break-word;
        @media ${screen.small} {
          width: 190px;
        }

        li {
          color: ${mainGrey};
          font-family: ${pTextLighter};
          font-size: 1.15rem;
          padding: 9px 20px 9px 20px;
          cursor: pointer;
          transition: ${transHover};
          @media ${screen.small} {
            font-size: 1.25rem;
            padding: 9px 30px 9px 30px;
          }
          &:hover {
            color: ${mainRed};
          }
        }
      }
    }
  }
  .news-section {
    width: 100%;
    padding: 30px 0;

    &__area {
      max-width: ${innerWidth};
      margin: 0 auto;
      display: flex;
      /* align-items: center; */
      flex-wrap: wrap;

      &__box {
        min-height: 410px;
        border: solid 1px ${borderGrey};
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0 0 25px 0;
        @media ${screen.small} {
          min-height: 440px;
          margin: 20px;
          max-width: calc(50% - 40px);
        }
        @media ${screen.medium} {
          min-height: 465px;
          margin: 20px;
          max-width: calc(33.33% - 40px);
        }
        @media ${screen.large} {
          min-height: 440px;
        }

        .header {
          position: relative;
          z-index: 1;
          width: 100%;
          overflow: hidden;
          min-height: 148px;
          max-height: 148px;
          @media ${screen.small} {
            max-height: 218px;
            min-height: 218px;
          }
          @media ${screen.medium} {
            max-height: 162px;
            min-height: 162px;
          }
          .gatsby-image-wrapper {
            overflow: visible !important;
          }

          img {
            width: 100%;
            height: 100%;
            display: block;
            transform: scale(1.3);
          }

          &__overlay-hover {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(169, 53, 29, 0.8);
            transform: translateX(-100%);
            transition: ${transHover};
            display: flex;
            align-items: center;
            justify-content: center;

            span {
              color: white;
              font-family: ${pTextBold};
              font-size: 1.125rem;
            }
          }

          @media ${screen.withCursor} {
            &:hover .header__overlay-hover {
              transform: translateX(0);
            }
          }
        }
        .content {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          padding: 20px;
          &__text {
            h2 {
              color: ${mainRed};
              font-family: ${pTextBold};
              font-size: 1.125rem;
              line-height: 27px;
              transition: ${transHover};
              :hover {
                color: ${mainGrey};
              }
            }
            p {
              font-family: ${pTextLighter};
              font-size: 1rem;
              padding-top: 10px;
              line-height: 1.52;
            }
          }
          &__footer {
            width: 100%;
            padding: 20px 0 10px;

            span {
              padding: 10px 40px;
              font-family: ${pText};
              font-size: 0.875rem;
              color: white;
              background-color: ${mainRed};
              transition: ${transHover};
              @media ${screen.withCursor} {
                &:hover {
                  background-color: ${mainGrey};
                }
              }
            }
          }
        }
      }
    }

    .load-more {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      max-width: ${innerWidth};
      margin: 25px auto 0 auto;
      @media ${screen.small} {
        margin: 35px 20px 0 auto;
      }
      @media ${screen.medium} {
        margin: 35px 20px 0 auto;
        padding-right: 20px;
      }
      @media ${screen.large} {
        margin: 35px auto 0 auto;
      }

      &__btn {
        border: 1px ${mainRed} solid;
        cursor: pointer;
        color: ${mainRed};
        font-family: ${pText};
        padding: 10px 40px;
        font-size: 0.875rem;
        transition: ${transHover};
        @media ${screen.withCursor} {
          &:hover {
            color: ${mainGrey};
            border: 1px ${mainGrey} solid;
          }
        }
      }
    }

    .fully-loaded-feedback {
      width: 100%;
      font-family: ${pTextBold};
      margin-top: 35px;
      font-size: 1.05rem;
      @media ${screen.small} {
        font-size: 1.125rem;
        margin-left: 20px;
        margin-top: 45px;
      }
    }
  }
`;

const NewsPage = ({ data }) => {
  const categories = data.categories.edges
    .map((item) => item.node.data.title.text)
    .sort();

  const allBlogs = data.blogs.edges.map((blog) => {
    return {
      node: {
        id: blog.node.id,
        data: {
          edit_published_date: blog.node.data.edit_published_date
            ? blog.node.data.edit_published_date
            : blog.node.first_publication_date,
          featured_image: {
            alt: blog.node.data.featured_image.alt,
            gatsbyImageData: blog.node.data.featured_image.gatsbyImageData,
          },
          title: blog.node.data.title,
          featured_description: blog.node.data.featured_description,
          categories: blog.node.data.categories,
        },
        slug: toSlug(blog.node.data.title.text),
        sortedDate: blog.node.data.edit_published_date
          ? Number(blog.node.data.edit_published_date.split("-").join(""))
          : Number(
              blog.node.first_publication_date.slice(0, 10).split("-").join("")
            ),
      },
    };
  });

  const sortedBlogs = allBlogs.sort(
    (a, b) => b.node.sortedDate - a.node.sortedDate
  );

  const [blogs, setBlogs] = useState(sortedBlogs);
  const [openFilter, setFilter] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const titleTag = data.seo.data.title_tag;
  const metaDescription = data.seo.data.meta_description;
  const [blogLength, setBlogLength] = useState(9);

  const finalBlogs = blogs.filter(
    (blog) => blog.node.id !== "6e44d1b9-a1fc-59c9-aa93-ad78421b5ede"
  );

  // toggle drop down filter
  const toggleFilter = () => {
    setFilter(!openFilter);
  };

  // close drop down filter if user click anywhere on the page
  const closeFilter = () => {
    if (openFilter) {
      setFilter(false);
    }
  };

  // select category functionality
  const selectCategory = (category) => {
    if (category === "All") {
      setBlogs(sortedBlogs);
      setSelectedCategory("All");
    } else {
      // return blogs if category exist in array categories
      const filteredBlogs = sortedBlogs.filter((blog) =>
        blog.node.data.categories.some(
          (cat) =>
            cat.category.document &&
            cat.category.document.data.title.text === category
        )
      );
      setBlogs(filteredBlogs);
      setSelectedCategory(category);
    }

    toggleFilter();
  };

  const renderBlogList =
    selectedCategory === "All" ? finalBlogs.slice(0, blogLength) : finalBlogs;

  return (
    <Layout>
      <SEO title={titleTag} description={metaDescription} />
      <Wrapper onClick={closeFilter}>
        <div className="title-section">
          <div className="title-section__area">
            <div className="title-section__area__box">
              <h1>
                <span className="first-line">The Latest</span>
                <span className="second-line">Lysicrates News</span>
              </h1>
            </div>
          </div>
        </div>
        <div className="filter-section">
          <div className="filter-section__btn">
            <h5 className="heading" onClick={toggleFilter}>
              Categories
              <span>
                {openFilter ? (
                  <MdKeyboardArrowDown className="icon" />
                ) : (
                  <MdKeyboardArrowRight className="icon" />
                )}
              </span>
            </h5>
            {openFilter && (
              <ul className="filter-list">
                {categories.map((category) => (
                  <li onClick={() => selectCategory(category)} key={category}>
                    <span>{category}</span>
                  </li>
                ))}
                <li onClick={() => selectCategory("All")}>
                  <span>All</span>
                </li>
              </ul>
            )}
          </div>
        </div>
        <div className="news-section">
          <div className="news-section__area">
            {renderBlogList.map((blog, i) => (
              <div key={i} className="news-section__area__box">
                <div className="header">
                  {blog.node.data.featured_image.gatsbyImageData ? (
                    <GatsbyImage
                      image={blog.node.data.featured_image.gatsbyImageData}
                      alt={blog.node.data.featured_image.alt}
                    />
                  ) : (
                    <img src="/x" alt="not available" />
                  )}

                  <Link to={`/news/${blog.node.slug}`} activeClassName="active">
                    <div className="header__overlay-hover">
                      <span>
                        {format(
                          blog.node.data.edit_published_date,
                          "MMMM Do, YYYY"
                        )}
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="content">
                  <div className="content__text">
                    <Link
                      to={`/news/${blog.node.slug}`}
                      activeClassName="active"
                    >
                      <h2>{blog.node.data.title.text}</h2>
                    </Link>
                    <p>{blog.node.data.featured_description}</p>
                  </div>
                  <div className="content__footer">
                    <Link to={`/news/${blog.node.slug}`}>
                      <span>READ MORE</span>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
            {blogLength <= data.blogs.edges.length &&
            selectedCategory === "All" ? (
              <div className="load-more">
                <span
                  className="load-more__btn"
                  onClick={() => setBlogLength(blogLength + 9)}
                >
                  LOAD MORE
                </span>
              </div>
            ) : (
              <p className="fully-loaded-feedback">
                {selectedCategory === "All"
                  ? `You're up to date!`
                  : `You're up to date about ${selectedCategory}!`}
              </p>
            )}
          </div>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default NewsPage;

export const dataQuery = graphql`
  {
    blogs: allPrismicBlog(
      sort: { fields: data___edit_published_date, order: DESC }
    ) {
      edges {
        node {
          first_publication_date
          id
          data {
            title {
              text
            }
            categories {
              category {
                document {
                  __typename
                  ... on PrismicCategory {
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
            featured_image {
              alt
              gatsbyImageData(layout: CONSTRAINED, width: 526, height: 290)
            }
            featured_description
            edit_published_date
          }
        }
      }
    }
    seo: prismicNewsPage {
      data {
        title_tag
        meta_description
      }
    }
    categories: allPrismicCategory {
      edges {
        node {
          data {
            title {
              text
            }
          }
        }
      }
    }
  }
`;
