import React from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import SEO from "../components/seo";
import styled from "styled-components";
import {
  headingBold,
  headingNormal,
  mainBlack,
  pTextLighter,
  mainRed,
  titleBlack,
  screen,
  innerWidth,
} from "../components/common/variables";
import { GatsbyImage } from "gatsby-plugin-image";

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;

  .title-section {
    width: 100%;
    padding-top: 80px;

    &__area {
      max-width: ${innerWidth};
      margin: 0 auto;

      &__box {
        margin-left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        justify-content: center;
        @media ${screen.small} {
          width: 320px;
          display: block;
          margin-left: 40px;
        }

        .first-line {
          display: block;
          font-family: ${headingBold};
          font-size: 2.1875rem;
          color: ${mainRed};
          margin-left: 0px;
          margin-bottom: -10px;
          text-align: center;
          @media ${screen.small} {
            margin-left: 35px;
            margin-bottom: -10px;
            text-align: left;
          }
        }

        .second-line {
          display: block;
          font-family: ${headingNormal};
          font-size: 3rem;
          color: ${titleBlack};
          text-align: center;
          @media ${screen.small} {
            font-size: 3.75rem;
            text-align: left;
          }
          @media ${screen.medium} {
            font-size: 4.375rem;
          }
        }

        .third-line {
          display: block;
          font-family: ${headingBold};
          font-size: 2.1875rem;
          color: ${mainRed};
          margin-left: 0px;
          margin-top: -10px;
          text-align: center;
          @media ${screen.small} {
            margin-left: 200px;
            margin-top: -10px;
            text-align: left;
          }
        }
      }
    }
  }
  .banner-section {
    width: 145%;
    margin: 40px 0 60px 0;
    align-items: center;
    justify-content: center;
    @media ${screen.small} {
      width: 100%;
    }

    img {
      width: 145%;
    }
  }
  .article-section {
    width: 100%;
    padding-bottom: 80px;
    &__container {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 0 30px 60px;
      @media ${screen.small} {
        padding: 0 45px 60px;
      }
      @media ${screen.medium} {
        padding: 0 60px;
      }
      @media ${screen.large} {
        padding: 0 20px;
      }
      @media ${screen.xLarge} {
        padding: 0;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media ${screen.small} {
        flex-direction: row;
        align-items: flex-start;
      }
      &__photo {
        padding-right: 0px;
        padding-top: 0px;
        max-width: 390px;
        width: 100%;
        @media ${screen.small} {
          padding-right: 40px;
        }
        img {
          width: 100%;
        }
      }
      &__text {
        max-width: 670px;
        padding: 20px 0;
        @media ${screen.small} {
          padding: 0;
        }
        p {
          color: ${mainBlack};
          font-family: ${pTextLighter};
          padding-bottom: 20px;
          line-height: 1.7;
          font-size: 1.05rem;
          @media ${screen.small} {
            font-size: 1.125rem;
          }
        }
      }
    }
  }
`;

const AboutPage = ({ data }) => {
  const titleTag = data.content.data.title_tag
    ? data.content.data.title_tag
    : "Lysicrates";
  const metaDescription = data.content.data.meta_description
    ? data.content.data.meta_description
    : "Lysicrates";
  const imageBanner = data.content.data.banner_image
    ? data.content.data.banner_image.gatsbyImageData
    : "";
  const imageBannerAlt = data.content.data.banner_image.alt
    ? data.content.data.banner_image.alt
    : "Banner";
  const featuredImage = data.content.data.featured_image
    ? data.content.data.featured_image.gatsbyImageData
    : "";
  const descriptionHTML = data.content.data.description
    ? data.content.data.description.html
    : "";

  return (
    <Layout>
      <SEO title={titleTag} description={metaDescription} />
      <Wrapper>
        <div className="title-section">
          <div className="title-section__area">
            <div className="title-section__area__box">
              <h1>
                <span className="first-line">About the</span>
                <span className="second-line">Lysicrates</span>
                <span className="third-line">Foundation</span>
              </h1>
            </div>
          </div>
        </div>
        <div className="banner-section">
          <GatsbyImage image={imageBanner} alt={imageBannerAlt} />
        </div>
        <div className="article-section">
          <div className="article-section__container">
            <div className="article-section__container__photo">
              <GatsbyImage image={featuredImage} alt={imageBannerAlt} />
            </div>
            <div className="article-section__container__text">
              <div
                dangerouslySetInnerHTML={{
                  __html: descriptionHTML,
                }}
              />
            </div>
          </div>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default AboutPage;

export const dataQuery = graphql`
  {
    content: prismicAboutPage {
      data {
        banner_image {
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
        featured_image {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        description {
          html
        }
        title_tag
        meta_description
      }
    }
  }
`;
