import React from "react";
import MainFooter from "./main-footer";
import MobileFooter from "./mobile-footer.js";

const Footer = () => (
  <div>
    <MainFooter />
    <MobileFooter />
  </div>
);

export default Footer;
