import React, { useState } from "react";
import { Link, graphql } from "gatsby";
import Layout from "../layout";
import SEO from "../components/seo";
import styled from "styled-components";
import bgPage from "../images/oration/page-bg.jpg";
import ripWhite from "../images/oration/white-rip.png";
import bgBannerImg from "../images/oration/banner-oration.png";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import {
  headingBold,
  headingNormal,
  mainBlack,
  mainGrey,
  transHover,
  pTextBold,
  pText,
  mainRed,
  pTextLighter,
  screen,
  innerWidth,
} from "../components/common/variables";
import { GatsbyImage } from "gatsby-plugin-image";

const Wrapper = styled.div`
  width: 100%;
  background: url(${bgPage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  overflow: hidden;

  .title-section {
    width: 100%;
    padding: 80px 0 90px 0;
    background-image: url(${bgBannerImg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    position: relative;
    @media ${screen.small} {
      padding: 80px 0 150px 0;
    }
    @media ${screen.medium} {
      padding: 80px 0 200px 0;
    }

    &__area {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 0 30px;
      @media ${screen.small} {
        padding: 0 60px;
      }
      @media ${screen.large} {
        padding: 0 20px;
      }
      @media ${screen.xLarge} {
        padding: 0;
      }

      &__box {
        margin-left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media ${screen.small} {
          display: block;
        }

        .first-line {
          display: block;
          font-family: ${headingBold};
          font-size: 2.1875rem;
          color: ${mainRed};
          margin-left: 0px;
          margin-bottom: -10px;
          text-align: center;
          @media ${screen.small} {
            margin-bottom: -10px;
            text-align: left;
          }
        }

        .second-line {
          display: block;
          font-family: ${headingNormal};
          font-size: 3rem;
          color: ${mainBlack};
          text-align: center;
          @media ${screen.small} {
            font-size: 3.75rem;
            text-align: left;
            margin-left: 22px;
          }
          @media ${screen.medium} {
            font-size: 4.375rem;
          }
        }
      }

      .banner-description {
        max-width: 620px;
        margin: 20px 0 0 0;
        @media ${screen.small} {
          margin: 30px 0 0 0;
        }

        p {
          margin: 0 0 16px 0;
          font-size: 1.05rem;
          font-family: ${pTextLighter};
          @media ${screen.small} {
            margin: 0 0 20px 0;
            font-size: 1.125rem;
          }
        }
      }

      .remarks {
        max-width: 550px;
        margin: 36px 0 0 0;
        @media ${screen.small} {
          margin: 46px 0 0 0;
        }

        &__each {
          display: flex;
          justify-content: space-between;
          margin: 0 0 56px 0;
          @media ${screen.small} {
            margin: 0 0 66px 0;
          }

          &:last-child {
            margin: 0;
          }

          .col {
            h2 {
              white-space: pre-wrap;
              font-family: ${pTextLighter};
              font-size: 1.4rem;
              font-weight: 300;
              max-width: 480px;
              line-height: 1.45;
              @media ${screen.small} {
                font-size: 1.6rem;
              }
            }

            p {
              white-space: pre-wrap;
              font-size: 0.95rem;
              font-family: ${pTextBold};
              margin: 5px 0 20px 0;
              @media ${screen.small} {
                font-size: 1.2rem;
                margin: 5px 0 0 0;
              }
            }

            &--one {
            }

            &--two {
              min-width: 86px;
              max-width: 86px;
              margin: 0 0 0 5px;
              @media ${screen.small} {
                margin: 0;
                min-width: 140px;
                max-width: 140px;
              }

              img {
                border-radius: 50%;
              }
            }
          }
        }
      }
    }

    .rip-border {
      display: block;
      position: absolute;
      bottom: -1px;
      transform: scale(-1);
      width: 100%;
      pointer-events: none;
    }
  }

  .dl-link {
    padding: 10px 30px;
    font-family: ${pText};
    font-size: 0.875rem;
    background-color: ${mainRed};
    text-decoration: none;
    color: #fff;
    display: table;
    transition: ${transHover};
    @media ${screen.small} {
      margin: 30px 0 0 0;
    }

    &:hover {
      background-color: ${mainGrey};
    }
  }

  .main-content {
    .oration-item {
      color: ${mainBlack};
      position: relative;

      &__flex-row {
        display: flex;
        max-width: ${innerWidth};
        margin: 0 auto;
        padding: 0 30px;
        flex-direction: column;
        @media ${screen.small} {
          padding: 0 60px;
          flex-direction: row;
        }
        @media ${screen.large} {
          padding: 0 20px;
        }
        @media ${screen.xLarge} {
          padding: 0;
        }

        .col {
          @media ${screen.small} {
            width: 50%;
          }

          .img-book {
            pointer-events: none;

            &--desktop {
              display: none;
              @media ${screen.small} {
                display: block;
              }
            }

            &--mobile {
              display: block;
              margin: 30px 0 15px 0;
              @media ${screen.small} {
                display: none;
              }
            }
          }

          .img-featured {
          }

          .year {
            font-family: ${headingBold};
            font-size: 2.3rem;
            margin-bottom: 15px;
            margin-top: 20px;
            @media ${screen.small} {
              font-size: 2.6rem;
              margin-bottom: 32px;
              margin-top: 0;
            }
          }

          .title {
            font-family: ${pTextLighter};
            font-size: 1.4rem;
            font-weight: 300;
            max-width: 480px;
            line-height: 1.45;
            @media ${screen.small} {
              font-size: 1.6rem;
            }
          }

          .orator {
            font-size: 0.95rem;
            font-family: ${pTextBold};
            margin: 5px 0 0 0;
            @media ${screen.small} {
              font-size: 1.2rem;
              margin: 5px 0 0 0;
            }
          }

          .description {
            margin: 20px 0 0 0;
            @media ${screen.small} {
              margin: 30px 0 0 0;
            }

            p {
              margin: 0 0 16px 0;
              font-size: 1.1rem;
              font-family: ${pTextLighter};
              @media ${screen.small} {
                margin: 0 0 20px 0;
                font-size: 1.2rem;
              }
              &:last-child {
                margin: 0;
              }
            }
          }

          .gallery {
            margin: 36px 0 0 0;
            @media ${screen.small} {
              margin: 66px 0 0 0;
            }

            &__heading {
              font-family: ${headingBold};
              font-size: 1.3rem;
              @media ${screen.small} {
                font-size: 1.6rem;
              }
            }

            &__list {
              display: flex;
              flex-wrap: wrap;
              margin: 10px -4px 0 -4px;

              .img-wrapper {
                width: calc(33.33% - 8px);
                cursor: pointer;
                margin: 4px;
                transition: ${transHover};
                @media ${screen.small} {
                  width: calc(25% - 8px);
                }
                @media ${screen.medium} {
                  width: calc(20% - 8px);
                }
                @media ${screen.withCursor} {
                  &:hover {
                    opacity: 0.84;
                  }
                }

                img {
                  width: 100%;
                  display: block;
                }
              }
            }
          }

          &--with-book-left {
            .img-book {
              @media ${screen.small} {
                margin-top: -142px;
                margin-bottom: 15px;
                margin-right: -14px;
              }
              @media ${screen.medium} {
                width: 780px;
                margin-left: -312px;
                margin-bottom: 0;
                margin-top: -185px;
                margin-right: 0;
              }
              @media ${screen.large} {
                width: 890px;
                margin-left: -379px;
                margin-top: -215px;
              }
              @media ${screen.xLarge} {
                width: 920px;
                margin-left: -389px;
              }
            }

            @media ${screen.small} {
              padding: 0 50px 0 0;
            }
          }

          &--with-book-right {
            z-index: 2;
            order: -1;
            @media ${screen.small} {
              padding: 0 0 0 50px;
              order: 0;
            }

            .img-book {
              @media ${screen.small} {
                margin: -165px 0 0 -10px;
              }
              @media ${screen.medium} {
                margin: -195px 0 0 -18px;
              }
            }

            .img-featured {
              margin: 50px 0 0 0;
              @media ${screen.small} {
                margin: 12px 0 0 0;
                width: 50vw;
              }
              @media ${screen.medium} {
                margin: 15px 0 0 0;
              }
            }
          }
        }
      }

      &--with-bg-white {
        background: #fff;
        padding: 70px 0;
        @media ${screen.small} {
          padding: 80px 0 160px 0;
        }
        @media ${screen.medium} {
          padding: 100px 0 200px 0;
        }
      }

      &--with-rip-border {
        padding: 35px 0 70px 0;
        @media ${screen.small} {
          padding: 140px 0 220px 0;
        }
        @media ${screen.medium} {
          padding: 170px 0 250px 0;
        }

        .rip-border {
          display: block;
          width: 100%;
          position: absolute;
          pointer-events: none;

          &--top {
            top: 0;
          }

          &--bottom {
            bottom: -1px;
            transform: scale(-1);
          }
        }
      }
    }
  }

  .resources {
    background: #fff;
    color: ${mainBlack};

    .inner-wrapper {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 50px 30px 160px 30px;
      @media ${screen.small} {
        padding: 75px 60px 175px 60px;
      }
      @media ${screen.medium} {
        padding: 100px 60px;
      }
      @media ${screen.large} {
        padding: 100px 20px;
      }
      @media ${screen.xLarge} {
        padding: 100px 0;
      }
    }

    &__heading {
      font-family: ${headingNormal};
      font-size: 3rem;
      text-align: center;
      line-height: 1.15;
      @media ${screen.small} {
        font-size: 3.75rem;
        text-align: left;
      }
      @media ${screen.medium} {
        font-size: 4.375rem;
      }
    }

    p {
      font-family: ${headingBold};
      font-size: 1.3rem;
      max-width: 450px;
      margin: 5px 0 35px 0;
      text-align: center;
      @media ${screen.small} {
        font-size: 1.6rem;
        text-align: left;
      }
    }

    a {
      span {
        font-family: ${pText};
        font-size: 1rem;
        color: ${mainRed};
        width: auto;
        padding: 10px 44px;
        border: solid 2px ${mainRed};
        transition: color, background-color 0.2s linear;
        display: table;
        margin: 0 auto;
        @media ${screen.small} {
          margin: 0;
        }

        &:hover {
          cursor: pointer;
          color: white;
          background-color: ${mainRed};
        }
      }
    }
  }
`;

function isOdd(num) {
  return num % 2;
}

const OrationsPage = ({ data }) => {
  const {
    banner_description,
    banner_remarks,
    body,
    title_tag,
    meta_description,
  } = data.content.data;

  const [lightboxController, setLightbox] = useState({
    isOpen: false,
    photoIndex: 0,
    selectedIndexGallery: 0,
  });

  const galleries = body.map((item, i) => {
    return item.items.map((gallery) => gallery.gallery.url);
  });

  return (
    <Layout>
      <SEO title={title_tag} description={meta_description} />
      <Wrapper>
        <div className="title-section">
          <div className="title-section__area">
            <div className="title-section__area__box">
              <h1>
                <span className="first-line">The Martin</span>
                <span className="second-line">Orations</span>
              </h1>
            </div>

            <div
              className="banner-description"
              dangerouslySetInnerHTML={{ __html: banner_description.html }}
            />

            <div className="remarks">
              {banner_remarks.map((remark) => (
                <div className="remarks__each" key={remark.heading}>
                  <div className="col col--one">
                    <h2>{remark.heading}</h2>
                    <p>{remark.subheading}</p>
                    <a
                      className="dl-link"
                      href={remark.downloadable_pdf.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      DOWNLOAD PDF
                    </a>
                  </div>
                  <div className="col col--two">
                    <GatsbyImage
                      image={remark.photo.thumbnails.cropped.gatsbyImageData}
                      alt={remark.photo.alt || "Portrait"}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <img className="rip-border" src={ripWhite} alt="decoration" />
        </div>

        <div className="main-content">
          {body.map((item, i) =>
            isOdd(i) ? (
              <div
                key={item.id}
                className="oration-item oration-item--with-rip-border"
              >
                <div key={item.id} className="oration-item__flex-row">
                  <div className="col">
                    <h3 className="year">{item.primary.year.text}</h3>
                    <h3 className="title">{item.primary.title.text}</h3>
                    <p className="orator">{item.primary.speech_by}</p>
                    <div
                      className="description"
                      dangerouslySetInnerHTML={{
                        __html: item.primary.description.html,
                      }}
                    />
                    <div className="img-book img-book--mobile">
                      <GatsbyImage
                        image={item.primary.book_image.gatsbyImageData}
                        alt={item.primary.book_image.alt || "Book"}
                      />
                    </div>
                    <a
                      className="dl-link"
                      href={item.primary.downloadable_pdf.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      DOWNLOAD PDF
                    </a>

                    <div className="gallery">
                      <h4 className="gallery__heading">Photos from the day</h4>
                      <div className="gallery__list">
                        {item.items.map((photo, index) => (
                          <div key={index} className="img-wrapper">
                            <img
                              src={photo.gallery.thumbnails.thumbnail.url}
                              alt={photo.gallery.alt}
                              onClick={() =>
                                setLightbox({
                                  isOpen: true,
                                  photoIndex: index,
                                  selectedIndexGallery: i,
                                })
                              }
                            />
                          </div>
                        ))}
                      </div>
                      {lightboxController.isOpen && (
                        <Lightbox
                          mainSrc={
                            galleries[lightboxController.selectedIndexGallery][
                              lightboxController.photoIndex
                            ]
                          }
                          nextSrc={
                            galleries[lightboxController.selectedIndexGallery][
                              (lightboxController.photoIndex + 1) %
                                galleries[
                                  lightboxController.selectedIndexGallery
                                ].length
                            ]
                          }
                          prevSrc={
                            galleries[lightboxController.selectedIndexGallery][
                              (lightboxController.photoIndex +
                                galleries[
                                  lightboxController.selectedIndexGallery
                                ].length -
                                1) %
                                galleries[
                                  lightboxController.selectedIndexGallery
                                ].length
                            ]
                          }
                          onCloseRequest={() =>
                            setLightbox({
                              ...lightboxController,
                              isOpen: false,
                            })
                          }
                          onMovePrevRequest={() =>
                            setLightbox({
                              ...lightboxController,
                              photoIndex:
                                (lightboxController.photoIndex +
                                  galleries[
                                    lightboxController.selectedIndexGallery
                                  ].length -
                                  1) %
                                galleries[
                                  lightboxController.selectedIndexGallery
                                ].length,
                            })
                          }
                          onMoveNextRequest={() =>
                            setLightbox({
                              ...lightboxController,
                              photoIndex:
                                (lightboxController.photoIndex + 1) %
                                galleries[
                                  lightboxController.selectedIndexGallery
                                ].length,
                            })
                          }
                          wrapperClassName="oration-modal-wrapper"
                        />
                      )}
                    </div>
                  </div>

                  <div className="col col--with-book-right">
                    <div className="img-book img-book--desktop">
                      <GatsbyImage
                        image={item.primary.book_image.gatsbyImageData}
                        alt={item.primary.book_image.alt || "Book"}
                      />
                    </div>
                    <div className="img-featured">
                      <GatsbyImage
                        image={
                          item.primary.featured_image.thumbnails.thumbnail
                            .gatsbyImageData
                        }
                        alt={item.primary.featured_image.alt || "Featured"}
                      />
                    </div>
                  </div>
                </div>

                <img
                  className="rip-border rip-border--top"
                  src={ripWhite}
                  alt="decoration"
                />
                <img
                  className="rip-border rip-border--bottom"
                  src={ripWhite}
                  alt="decoration"
                />
              </div>
            ) : (
              <div
                key={item.id}
                className="oration-item oration-item--with-bg-white"
              >
                <div key={item.id} className="oration-item__flex-row">
                  <div className="col col--with-book-left">
                    <div className="img-book img-book--desktop">
                      <GatsbyImage
                        image={item.primary.book_image.gatsbyImageData}
                        alt={item.primary.book_image.alt || "Book"}
                      />
                    </div>
                    <div className="img-featured">
                      <GatsbyImage
                        image={
                          item.primary.featured_image.thumbnails.thumbnail
                            .gatsbyImageData
                        }
                        alt={item.primary.featured_image.alt || "Featured"}
                      />
                    </div>
                  </div>

                  <div className="col">
                    <h3 className="year">{item.primary.year.text}</h3>
                    <h3 className="title">{item.primary.title.text}</h3>
                    <p className="orator">{item.primary.speech_by}</p>
                    <div
                      className="description"
                      dangerouslySetInnerHTML={{
                        __html: item.primary.description.html,
                      }}
                    />
                    <div className="img-book img-book--mobile">
                      <GatsbyImage
                        image={item.primary.book_image.gatsbyImageData}
                        alt={item.primary.book_image.alt || "Book"}
                      />
                    </div>
                    <a
                      className="dl-link"
                      href={item.primary.downloadable_pdf.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      DOWNLOAD PDF
                    </a>

                    <div className="gallery">
                      <h4 className="gallery__heading">Photos from the day</h4>
                      <div className="gallery__list">
                        {item.items.map((photo, index) => (
                          <div key={index} className="img-wrapper">
                            <img
                              src={photo.gallery.thumbnails.thumbnail.url}
                              alt={photo.gallery.alt}
                              onClick={() =>
                                setLightbox({
                                  isOpen: true,
                                  photoIndex: index,
                                  selectedIndexGallery: i,
                                })
                              }
                            />
                          </div>
                        ))}
                      </div>
                      {lightboxController.isOpen && (
                        <Lightbox
                          mainSrc={
                            galleries[lightboxController.selectedIndexGallery][
                              lightboxController.photoIndex
                            ]
                          }
                          nextSrc={
                            galleries[lightboxController.selectedIndexGallery][
                              (lightboxController.photoIndex + 1) %
                                galleries[
                                  lightboxController.selectedIndexGallery
                                ].length
                            ]
                          }
                          prevSrc={
                            galleries[lightboxController.selectedIndexGallery][
                              (lightboxController.photoIndex +
                                galleries[
                                  lightboxController.selectedIndexGallery
                                ].length -
                                1) %
                                galleries[
                                  lightboxController.selectedIndexGallery
                                ].length
                            ]
                          }
                          onCloseRequest={() =>
                            setLightbox({
                              ...lightboxController,
                              isOpen: false,
                            })
                          }
                          onMovePrevRequest={() =>
                            setLightbox({
                              ...lightboxController,
                              photoIndex:
                                (lightboxController.photoIndex +
                                  galleries[
                                    lightboxController.selectedIndexGallery
                                  ].length -
                                  1) %
                                galleries[
                                  lightboxController.selectedIndexGallery
                                ].length,
                            })
                          }
                          onMoveNextRequest={() =>
                            setLightbox({
                              ...lightboxController,
                              photoIndex:
                                (lightboxController.photoIndex + 1) %
                                galleries[
                                  lightboxController.selectedIndexGallery
                                ].length,
                            })
                          }
                          wrapperClassName="oration-modal-wrapper"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>

        <div className="resources">
          <div className="inner-wrapper">
            <h5 className="resources__heading">Other Resources</h5>
            <p>
              Download and read other publications from The Lysicrates
              Foundation.
            </p>
            <Link to="/resources/">
              <span>DISCOVER MORE</span>
            </Link>
          </div>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default OrationsPage;

export const dataQuery = graphql`
  {
    content: prismicOrationPage {
      data {
        title_tag
        meta_description
        banner_description {
          html
        }
        banner_remarks {
          heading
          subheading
          downloadable_pdf {
            url
          }
          photo {
            alt
            thumbnails {
              cropped {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        body {
          ... on PrismicOrationPageDataBodyOration {
            id
            primary {
              year {
                text
              }
              title {
                text
              }
              speech_by
              description {
                html
              }
              downloadable_pdf {
                url
              }
              featured_image {
                alt
                thumbnails {
                  thumbnail {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
              book_image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            items {
              gallery {
                alt
                url
                thumbnails {
                  thumbnail {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
