import React, { useState } from "react";
import { graphql, navigate } from "gatsby";
import Layout from "../layout";
import SEO from "../components/seo";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  mainRed,
  mainGrey,
  mainBlack,
  mainGreen,
  headingNormal,
  pTextLighter,
  pTextBold,
  pText,
  transHover,
  lightGrey,
  screen,
  innerWidth,
} from "../components/common/variables";
import { format } from "date-fns";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaPinterestSquare,
  FaEnvelopeSquare,
} from "react-icons/fa";
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  EmailShareButton,
} from "react-share";
import Lightbox from "react-image-lightbox";

const Wrapper = styled.div`
  margin-top: 100px;
  padding-bottom: 150px;
  @media ${screen.small} {
    padding-bottom: 150px;
  }
  @media ${screen.medium} {
    padding-bottom: 120px;
  }

  p {
    line-height: 1.7;
  }

  a {
    color: ${mainGreen};
    font-weight: 700;
    @media ${screen.withCursor} {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .banner {
    max-width: ${innerWidth};
    margin: 0 auto;
  }

  .title {
    color: ${mainRed};
    font-family: ${headingNormal};
    font-weight: 900;
    font-size: 1.7rem;
    max-width: 960px;
    margin-top: 25px;
    @media ${screen.small} {
      font-size: 3rem;
      margin-top: 50px;
    }
  }

  .published-date {
    font-family: ${pTextLighter};
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 1.1rem;
    @media ${screen.small} {
      font-size: 1.3rem;
    }
  }

  .main-content {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 30px;
    @media ${screen.small} {
      padding: 0 45px;
    }
    @media ${screen.medium} {
      padding: 0 60px;
    }
    @media ${screen.large} {
      padding: 0 20px;
    }
    @media ${screen.xLarge} {
      padding: 0;
    }

    .full-texts-container {
      font-family: ${pTextLighter};
      max-width: 1000px;

      h1 {
        font-size: 1.35rem;
        margin-bottom: 16px;
        line-height: 1.4;
        @media ${screen.small} {
          font-size: 1.6rem;
          margin-bottom: 20px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      h2 {
        font-size: 1.25rem;
        margin-bottom: 16px;
        line-height: 1.4;
        @media ${screen.small} {
          font-size: 1.5rem;
          margin-bottom: 20px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      h3 {
        font-size: 1.2rem;
        margin-bottom: 16px;
        line-height: 1.4;
        @media ${screen.small} {
          font-size: 1.42rem;
          margin-bottom: 20px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      h4 {
        font-size: 1.17rem;
        margin-bottom: 16px;
        line-height: 1.4;
        @media ${screen.small} {
          font-size: 1.35rem;
          margin-bottom: 20px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      h5 {
        font-size: 1.14rem;
        margin-bottom: 16px;
        line-height: 1.4;
        @media ${screen.small} {
          font-size: 1.3rem;
          margin-bottom: 20px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      h6 {
        font-size: 1.12rem;
        margin-bottom: 16px;
        line-height: 1.4;
        @media ${screen.small} {
          font-size: 1.25rem;
          margin-bottom: 20px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      p {
        font-size: 1.05rem;
        margin-bottom: 16px;
        @media ${screen.small} {
          font-size: 1.125rem;
          margin-bottom: 20px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      strong {
        font-family: ${pTextBold};
      }

      a {
        font-family: ${pTextBold};
        color: ${mainBlack};
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .block-quote-container {
      border-left: 3px ${mainRed} solid;
      max-width: 920px;
      margin: 30px auto;
      padding-left: 15px;
      padding-top: 6px;
      padding-bottom: 6px;
      @media ${screen.small} {
        padding-left: 30px;
        border-left: 6px ${mainRed} solid;
      }
      p {
        color: ${mainGrey};
        font-size: 1.25rem;
        font-family: ${pTextLighter};
        font-style: italic;
        margin-bottom: 20px;
        @media ${screen.small} {
          font-size: 1.4rem;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      strong {
        font-family: ${pTextBold};
      }

      a {
        color: ${mainBlack};
        font-family: ${pTextBold};
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .image-text-container {
      display: flex;
      margin-top: 30px;
      margin-bottom: 30px;
      flex-wrap: wrap;

      &__image-container {
        width: 100%;
        @media ${screen.small} {
          width: 50%;
        }
        &--left {
          padding-bottom: 20px;
          @media ${screen.small} {
            padding-right: 50px;
            padding-bottom: 0;
          }
        }

        .caption {
          color: ${mainGrey};
          font-family: ${pTextLighter};
          font-style: italic;
          font-size: 0.9rem;
          margin-top: 6px;
          display: block;
          @media ${screen.small} {
            font-size: 0.95rem;
            margin-top: 9px;
          }
        }
      }

      &__text-container {
        width: 100%;
        align-self: center;
        @media ${screen.small} {
          width: 50%;
        }
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: ${mainBlack};
          font-family: ${pTextBold};
          font-size: 1.22rem;
          margin-bottom: 10px;
          line-height: 1.5;
          @media ${screen.small} {
            font-size: 1.3rem;
            margin-bottom: 20px;
          }
        }

        p {
          margin-bottom: 20px;
          font-size: 1.05rem;
          font-family: ${pTextLighter};
          @media ${screen.small} {
            font-size: 1.125rem;
          }
        }

        strong {
          font-family: ${pTextBold};
        }

        &--left {
          @media ${screen.small} {
            padding-right: 50px;
          }
        }
      }
    }

    .ordered-list-container {
      margin-top: 25px;
      margin-bottom: 25px;
      @media ${screen.small} {
        margin-top: 50px;
        margin-bottom: 50px;
      }

      &__heading {
        color: ${mainBlack};
        font-family: ${pTextBold};
        font-size: 1.22rem;
        margin-bottom: 10px;
        line-height: 1.5;
        @media ${screen.small} {
          font-size: 1.3rem;
          margin-bottom: 20px;
        }
      }

      &__list {
        padding-left: 35px;
        li {
          list-style-type: decimal;
          font-size: 1.05rem;
          margin-bottom: 20px;
          font-family: ${pTextLighter};
          @media ${screen.small} {
            font-size: 1.13rem;
          }
        }
      }
    }

    .unordered-list-container {
      margin-top: 25px;
      margin-bottom: 25px;
      @media ${screen.small} {
        margin-top: 50px;
        margin-bottom: 50px;
      }

      &__heading {
        color: pink;
      }

      &__list {
        padding-left: 35px;
        li {
          list-style-type: disc;
          font-size: 1.05rem;
          margin-bottom: 20px;
          font-family: ${pTextLighter};
          position: relative;
          @media ${screen.small} {
            font-size: 1.13rem;
          }

          &::before {
            content: "•";
            color: ${mainRed};
            font-family: sans-serif;
            font-size: 1.55rem;
            position: absolute;
            left: -22px;
            top: -4px;
            @media ${screen.small} {
              font-size: 1.6rem;
            }
          }
        }
      }
    }

    .hyperlink-container {
      margin-top: 30px;
      margin-bottom: 30px;
      @media ${screen.medium} {
        margin-top: 60px;
        margin-bottom: 60px;
      }
      a {
        color: ${mainGreen};
        font-family: ${pTextBold};
        font-size: 1.22rem;
        line-height: 1.5;
        @media ${screen.small} {
          font-size: 1.3rem;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .video-container {
      position: relative;
      padding-bottom: 56.25%;
      overflow: hidden;
      margin-top: 20px;
      margin-bottom: 20px;
      @media ${screen.small} {
        margin-top: 30px;
        margin-bottom: 30px;
      }
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .full-image-container {
      margin-top: 20px;
      margin-bottom: 20px;
      @media ${screen.small} {
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }

    .share-section {
      background-color: ${lightGrey};
      padding: 20px 25px;
      margin: 25px 0;
      @media ${screen.small} {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 25px 50px;
        margin: 50px 0;
      }

      &__description {
        color: ${mainGrey};
        font-family: ${pTextLighter};
        font-size: 1.22rem;
        @media ${screen.small} {
          font-size: 1.3rem;
          margin-right: 50px;
        }
      }

      &__social-list {
        display: flex;
        margin-top: 10px;
        @media ${screen.small} {
          margin-top: 0;
        }

        li {
          margin-right: 9px;
          @media ${screen.small} {
            margin-left: 12px;
            margin-right: 0;
          }
          svg {
            font-size: 2rem;
            color: ${mainRed};
            transition: ${transHover};
            @media ${screen.small} {
              font-size: 2.3rem;
            }
            cursor: pointer;
            &:hover {
              transform: scale(0.94);
            }
          }
        }
      }
    }

    .soundcloud-text-container {
      display: flex;
      flex-wrap: wrap;
      margin-top: 25px;
      margin-bottom: 25px;
      @media ${screen.small} {
        margin-top: 50px;
        margin-bottom: 50px;
        flex-wrap: nowrap;
      }

      .texts {
        width: 100%;
        align-self: center;
        @media ${screen.small} {
          width: 60%;
          padding-right: 20px;
        }
        @media ${screen.large} {
          padding-right: 0;
        }

        h2,
        h3,
        h4,
        h5,
        h6 {
          color: ${mainBlack};
          font-family: ${pTextBold};
          font-size: 1.22rem;
          margin-bottom: 10px;
          line-height: 1.5;
          @media ${screen.small} {
            font-size: 1.3rem;
            margin-bottom: 20px;
          }
        }

        p {
          margin-bottom: 20px;
          font-size: 1.05rem;
          font-family: ${pTextLighter};
          @media ${screen.small} {
            font-size: 1.125rem;
          }
        }

        strong {
          font-family: ${pTextBold};
        }

        a {
          color: ${mainBlack};
          font-family: ${pTextBold};
        }
      }

      .iframe-container {
        width: 100%;
        @media ${screen.small} {
          width: 40%;
        }

        iframe {
          width: 350px;
          height: 350px;
          display: block;
          margin: 10px auto 25px auto;
          border: 0;
          @media ${screen.small} {
            margin: 0 auto;
          }
        }
      }
    }

    .gallery-section {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
      @media ${screen.small} {
        margin-top: 30px;
        margin-bottom: 30px;
      }

      &__container {
        max-width: ${innerWidth};
        margin: 0 auto;

        &__img-area {
          display: flex;
          flex-wrap: wrap;
          padding: 20px 0;
          justify-content: center;
          @media ${screen.small} {
            justify-content: flex-start;
          }

          .img-container {
            max-width: 50%;
            width: 100%;
            @media ${screen.small} {
              max-width: 33.33%;
            }
            @media ${screen.medium} {
              max-width: 25%;
            }
            img {
              padding: 1px;
              width: 100%;
              max-height: 185px;
              height: 100%;
              object-fit: cover;
              cursor: pointer;
            }
          }
        }
      }
    }

    .navigation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 50px;

      &__next {
        color: ${mainGrey};
        font-family: ${pText};
        font-size: 1rem;
        cursor: pointer;
        transition: ${transHover};

        &:hover {
          color: ${mainRed};
        }
        svg {
          font-size: 1.5rem;
          vertical-align: middle;
        }
      }

      &__all {
        color: ${mainGrey};
        font-family: ${pText};
        font-size: 1rem;
        cursor: pointer;
        text-decoration: underline;
      }

      &__prev {
        color: ${mainGrey};
        font-family: ${pText};
        font-size: 1rem;
        cursor: pointer;
        transition: ${transHover};

        &:hover {
          color: ${mainRed};
        }

        svg {
          font-size: 1.5rem;
          vertical-align: middle;
          margin-bottom: 3px;
        }
      }
    }
  }
`;

const BlogPage = ({ data, pageContext }) => {
  const [lightboxController, setLightbox] = useState({
    isOpen: false,
    photoIndex: 0,
  });
  // prismic content data
  const titleTag = data.content.data.title_tag
    ? data.content.data.title_tag
    : "Lysicrates";
  const metaDescription = data.content.data.meta_description
    ? data.content.data.meta_description
    : "Lysicrates";
  const imageBanner =
    data.content.data.featured_image.gatsbyImageData &&
    data.content.data.featured_image.gatsbyImageData;

  const imageBannerAlt = data.content.data.banner_image
    ? data.content.data.banner_image.alt
    : "Banner";
  const blogTitle = data.content.data.title
    ? data.content.data.title.text
    : "Lysicrates";
  const publishedDate = data.content.data.edit_published_date
    ? format(data.content.data.edit_published_date, "MMMM Do, YYYY")
    : format(data.content.first_publication_date, "MMMM Do, YYYY");

  // next blog is a slug coming from gatsby-node.js config
  const nextBlog = pageContext.next ? pageContext.next.slug : null;
  // prev blog is a slug coming from gatsby-node.js config
  const prevBlog = pageContext.prev ? pageContext.prev.slug : null;

  // image to share for Pinterest share
  const pinterestImgShare = data.content.data.featured_image.url;
  const pinterestDescriptionShare = data.content.data.featured_description;

  // full URL of the page for share
  const shareURL = typeof window !== "undefined" && window.location.href;

  // navigate to next blog
  const goNext = () => {
    navigate(`/news/${nextBlog}`);
  };

  // navigate to previous blog
  const goPrev = () => {
    navigate(`/news/${prevBlog}`);
  };

  // navigate to full blog list
  const goAll = () => {
    navigate(`/news`);
  };

  // array of JSX with the content from Prismic slices
  const allContent = data.content.data.body.map(content => {
    if (content.slice_type === "texts") {
      return (
        <div
          className="full-texts-container"
          dangerouslySetInnerHTML={{ __html: content.primary.description.html }}
          key={content.id}
        />
      );
    }

    if (content.slice_type === "blockquote") {
      return (
        <div
          className="block-quote-container"
          dangerouslySetInnerHTML={{ __html: content.primary.description.html }}
          key={content.id}
        />
      );
    }

    if (content.slice_type === "left_image_w__text") {
      return (
        <div className="image-text-container" key={content.id}>
          <div className="image-text-container__image-container image-text-container__image-container--left">
            <div className="image">
              <GatsbyImage
                image={content.primary.image.gatsbyImageData}
                alt={content.primary.image.alt}
              />
            </div>
            <span className="caption">{content.primary.caption}</span>
          </div>
          <div
            className="image-text-container__text-container"
            dangerouslySetInnerHTML={{
              __html: content.primary.description.html,
            }}
          />
        </div>
      );
    }

    if (content.slice_type === "right_image_w__text") {
      return (
        <div className="image-text-container" key={content.id}>
          <div
            className="image-text-container__text-container image-text-container__text-container--left"
            dangerouslySetInnerHTML={{
              __html: content.primary.description.html,
            }}
          />
          <div className="image-text-container__image-container image-text-container__image-container--right">
            <div className="image image--right">
              <GatsbyImage
                image={content.primary.image.gatsbyImageData}
                alt={content.primary.image.alt}
              />
            </div>
            <span className="caption">{content.primary.caption}</span>
          </div>
        </div>
      );
    }

    if (content.slice_type === "ordered_list") {
      return (
        <div className="ordered-list-container" key={content.id}>
          {content.primary.text_heading && (
            <h6 className="ordered-list-container__heading">
              {content.primary.text_heading}
            </h6>
          )}
          <ol className="ordered-list-container__list">
            {content.items.map((item, i) => (
              <li key={i}>{item.item}</li>
            ))}
          </ol>
        </div>
      );
    }

    if (content.slice_type === "unordered_list") {
      return (
        <div className="unordered-list-container" key={content.id}>
          {content.primary.text_heading && (
            <h6 className="ordered-list-container__heading">
              {content.primary.text_heading}
            </h6>
          )}
          <ul className="unordered-list-container__list">
            {content.items.map((item, i) => (
              <li key={i}>{item.item}</li>
            ))}
          </ul>
        </div>
      );
    }

    if (content.slice_type === "hyperlink") {
      return (
        <div className="hyperlink-container" key={content.id}>
          <a
            href={content.primary.url ? content.primary.url : "/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            {content.primary.text_display
              ? content.primary.text_display
              : "Link Here"}
          </a>
        </div>
      );
    }

    if (content.slice_type === "full_width_video") {
      return (
        <div className="video-container" key={content.id}>
          <iframe
            src={content.primary.video_url}
            allowFullScreen
            title={content.primary.video_url}
          />
        </div>
      );
    }

    if (content.slice_type === "full_width_image") {
      return (
        <div className="full-image-container" key={content.id}>
          <GatsbyImage
            image={content.primary.image.gatsbyImageData}
            alt={content.primary.image.alt}
          />
        </div>
      );
    }

    if (content.slice_type === "soundcloud_w__text") {
      return (
        <div className="soundcloud-text-container" key={content.id}>
          <div
            className="texts"
            dangerouslySetInnerHTML={{
              __html: content.primary.description.html,
            }}
          />
          <div className="iframe-container">
            <iframe
              src={content.primary.soundcloud_url}
              allowFullScreen
              title={content.primary.soundcloud_url}
            />
          </div>
        </div>
      );
    }

    if (content.slice_type === "image_gallery") {
      return (
        <div className="gallery-section" key={content.id}>
          <div className="gallery-section__container">
            <div className="gallery-section__container__img-area">
              {content.items.map((photo, index) => (
                <div key={index} className="img-container">
                  <img
                    src={photo.image.url}
                    alt={photo.image.alt}
                    onClick={() =>
                      setLightbox({ isOpen: true, photoIndex: index })
                    }
                  />
                </div>
              ))}
              {lightboxController.isOpen && (
                <Lightbox
                  mainSrc={
                    content.items[lightboxController.photoIndex].image.url
                  }
                  nextSrc={
                    content.items[
                      (lightboxController.photoIndex + 1) % content.items.length
                    ].image.url
                  }
                  prevSrc={
                    content.items[
                      (lightboxController.photoIndex +
                        content.items.length -
                        1) %
                        content.items.length
                    ].image.url
                  }
                  onCloseRequest={() =>
                    setLightbox({ ...lightboxController, isOpen: false })
                  }
                  onMovePrevRequest={() =>
                    setLightbox({
                      ...lightboxController,
                      photoIndex:
                        (lightboxController.photoIndex +
                          content.items.length -
                          1) %
                        content.items.length,
                    })
                  }
                  onMoveNextRequest={() =>
                    setLightbox({
                      ...lightboxController,
                      photoIndex:
                        (lightboxController.photoIndex + 1) %
                        content.items.length,
                    })
                  }
                />
              )}
            </div>
          </div>
        </div>
      );
    }
  });

  return (
    <Layout>
      <SEO title={titleTag} description={metaDescription} />
      <Wrapper>
        <div className="banner">
          {imageBanner ? (
            <GatsbyImage image={imageBanner} alt={imageBannerAlt} />
          ) : (
            <img src="/x" alt="not found" />
          )}
        </div>

        <main className="main-content">
          <h1 className="title">{blogTitle}</h1>
          <p className="published-date">{publishedDate}</p>
          {allContent.map(content => content)}
          <section className="share-section">
            <p className="share-section__description">
              Share this story, choose your platform!
            </p>
            <ul className="share-section__social-list">
              <li>
                <FacebookShareButton
                  url={shareURL}
                  children={<FaFacebookSquare />}
                />
              </li>
              <li>
                <TwitterShareButton
                  url={shareURL}
                  children={<FaTwitterSquare />}
                />
              </li>
              <li>
                <PinterestShareButton
                  url={shareURL}
                  media={pinterestImgShare}
                  description={pinterestDescriptionShare}
                  children={<FaPinterestSquare />}
                />
              </li>
              <li>
                <EmailShareButton
                  url={shareURL}
                  children={<FaEnvelopeSquare />}
                />
              </li>
            </ul>
          </section>
          <div className="navigation">
            <div
              className="navigation__prev"
              onClick={goPrev}
              style={{ visibility: prevBlog ? `visible` : `hidden` }}
            >
              <MdNavigateBefore /> Previous
            </div>
            <div className="navigation__all" onClick={goAll}>
              View All
            </div>
            <div
              className="navigation__next"
              onClick={goNext}
              style={{ visibility: nextBlog ? `visible` : `hidden` }}
            >
              Next <MdNavigateNext />
            </div>
          </div>
        </main>
      </Wrapper>
    </Layout>
  );
};

export default BlogPage;

export const data = graphql`
  query($id: String!) {
    content: prismicBlog(id: { eq: $id }) {
      first_publication_date
      data {
        title {
          text
        }
        edit_published_date
        title_tag
        meta_description
        featured_image {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        body {
          ... on PrismicBlogDataBodyTexts {
            slice_type
            id
            primary {
              description {
                html
                text
              }
            }
          }
          ... on PrismicBlogDataBodyBlockquote {
            slice_type
            id
            primary {
              description {
                html
              }
            }
          }
          ... on PrismicBlogDataBodyLeftImageWText {
            slice_type
            id
            primary {
              description {
                html
              }
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
              caption
            }
          }
          ... on PrismicBlogDataBodyRightImageWText {
            slice_type
            id
            primary {
              description {
                html
              }
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
              caption
            }
          }
          ... on PrismicBlogDataBodyUnorderedList {
            slice_type
            id
            primary {
              text_heading
            }
            items {
              item
            }
          }
          ... on PrismicBlogDataBodyOrderedList {
            slice_type
            id
            primary {
              text_heading
            }
            items {
              item
            }
          }
          ... on PrismicBlogDataBodyFullWidthVideo {
            slice_type
            id
            primary {
              video_url
            }
          }
          ... on PrismicBlogDataBodyFullWidthImage {
            slice_type
            id
            primary {
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          ... on PrismicBlogDataBodyHyperlink {
            slice_type
            id
            primary {
              text_display
              url
            }
          }
          ... on PrismicBlogDataBodySoundcloudWText {
            slice_type
            id
            primary {
              description {
                html
              }
              soundcloud_url
            }
          }
          ... on PrismicBlogDataBodyImageGallery {
            slice_type
            id
            items {
              image {
                alt
                url
              }
            }
          }
        }
      }
    }
  }
`;
