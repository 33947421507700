import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  pText,
  pTextBold,
  mainRed,
  screen,
  fontTagLight,
  fontTagBold,
  headerBlue,
} from "../common/variables";
import { Link } from "gatsby";
import logo from "../../images/header/logo-2024.svg";
import { IoIosClose } from "react-icons/io";

import header_background from "../../images/header/lysicrates-torn-parper-nav-bar-V2.png";
import top_background from "../../images/header/lysicrates-torn-parper-nav-bar-bottom-V2.png";
import menuBackground from "../../images/header/lysicrates-torn-parper-tablet-mobile-bottom-menu.png";

const Wrapper = styled.div`
  width: 100%;
  @media ${screen.small} {
    display: none;
  }
  .header-spacing {
    position: relative;
    width: 100%;
    height: 80px;
    z-index: 8;
    background-color: grey;

    &__top-background {
      display: none;
      top: 60px;
      left: 0;
      position: absolute;
      width: 100%;
      height: 60px;
      background: url(${top_background}) no-repeat center;
      background-size: 100% 100%;
    }
    &__top-background-home {
      display: none;
      top: 60px;
      left: 0;
      position: absolute;
      width: 100%;
      height: 60px;
      background: url(${top_background}) no-repeat center;
      background-size: cover;
    }
  }
  .banner-spacing {
    position: relative;
    width: 100%;
    height: 40px;
    background-color: grey;
  }
  .header {
    position: fixed;
    top: 0;
    width: 100%;
    background: url(${header_background}) no-repeat center;
    background-size: 100% 100%;
    z-index: 13;
    .top-banner {
      width: 100%;
      height: 40px;
      background: ${headerBlue};
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &__marquee {
        width: 90%;
        height: 30px;
        white-space: nowrap;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          color: white;
          font-family: ${fontTagLight};
          font-size: 0.8rem;
          display: inline-block;
          animation: marquee 15s linear infinite;
          :hover {
            animation-play-state: paused;
          }
          .text {
            color: white;
            font-family: ${fontTagBold};
            font-size: 0.8rem;
          }
          .button {
            margin-left: 20px;
            color: white;
            font-family: ${fontTagLight};
            font-size: 0.8rem;
            padding: 2px 10px;
            border: solid 1px white;
            transition: all 0.3s ease-out;
            :hover {
              color: ${headerBlue};
              background: white;
            }
          }
        }
        @keyframes marquee {
          0% {
            transform: translate(30%, 0);
          }
          50% {
            transform: translate(-30%, 0);
          }
          100% {
            transform: translate(30%, 0);
          }
        }
      }
      &__close {
        position: absolute;
        width: 30px;
        height: 30px;
        right: 20px;
        color: white;
        :hover {
          cursor: pointer;
        }
      }
    }
    .header-area {
      position: relative;
      width: 100%;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      &__logo {
        width: 200px;
        max-height: 50px;
        height: 100%;
        margin: 10px;
        :hover {
          cursor: pointer;
        }
      }
      .nav-icon {
        position: absolute;
        right: 20px;
        width: 30px;
        height: 30px;
        transform: rotate(0deg);
        transition: 0.5s ease-in-out;
        cursor: pointer;
      }
      .nav-icon span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: ${mainRed};
        border-radius: 9px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;
      }
      .nav-icon span:nth-child(1) {
        top: 0px;
      }

      .nav-icon span:nth-child(2),
      .nav-icon span:nth-child(3) {
        top: 10px;
      }

      .nav-icon span:nth-child(4) {
        top: 20px;
      }

      .nav-icon.open span:nth-child(1) {
        top: 10px;
        width: 0%;
        left: 50%;
      }

      .nav-icon.open span:nth-child(2) {
        transform: rotate(45deg);
      }

      .nav-icon.open span:nth-child(3) {
        transform: rotate(-45deg);
      }

      .nav-icon.open span:nth-child(4) {
        top: 10px;
        width: 0%;
        left: 50%;
      }
    }
  }
  .header-menu {
    padding-top: 120px;
    padding-bottom: 40px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 12;
    background: url(${menuBackground}) no-repeat center bottom;
    background-size: cover;
    transition: all 0.4s ease-out;
    transform: translate(0, -100%);
    &__open {
      transform: translate(0, 0%);
    }
    &__container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      overflow: scroll;

      &__links-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &__link {
          margin: 10px;
          a {
            color: ${mainRed};
            font-family: ${pText};
            font-size: 1.125rem;
            white-space: nowrap;
          }
          .active {
            font-family: ${pTextBold};
          }
        }
        &__expand {
          color: ${mainRed};
          font-family: ${pText};
          font-size: 1.125rem;
          :hover {
            cursor: pointer;
          }
        }
        &__hidden-list {
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.3s linear;
        }
        &__hidden-list__open {
          max-height: 300px;
          transition: max-height 0.3s linear;
        }
      }
    }
  }
`;

const MobileNav = ({ events }) => {
  const [menuTogller, setMenuToggler] = useState(false);
  const [hiddenListTogller, setHiddenListToggler] = useState(false);
  const [bannerController, setBannerController] = useState(true);
  // toggle menu
  const toggleMenu = () => {
    // if close menu, close hidden list as well
    !menuTogller === false && setHiddenListToggler(false);
    setMenuToggler(!menuTogller);
  };
  // toggle hidden list
  const toggleHiddenList = () => {
    setHiddenListToggler(!hiddenListTogller);
  };
  // deal with top background
  const [path, setPath] = useState("normal");
  useEffect(() => {
    if (path && window.location.pathname === "/") {
      setPath("/");
    } else if (path) {
      setPath("normal");
    }
  }, [path]);

  // get latest event
  const latestEvent = events[0];
  return (
    <Wrapper>
      <div
        className="banner-spacing"
        style={bannerController ? { display: "block" } : { display: "none" }}
      />
      <div className="header-spacing">
        <div
          style={{ display: path === "normal" ? `block` : `none` }}
          className="header-spacing__top-background"
        />
        <div
          style={{ display: path === "/" ? `block` : `none` }}
          className="header-spacing__top-background-home"
        />
      </div>
      <div className="header">
        <div
          style={bannerController ? { display: "flex" } : { display: "none" }}
          className="top-banner"
        >
          <div className="top-banner__marquee">
            <a href="https://martinlysicrates.com.au/" target="blank">
              <p>
                THE <span className="text">MARTIN-LYSICRATES</span> PRIZE
                celebrates the best of Australian playwrights.
                <span className="button">Visit site</span>
              </p>
            </a>
          </div>
          <IoIosClose
            className="top-banner__close"
            onClick={() => setBannerController(false)}
            alt="close"
          />
        </div>
        <div className="header-area">
          <Link to="/" activeClassName="active">
            <img className="header-area__logo" src={logo} alt="logo" />
          </Link>
          <div
            className={menuTogller ? "nav-icon open" : "nav-icon"}
            onClick={() => toggleMenu()}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <div
        className={
          menuTogller === false
            ? "header-menu"
            : "header-menu header-menu__open"
        }
      >
        <div className="header-menu__container">
          <div className="header-menu__container__links-list">
            <div className="header-menu__container__links-list__link">
              <Link to="/" activeClassName="active">
                HOME
              </Link>
            </div>
            <div className="header-menu__container__links-list__link">
              <Link to="/about/" activeClassName="active">
                ABOUT
              </Link>
            </div>
            <div className="header-menu__container__links-list__link">
              <Link
                to={`/the-${latestEvent}-lysicrates-event`}
                activeClassName="active"
              >
                EVENTS
              </Link>
              <span
                className="header-menu__container__links-list__expand"
                onClick={toggleHiddenList}
              >
                {" "}
                +
              </span>
            </div>
            <div
              className={
                hiddenListTogller === false
                  ? "header-menu__container__links-list__hidden-list"
                  : "header-menu__container__links-list__hidden-list__open"
              }
            >
              {events.map((event) => (
                <div
                  className="header-menu__container__links-list__link"
                  key={event}
                >
                  <Link
                    to={`/the-${event}-lysicrates-event/`}
                    activeClassName="active"
                  >
                    {event}
                  </Link>
                </div>
              ))}
            </div>
            <div className="header-menu__container__links-list__link">
              <Link to="/resources/" activeClassName="active">
                RESOURCES
              </Link>
            </div>
            <div className="header-menu__container__links-list__link">
              <Link to="/history/" activeClassName="active">
                LYSICRATES
              </Link>
            </div>
            <div className="header-menu__container__links-list__link">
              <Link to="/james-martin/" activeClassName="active">
                JAMES MARTIN
              </Link>
            </div>
            <div className="header-menu__container__links-list__link">
              <Link to="/orations/" activeClassName="active">
                ORATIONS
              </Link>
            </div>
            <div className="header-menu__container__links-list__link">
              <Link to="/news/" activeClassName="active">
                NEWS
              </Link>
            </div>
            <div className="header-menu__container__links-list__link">
              <Link to="/board/" activeClassName="active">
                THE BOARD
              </Link>
            </div>
            <div className="header-menu__container__links-list__link">
              <Link to="/accolades/" activeClassName="active">
                ACCOLADES
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default MobileNav;
