import React from "react";
import styled from "styled-components";
import Layout from "../layout";
import SEO from "../components/seo";
import { mainRed, headingBold, screen } from "../components/common/variables";
import { Helmet } from "react-helmet";
import bgImg from "../images/donate/lysicrates-donation-background.png";

const Wrapper = styled.div`
  .bg-wrapper {
    padding: 60px 30px 80px 30px;
    @media ${screen.small} {
      padding: 90px 30px 90px 30px;
    }
    @media ${screen.large} {
      padding: 180px 0 220px 0;
    }
  }

  .title {
    color: ${mainRed};
    font-family: ${headingBold};
    font-size: 1.4rem;
    text-align: center;
    @media ${screen.small} {
      font-size: 1.8rem;
    }

    &--brand {
      display: block;
      font-size: 2.05rem;
      margin-top: 16px;
      @media ${screen.small} {
        font-size: 3.3rem;
      }
    }
  }

  .form-container {
    max-width: 685px;
    margin: 26px auto 0 auto;
    display: flex;
    @media ${screen.small} {
      margin: 80px auto 0 auto;
    }

    iframe {
      display: block;
      margin: auto;
      max-width: 429px;
      width: 100%;
      height: 685px;
    }
  }
`;

const DonatePage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Donation"
        description="The Lysicrates Foundation is a not-for-profit organisation and its events are free to attend."
      />
      <Helmet>
        <script src="https://donorbox.org/widget.js" paypalExpress="false" />
      </Helmet>
      <Wrapper>
        <div
          className="bg-wrapper"
          style={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${bgImg})`,
          }}
        >
          <h1 className="title">
            Thank you for supporting <br />
            <span className="title--brand">Lysicrates Foundation</span>
          </h1>
          <div className="form-container">
            <iframe
              src="https://donorbox.org/embed/lysicrates-donations"
              seamless="seamless"
              name="donorbox"
              frameBorder="0"
              scrolling="no"
              title="donation-form"
              allowpaymentrequest="true"
            />
          </div>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default DonatePage;
