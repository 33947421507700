import React, { useState, useEffect } from "react";
import Layout from "../layout";
import SEO from "../components/seo";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import addToMailchimp from "gatsby-plugin-mailchimp";
import Slider from "react-slick";
import {
  emailRegex,
  headingNormal,
  headingBold,
  brightGreen,
  mainOrange,
  headingItalic,
  mainGrey,
  mainBlack,
  pText,
  pTextLighter,
  mainRed,
  screen,
  darkBlue,
  pTextBold,
  mainGreen,
  innerWidth,
  backgroundGrey,
  menuBorderGrey,
  borderGrey,
} from "../components/common/variables";
import { IoIosClose } from "react-icons/io";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import styled from "styled-components";
import bottomEdge from "../images/home/grey-rip.png";
import historyTopEdge from "../images/home/homepage-torn-paper-bottom.png";

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;

  .banner-section {
    background-image: ${(props) => `url(${props.bannerBackground})`};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 80%;
    z-index: 1;
    @media ${screen.small} {
      background-position: 60%;
    }
    @media ${screen.medium} {
      background-position: center;
    }

    &__container {
      max-width: ${innerWidth};
      height: 300px;
      margin: 0 auto;
      padding: 20px 30px;
      @media ${screen.small} {
        padding: 20px 90px;
        height: 540px;
      }
      @media ${screen.medium} {
        padding: 20px 60px;
      }
      @media ${screen.large} {
        padding: 20px 20px 20px 0;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      @media ${screen.small} {
        justify-content: flex-start;
      }
      &__box {
        display: none;
        max-width: 600px;
        position: relative;
        z-index: 8;
        margin: 50px 0 0 0;
        @media ${screen.small} {
          display: block;
        }
        @media ${screen.medium} {
          z-index: 9;
        }

        .title {
          padding-bottom: 20px;

          &__first-line {
            text-align: center;
            padding-bottom: 10px;
            color: white;
            font-family: ${headingBold};
            font-size: 3.05rem;
            line-height: 4rem;
            @media ${screen.small} {
              text-align: left;
              font-size: 2.8rem;
            }
            @media ${screen.medium} {
              font-size: 3.35rem;
            }
            @media ${screen.large} {
              text-align: left;
            }
          }
          &__second-line {
            text-align: center;
            font-weight: 100;
            font-size: 1.5rem;
            display: block;
            margin: 20px 0 0 0;
            @media ${screen.small} {
              text-align: left;
              color: white;
            }
          }
        }

        .cta {
          display: none;
          max-width: 280px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 10px 20px 10px 20px;
          border: 2px solid ${mainRed};
          font-family: ${pTextBold};
          line-height: 26px;
          font-size: 1.125rem;
          color: ${mainRed};
          cursor: pointer;
          transition: all 0.3s ease-out;
          max-width: max-content;
          border-color: white;
          color: white;
          :hover {
            border-color: ${mainGrey};
            color: ${mainGrey};
          }
        }
      }
    }
    &__bottom-edge {
      width: 100%;
      height: 60px;
      background: url(${bottomEdge}) no-repeat top;
      background-size: cover;
      display: none;
      @media ${screen.medium} {
        display: block;
      }
    }
  }

  .video-modal {
    z-index: 12;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(256, 256, 256, 0.7);
    .box-wrapper {
      width: 100%;
      max-width: 1400px;
      position: relative;
      .video-container {
        width: 100%;
        padding: 30% 0;
        box-sizing: border-box;
        position: relative;
        iframe {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      .button {
        position: absolute;
        top: 5%;
        right: 2%;
        width: 40px;
        height: 40px;
        color: ${borderGrey};
        opacity: 0.7;
      }
    }
  }

  .video-voting-section {
    width: 100%;
    position: relative;

    .ripped-border-img {
      display: block;
      transform: scaleY(-1);
      width: 520px;
      left: 0;
      position: absolute;
      top: -20px;
      @media ${screen.small} {
        left: auto;
        width: 100%;
      }
      @media ${screen.medium} {
        top: -45px;
      }
      @media ${screen.large} {
        top: -48px;
      }
      @media ${screen.xLarge} {
        top: -65px;
      }
    }

    .inner-wrapper {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 50px 30px 30px 30px;
      @media ${screen.small} {
        padding: 80px 45px 40px 45px;
      }
      @media ${screen.medium} {
        padding: 100px 50px 60px 50px;
      }
      @media ${screen.large} {
        padding: 100px 0 50px 0;
      }
    }
  }

  .vote-section {
    background: ${backgroundGrey};
    padding: 40px 30px 80px;
    @media ${screen.small} {
      padding: 40px 45px 80px;
    }
    @media ${screen.medium} {
      padding: 40px 60px 80px;
    }
    @media ${screen.large} {
      padding: 40px 20px 80px;
    }
    @media ${screen.xLarge} {
      padding: 40px 0 80px;
    }
    &__container {
      max-width: ${innerWidth};
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      &__title-area {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 40px;
        .responsive-title {
          text-align: center;
          padding-bottom: 30px;
          color: ${mainBlack};
          font-family: ${headingBold};
          font-size: 2.3rem;
          line-height: 1.45;
          @media ${screen.small} {
            display: none;
          }

          &--description {
            display: block;
            text-align: center;
            margin: 0 0 30px 0;
            @media ${screen.small} {
              display: none;
            }
          }
        }
        .responsive-cta {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 10px 20px 10px 20px;
          border: 2px solid ${mainRed};
          font-family: ${pTextBold};
          line-height: 26px;
          font-size: 1.125rem;
          color: ${mainRed};
          cursor: pointer;
          transition: all 0.3s ease-out;
          :hover {
            border-color: ${mainGrey};
            color: ${mainGrey};
          }
          @media ${screen.small} {
            display: none;
          }
        }
        .main-title {
          text-align: center;
          font-family: ${headingNormal};
          font-weight: bold;
          color: ${mainRed};
          font-size: 2.5rem;
          padding-bottom: 20px;
          padding-top: 60px;
          max-width: 700px;
          @media ${screen.small} {
            padding-top: 30px;
            padding-bottom: 30px;
            font-size: 3.8rem;
          }
        }
        .title {
          text-align: center;
          font-family: ${headingNormal};
          font-weight: bold;
          font-size: 2.25rem;
          @media ${screen.small} {
            font-size: 3rem;
          }
        }
        .title-tag {
          text-align: center;
          font-family: ${headingBold};
          font-size: 2.25rem;
          color: ${mainRed};
          margin: 7px 0 0 0;
        }
      }
      &__text-area {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        .content1 {
          max-width: 800px;
          padding-bottom: 50px;

          .subtitle {
            text-align: center;
            font-family: ${headingNormal};
            font-weight: bold;
            margin: 0 0 30px 0;
            font-size: 2.25rem;
            @media ${screen.small} {
              font-size: 3rem;
            }
          }

          p {
            text-align: center;
            font-family: ${pTextBold};
            line-height: 30px;
            font-size: 1.125rem;
            margin: 0 0 22px 0;
            color: ${mainBlack};
          }
        }

        .video-wrapper {
          width: 100%;
          max-width: 828px;
          margin: 0 0 10px 0;
          @media ${screen.small} {
            margin: 0 0 60px 0;
          }

          .video-heading {
            padding-bottom: 25px;
            text-align: center;
            font-family: ${headingBold};
            font-size: 1.6875rem;
            color: ${mainRed};
            max-width: 310px;
            margin: 0 auto;
            @media ${screen.small} {
              font-size: 2.5rem;
              margin: 0 auto 10px auto;
              max-width: 550px;
            }
          }

          .video {
            position: relative;
            overflow: hidden;
            width: 100%;
            padding-top: 56.25%;

            iframe {
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              width: 100%;
              height: 100%;
            }
          }
        }

        .content2 {
          padding-bottom: 25px;

          p {
            text-align: center;
            font-family: ${headingBold};
            font-size: 1.6875rem;
            color: ${mainRed};
            @media ${screen.small} {
              font-size: 2.5rem;
            }
          }
        }
        .content3 {
          max-width: 1000px;
          padding-bottom: 60px;

          p {
            text-align: center;
            font-family: ${pText};
            line-height: 26px;
            font-size: 1.125rem;
            color: ${mainBlack};
            @media ${screen.small} {
              line-height: 30px;
            }
          }
        }
      }
      &__video-area {
        width: 100%;
        padding-bottom: 30px;
        @media ${screen.small} {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
        }
        .video-box {
          @media ${screen.small} {
            width: 32%;
          }
          .video-container {
            width: 100%;
            padding: 30% 0;
            box-sizing: border-box;
            position: relative;
            iframe {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
          .title {
            padding: 8px 0;
            text-align: center;
            font-family: ${pTextBold};
            line-height: 26px;
            font-size: 1.25rem;
            color: ${mainBlack};
          }
          .playwright {
            padding-bottom: 30px;
            text-align: center;
            font-family: ${pText};
            line-height: 26px;
            font-size: 1.125rem;
            color: ${mainBlack};
          }
        }
      }
      &__vote-area {
        max-width: 520px;
        padding-bottom: 25px;
        margin: 0 auto;

        .title {
          padding-bottom: 25px;
          text-align: center;
          font-family: ${headingBold};
          font-size: 1.6875rem;
          color: ${mainRed};
          @media ${screen.small} {
            font-size: 2.5rem;
          }
        }
        .content {
          p {
            text-align: center;
            font-family: ${pText};
            line-height: 26px;
            font-size: 1.125rem;
            color: ${mainRed};
            @media ${screen.small} {
              line-height: 30px;
            }
          }
        }
      }
      &__cta-area {
        span {
          display: block;
          text-align: center;
          padding: 15px 30px;
          border-radius: 5px;
          font-family: ${pText};
          font-size: 1.5625rem;
          color: white;
          cursor: pointer;
          background-color: ${mainRed};
          transition: all 0.3s ease-out;
        }
        span:hover {
          background-color: ${mainGrey};
        }
      }

      .cognito {
        width: 100%;

        .c-forms-heading {
          margin: 10px;
          border-radius: 5px;
          @media ${screen.small} {
            margin: 10px 20px;
          }
        }
        .c-forms-form-title {
          text-align: center;
          padding: 8px !important;
          @media ${screen.small} {
            text-align: left;
          }
        }
        .c-editor {
          display: block;
          width: 100%;
          padding: 10px;
          @media ${screen.small} {
            padding: 10px 20px;
          }
        }
        input:focus,
        select:focus {
          outline: none;
        }
        input[type="text"] {
          padding: 10px 0;
          border-bottom: 1px solid ${menuBorderGrey};
        }
        .c-forms-form select {
          padding: 10px 0;
          border-bottom: 1px solid ${menuBorderGrey};
        }
        .c-validation {
          background-color: ${mainRed} !important;
          padding: 10px;
          margin: 10px;
          @media ${screen.small} {
            margin: 10px 20px;
          }
        }
        div.c-html {
          padding: 10px;
          @media ${screen.small} {
            padding: 10px 20px;
          }
        }
        .c-action {
          width: 100%;
          padding: 10px;
          margin: 0;
          @media ${screen.small} {
            padding: 10px 20px;
          }
        }
        #c-submit-button {
          float: left;
          width: 100%;
          margin: 0;
          padding: 13px;
          transition: all 0.3s ease-out;
          @media ${screen.small} {
            width: 300px;
          }
        }
        #c-submit-button:hover {
          background-color: ${mainGrey};
        }
        div.c-forms-confirmation {
          min-height: 150px;
          padding-top: 125px;
        }
      }
    }
  }

  .submit-section {
    width: 100%;
    background: ${mainRed};
    &__container {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 30px 30px;
      @media ${screen.small} {
        padding: 30px 45px;
      }
      @media ${screen.medium} {
        padding: 30px 60px;
      }
      @media ${screen.large} {
        padding: 40px 20px 30px 20px;
      }
      @media ${screen.xLarge} {
        padding: 40px 0 30px 0;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media ${screen.large} {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
      &__first-line {
        font-family: ${pTextBold};
        font-size: 1.45rem;
        color: white;
        text-align: center;
        max-width: 450px;
        padding: 0 0 20px 0;
        @media ${screen.medium} {
          padding: 10px 0;
        }
      }
      &__second-line {
        position: relative;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media ${screen.small} {
          width: auto;
        }

        .names-row {
          input {
            border: none;
            border-radius: 0;
            width: 100%;
            height: 36px;
            padding: 5px 9px;
            font-size: 0.88rem;
            font-family: ${pText};
            outline: none;
            background: white;
            margin-bottom: 10px;
            @media ${screen.small} {
              width: 49%;
              margin-bottom: 0;
            }

            &:first-child {
              @media ${screen.small} {
                margin-right: 10px;
              }
            }
          }
        }
        &__input {
          width: 100%;
          height: 36px;
          padding: 8px 15px;
          padding: 5px 9px;
          /* margin: 5px; */
          border: none;
          border-radius: 0;
          outline: none;
          font-family: ${pText};
          font-size: 0.88rem;
          margin-bottom: 10px;
          @media ${screen.small} {
            width: 310px;
            margin: 10px 10px 10px 0;
          }
        }
        &__submit {
          width: 100%;
          padding: 0 50px;
          margin: 5px 0;
          border-radius: 0;
          height: 45px;
          height: 36px;
          font-family: ${pTextBold};
          font-size: 0.88rem;
          color: white;
          border: solid 2px white;
          background: ${mainRed};
          transition: all 0.3s ease-out;
          @media ${screen.small} {
            width: 200px;
          }
          :hover {
            background-color: white;
            color: ${mainRed};
            cursor: pointer;
          }
          :active,
          :focus,
          :focus:active {
            background-image: none;
            outline: 0;
            box-shadow: none;
          }
        }
        span {
          font-family: ${pText};
          font-size: 0.8rem;
          color: white;
          position: absolute;
          bottom: -15px;
          left: 0;
        }
      }
    }
  }
  .slides-section {
    width: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    &__title-box {
      padding: 20px 30px;
      @media ${screen.small} {
        padding: 20px 45px;
      }
      @media ${screen.medium} {
        padding: 20px 60px;
      }
      @media ${screen.large} {
        padding: 20px 20px;
      }
      @media ${screen.xLarge} {
        padding: 20px;
      }
      text-align: center;
      h1 {
        padding-top: 60px;
        padding-bottom: 10px;
        font-family: ${pTextLighter};
        font-size: 2rem;
        color: ${mainGreen};
      }
      span {
        padding-bottom: 10px;
        font-family: ${headingBold};
        font-size: 3rem;
      }
      .slide-p-container {
        max-width: 500px;
        @media ${screen.medium} {
          max-width: 100%;
        }
        p {
          font-family: ${pTextLighter};
          font-size: 1.125rem;
          line-height: 30px;
          color: ${mainBlack};
        }
      }
      h3 {
        padding-top: 30px;
        color: ${mainGreen};
        font-family: ${pText};
        font-size: 1.4rem;
        @media ${screen.small} {
          display: none;
        }
        span {
          font-family: ${headingBold};
          font-size: 1.7rem;
          color: ${mainGreen};
        }
      }
    }
    &__slide-container {
      width: 100%;
      overflow: hidden;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .next-btn {
        color: white;
        opacity: 0.7;
        transition: all 0.3s ease-out;
        :hover {
          cursor: pointer;
          color: ${mainGreen};
          opacity: 1;
        }
        position: absolute;
        top: 27%;
        right: 15px;
        z-index: 2;
        width: 60px;
        height: 60px;
        @media ${screen.small} {
          top: 33%;
          right: 30px;
        }
      }

      .prev-btn {
        color: white;
        opacity: 0.7;
        transition: all 0.3s ease-out;
        :hover {
          cursor: pointer;
          color: ${mainGreen};
          opacity: 1;
        }
        position: absolute;
        top: 27%;
        left: 15px;
        z-index: 2;
        width: 60px;
        height: 60px;
        @media ${screen.small} {
          top: 33%;
          left: 30px;
        }
      }
    }
    &__slide-box {
      padding-bottom: 100px;
      width: ${innerWidth};
      @media ${screen.small} {
        width: 1800px;
      }
      &__box {
        .card {
          position: relative;
          width: 320px;
          height: 250px;
          margin: 0 auto;
          @media ${screen.small} {
            width: 550px;
            height: 400px;
          }
          &__image {
            width: 100%;
            height: 100%;
          }
          &__title {
            position: absolute;
            left: 0;
            bottom: 0;
            background: ${mainGreen};
            width: 100%;
            height: 50px;
            @media ${screen.small} {
              height: 80px;
            }
            &__desktop {
              display: none;
              width: 100%;
              height: 100%;
              padding: 0 20px;
              align-items: center;
              justify-content: space-between;
              @media ${screen.large} {
                display: flex;
              }
            }
            &__tablet {
              display: none;
              width: 100%;
              height: 100%;
              padding: 0 20px;
              align-items: center;
              justify-content: center;
              @media ${screen.small} {
                display: flex;
              }
              @media ${screen.large} {
                display: none;
              }
            }
            &__mobile {
              width: 100%;
              height: 100%;
              padding: 0 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              @media ${screen.small} {
                display: none;
              }
            }
            h3 {
              color: white;
              font-family: ${pTextLighter};
              font-size: 1.4rem;
            }
            strong {
              font-family: ${headingBold};
              font-size: 1.7rem;
              color: white;
            }
            span {
              padding: 10px 20px;
              border: solid 2px white;
              font-family: ${pTextLighter};
              font-size: 1.125rem;
              color: white;
              transition: all 0.3s ease-out;
              :hover {
                background-color: white;
                color: ${mainGreen};
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
  .history-section {
    position: relative;
    width: 100%;
    height: 400px;
    background: ${(props) => `url(${props.historyBackgroundMobile})`} no-repeat
      top center;
    background-size: cover;
    @media ${screen.medium} {
      background: ${(props) => `url(${props.historyBackgroundTablet})`}
        no-repeat top center;
      background-size: cover;
    }
    @media ${screen.large} {
      background: ${(props) => `url(${props.historyBackgroundDesktop})`}
        no-repeat top right;
      background-size: cover;
    }
    &__top-edge {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 60px;
      background: url(${historyTopEdge}) no-repeat top;
      background-size: cover;
    }
    &__container {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 20px 30px;
      @media ${screen.small} {
        padding: 20px 45px;
      }
      @media ${screen.medium} {
        padding: 20px 60px;
      }
      @media ${screen.large} {
        padding: 20px 20px;
      }
      @media ${screen.xLarge} {
        padding: 20px;
      }
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      @media ${screen.medium} {
        justify-content: flex-start;
      }
      &__box {
        max-width: 500px;
        text-align: center;
        @media ${screen.medium} {
          text-align: left;
        }
        h1 {
          padding-bottom: 10px;
          font-family: ${headingBold};
          font-size: 2.5rem;
          color: white;
        }
        p {
          padding-bottom: 40px;
          font-family: ${pText};
          font-size: 1.125rem;
          line-height: 30px;
          color: white;
        }
        span {
          border: solid 2px white;
          padding: 10px 60px;
          background-color: white;
          color: ${mainGreen};
          font-family: ${pText};
          font-size: 1rem;
          line-height: 30px;
          transition: all 0.3s ease-out;
          :hover {
            background-color: transparent;
            color: white;
            cursor: pointer;
          }
          @media ${screen.small} {
            padding: 10px 80px;
            background-color: transparent;
            color: white;
            :hover {
              background-color: white;
              color: ${mainGreen};
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .prize-section {
    position: relative;
    width: 100%;
    background-color: ${darkBlue};
    overflow: hidden;
    &__img-desktop {
      display: none;
      position: absolute;
      right: -60px;
      bottom: 0;
      max-width: 800px;
      width: 100%;
      @media ${screen.medium} {
        display: block;
      }
    }
    &__img-mobile {
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: auto;
      @media ${screen.medium} {
        display: none;
      }
    }
    &__container {
      position: relative;
      max-width: ${innerWidth};
      min-height: 550px;
      margin: 0 auto;
      padding: 30px;
      @media ${screen.small} {
        padding: 60px 45px;
      }
      @media ${screen.medium} {
        padding: 60px 60px;
      }
      @media ${screen.large} {
        padding: 60px 20px;
      }
      display: flex;
      align-items: flex-start;
      justify-content: center;
      @media ${screen.small} {
        min-height: 750px;
      }
      @media ${screen.medium} {
        min-height: 400px;
        align-items: center;
        justify-content: flex-start;
      }
      &__box {
        max-width: 500px;
        text-align: center;
        @media ${screen.medium} {
          text-align: left;
        }
        h1 {
          padding-bottom: 10px;
          font-family: ${headingItalic};
          font-size: 2.5rem;
          color: ${mainOrange};
        }
        .prize-p-container {
          max-width: 100%;
          @media ${screen.medium} {
            max-width: 340px;
          }
          @media ${screen.large} {
            max-width: 100%;
          }
          p {
            padding-bottom: 40px;
            font-family: ${pText};
            font-size: 1.125rem;
            line-height: 30px;
            color: white;
          }
        }

        span {
          padding-bottom: 3px;
          border-bottom: solid 2px white;
          font-family: ${headingItalic};
          font-size: 2rem;
          line-height: 30px;
          color: white;
          transition: all 0.3s ease-out;
          :hover {
            color: ${brightGreen};
            border-bottom: solid 2px ${brightGreen};
            cursor: pointer;
          }
        }
      }
    }
  }
  .sponsors-section {
    width: 100%;
    background: white;
    &__container {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 80px 0 140px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      text-align: center;
      h3 {
        padding: 0px 30px 20px;
        @media ${screen.small} {
          font-size: 3rem;
          padding: 20px 45px;
        }
        @media ${screen.medium} {
          padding: 20px 60px;
        }
        @media ${screen.large} {
          padding: 20px 20px;
        }
        @media ${screen.xLarge} {
          padding: 20px;
        }
        font-family: ${headingBold};
        font-size: 2rem;
        color: ${mainGrey};
      }
      &__icon-box {
        width: 100%;
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .logo-container {
          width: 120px;
          margin: 10px;
          @media ${screen.small} {
            width: 180px;
            margin: 20px;
          }
        }
      }
    }
  }
`;

const CarouselButton = styled.div`
  position: absolute;
  top: 20px;
  width: 10px;
  height: 10px;
  border: 2px ${mainGreen} solid;
  :focus {
    background: ${mainGreen};
  }
`;

const IndexPage = ({ data }) => {
  // get page data from graphql
  const titleTag = data.seo.data.title_tag;
  const metaDescription = data.seo.data.meta_description;
  const banners = data.banner.data.banner;
  const bannerTitle = banners[0].title;
  const bannerBackground = banners[0].image.url;
  const bannerSubtitle = banners[0].subtitle;
  const bannerCtaText = banners[0].cta_text1;
  const bannerVideo = banners[0].banner_video;
  const bannerLink = banners[0].banner_link;
  const carouselDescription = data.carousels.data.heading_description;
  const carousels = data.carousels.data.list;
  const historyTitle = data.history.data.title1;
  const historyDescription = data.history.data.description;
  const historyLink = data.history.data.visit_url;
  const historyBackgroundMobile =
    data.history.data.background_image__mobile_.url;
  const historyBackgroundTablet =
    data.history.data.background_image__tablet_.url;
  const historyBackgroundDesktop =
    data.history.data.background_image__desktop_.url;
  const prizeTitle = data.prize.data.title2;
  const prizeDescription = data.prize.data.description1;
  const prizeLink = data.prize.data.visit_url1;
  const prizeBackgroundMobile =
    data.prize.data.background_image__mobile_1.gatsbyImageData;
  const prizeBackgroundMobileAlt =
    data.prize.data.background_image__mobile_1.alt;
  const prizeBackgroundTablet =
    data.prize.data.background_image__tablet_1.gatsbyImageData;

  const prizeBackgroundDesktop =
    data.prize.data.background_image__desktop_1.gatsbyImageData;
  const prizeBackgroundDesktopAlt =
    data.prize.data.background_image__desktop_1.alt;
  const sponsors = data.sponsors.data.sponsor;

  const videoList =
    (data.vote.data.body &&
      data.vote.data.body[0] &&
      data.vote.data.body[0].items) ||
    [];

  const primary =
    (data.vote.data.body &&
      data.vote.data.body[0] &&
      data.vote.data.body[0].primary) ||
    {};

  // const voteCtaText = data.vote.data.cta_text ? data.vote.data.cta_text : "";
  // const [isVoteOpen, setIsVoteOpen] = useState(false);

  // // import cognitoforms script
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.id = "cognito-script";
  //   script.src = "https://services.cognitoforms.com/s/ICNaStdWvUilXG7Z33E01A";
  //   script.async = true;
  //   !document.getElementById("cognito-script") &&
  //     document.body.appendChild(script);
  // });

  // // insert cognitoforms script
  // useEffect(() => {
  //   const cognitoForm = document.getElementById("cognito-form");
  //   const runScript = document.createElement("script");
  //   runScript.innerHTML = `Cognito.load("forms", { id: "3" });`;
  //   cognitoForm &&
  //     cognitoForm.children.length === 0 &&
  //     cognitoForm.appendChild(runScript);
  // });

  // display and remove video modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  // handle submit subscribe form
  const [email, setEmail] = useState("");
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [errorFeedback, setErrorFeedback] = useState(false);
  const [successFeedback, setSuccessFeedback] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const firstNameInput = (e) => {
    setFName(e.target.value);
  };

  const lastNameInput = (e) => {
    setLName(e.target.value);
  };

  const emailInput = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // reset feedback
    setErrorFeedback(false);
    setSuccessFeedback(false);

    if (!fName.length || !lName.length || !emailRegex.test(email)) {
      // if email is invalid
      setErrorFeedback(true);
      setSuccessFeedback(false);
      setErrorMsg("Please complete all required fields.");
      setEmail("");
      setFName("");
      setLName("");
    } else {
      addToMailchimp(email, {
        FNAME: fName,
        LNAME: lName,
      }).then((data) => {
        const checkEmail = data.msg.toLowerCase().split(" ");
        if (
          checkEmail.includes(email.toLowerCase()) ||
          checkEmail.includes(`"${email.toLowerCase()}"`)
        ) {
          // if email already in the list
          setErrorFeedback(true);
          setSuccessFeedback(false);
          setErrorMsg("Looks like you're already subscribed.");
          setEmail("");
          setFName("");
          setLName("");
        } else {
          // if email successfully added
          setSuccessFeedback(true);
          setErrorFeedback(false);
          setEmail("");
          setFName("");
          setLName("");
        }
      });
    }
  };

  // react slick setting
  const [carouselIndex, setcarouselIndex] = useState(0);
  let prevSlick;
  let nextSlick;
  var settings = {
    centerMode: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    customPaging: (i) => (
      <CarouselButton
        style={
          carouselIndex === i
            ? { background: mainGreen }
            : { background: "white" }
        }
      />
    ),
    beforeChange: (current, next) => setcarouselIndex(next),
  };

  // disable vertival scroll when swipe left or right
  const [firstClientX, setFirstClientX] = useState();
  const [firstClientY, setFirstClientY] = useState();
  const touchStart = (e) => {
    setFirstClientX(e.touches[0].clientX);
    setFirstClientY(e.touches[0].clientY);
  };
  const preventTouch = (e) => {
    const minValue = 10; // threshold
    const clientX = e.touches[0].clientX - firstClientX;
    const clientY = e.touches[0].clientY - firstClientY;
    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };
  useEffect(() => {
    window.addEventListener("touchstart", touchStart);
    window.addEventListener("touchmove", preventTouch, { passive: false });
    return () => {
      window.removeEventListener("touchstart", touchStart);
      window.removeEventListener("touchmove", preventTouch, {
        passive: false,
      });
    };
  });

  return (
    <Layout>
      <SEO title={titleTag} description={metaDescription} />
      <Wrapper
        prizeBackgroundMobile={prizeBackgroundMobile}
        prizeBackgroundTablet={prizeBackgroundTablet}
        prizeBackgroundDesktop={prizeBackgroundDesktop}
        historyBackgroundMobile={historyBackgroundMobile}
        historyBackgroundTablet={historyBackgroundTablet}
        historyBackgroundDesktop={historyBackgroundDesktop}
        bannerBackground={bannerBackground}
      >
        <div className="banner-section">
          <div className="banner-section__container">
            <div className="banner-section__container__box">
              <h1 className="title">
                <span className="title__first-line">{bannerTitle}</span>
                <span className="title__second-line">{bannerSubtitle}</span>
              </h1>
              <span
                className="cta"
                onClick={() => {
                  if (bannerLink.text) {
                    window.open(bannerLink.text, "_blank");
                  } else setIsModalOpen(true);
                }}
              >
                {bannerCtaText}
              </span>
            </div>
          </div>
          <div className="banner-section__bottom-edge" />
        </div>
        {isModalOpen && (
          <div className="video-modal" onClick={() => setIsModalOpen(false)}>
            <div className="box-wrapper">
              <div
                className="video-container"
                dangerouslySetInnerHTML={{
                  __html: bannerVideo,
                }}
              />
              <IoIosClose className="button" />
            </div>
          </div>
        )}
        <div className="vote-section">
          <div className="vote-section__container">
            <div className="vote-section__container__title-area">
              <h1 className="responsive-title">{bannerTitle}</h1>
              <span className="responsive-title--description">
                {bannerSubtitle}
              </span>
              <span
                className="responsive-cta"
                onClick={() => {
                  if (bannerLink.text) {
                    window.open(bannerLink.text, "_blank");
                  } else setIsModalOpen(true);
                }}
              >
                {bannerCtaText}
              </span>
              <h1 className="main-title">{primary.main_title.text}</h1>
              <h1 className="title">{primary.title_1.text}</h1>
            </div>
            <div className="vote-section__container__text-area">
              <div
                className="content1"
                dangerouslySetInnerHTML={{ __html: primary.content_1.html }}
              />
              <div className="content1">
                <h2 className="subtitle">{primary.title_2.text}</h2>
                <div
                  className="content1"
                  dangerouslySetInnerHTML={{ __html: primary.content_2.html }}
                />
              </div>

              <div className="video-wrapper">
                <h4 className="video-heading">{primary.video_title.text}</h4>

                {primary.video.url && (
                  <div className="video">
                    <iframe
                      src={primary.video.url}
                      title="vid"
                      frameBorder="0"
                      allow="autoplay; fullscreen"
                      allowFullScreen
                    ></iframe>
                  </div>
                )}
              </div>
            </div>

            {/* {isVoteOpen ? (
              <>
                <div id="cognito-form" className="cognito"></div>
              </>
            ) : (
              <div className="vote-section__container__cta-area">
                <span onClick={() => setIsVoteOpen(true)}>{voteCtaText}</span>
              </div>
            )} */}
          </div>
        </div>
        <div className="video-voting-section">
          <img className="ripped-border-img" src={historyTopEdge} alt="test" />
          <div className="inner-wrapper">
            <div className="vote-section__container__vote-area">
              <h1 className="title">{primary.video_voting_title.text}</h1>
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: primary.video_voting_sub_title.html,
                }}
              />
            </div>
            <div className="vote-section__container__video-area">
              {videoList.map((video, index) => {
                const videoHtml = video.video || "";
                const videoTitle = video.video_title
                  ? video.video_title.text
                  : "";
                const playwright = video.playwright
                  ? video.playwright.text
                  : "";
                return (
                  <div key={index} className={"video-box"}>
                    <div
                      className="video-container"
                      dangerouslySetInnerHTML={{
                        __html: videoHtml,
                      }}
                    />
                    <p className={"title"}>{videoTitle}</p>
                    <p className={"playwright"}>{playwright}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="submit-section">
          <div className="submit-section__container">
            <div className="submit-section__container__first-line">
              For more details please enter your email address here
            </div>
            <form
              className="submit-section__container__second-line"
              onSubmit={handleSubmit}
            >
              <p className="names-row">
                <input
                  type="text"
                  placeholder="First Name*"
                  value={fName}
                  onChange={firstNameInput}
                />
                <input
                  type="text"
                  placeholder="Last Name*"
                  value={lName}
                  onChange={lastNameInput}
                />
              </p>

              <p>
                <input
                  placeholder="Email Address*"
                  className="submit-section__container__second-line__input"
                  onChange={emailInput}
                  value={email}
                />
                <input
                  type="submit"
                  value="SUBMIT"
                  className="submit-section__container__second-line__submit"
                />
              </p>
              <span>{successFeedback ? `Thank you for subscribing!` : ``}</span>
              <span>{errorFeedback ? errorMsg : ``}</span>
            </form>
          </div>
        </div>
        <div className="slides-section">
          <div className="slides-section__title-box">
            <h1>
              The <span>annual</span> Lysicrates Prize
            </h1>
            <div className="slide-p-container">
              <p>{carouselDescription}</p>
            </div>
            <h3>
              The{" "}
              <span>
                {carousels[carouselIndex].page.document.data.title.text}
              </span>{" "}
              Event
            </h3>
          </div>
          <div className="slides-section__slide-container">
            <MdKeyboardArrowLeft
              className="prev-btn"
              onClick={() => prevSlick()}
              // onClick={(() => refs.slider.slickPrev())()}
            />
            <MdKeyboardArrowRight
              className="next-btn"
              onClick={() => nextSlick()}
            />
            <div className="slides-section__slide-box">
              <Slider
                ref={(c) => {
                  prevSlick = c && c.slickPrev;
                  nextSlick = c && c.slickNext;
                }}
                {...settings}
              >
                {carousels.map((carousel, index) => {
                  return (
                    <div key={index} className="slides-section__slide-box__box">
                      <div className="card">
                        {carousel.page.document.data.featured_image
                          .gatsbyImageData ? (
                          <GatsbyImage
                            className="card__image"
                            image={
                              carousel.page.document.data.featured_image
                                .gatsbyImageData
                            }
                            alt={
                              carousel.page.document.data.featured_image.alt ||
                              ""
                            }
                          />
                        ) : (
                          <img src="/x" alt="carousel img not found" />
                        )}
                        <Link
                          to={`/the-${carousel.page.document.data.title.text}-lysicrates-event`}
                          activeClassName="active"
                        >
                          <div className="card__title">
                            <div className="card__title__desktop">
                              <h3>
                                The{" "}
                                <strong className="year">
                                  {carousel.page.document.data.title.text}
                                </strong>{" "}
                                Event
                              </h3>
                              <span className="discover">DISCOVER</span>
                            </div>

                            <div className="card__title__tablet">
                              <h3>
                                Discover The{" "}
                                <strong className="year">
                                  {carousel.page.document.data.title.text}
                                </strong>{" "}
                                Event
                              </h3>
                            </div>

                            <div className="card__title__mobile">
                              <h3>DISCOVER</h3>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
        <div className="history-section">
          <div className="history-section__top-edge" />
          <div className="history-section__container">
            <div className="history-section__container__box">
              <h1>{historyTitle}</h1>
              <p>{historyDescription}</p>
              {(() => {
                const linkSlices = historyLink.split("/");
                const sliceIndex = linkSlices.length - 2;
                if (linkSlices[sliceIndex] === "history") {
                  return (
                    <Link to="/history/" activeClassName="active">
                      <span>LEARN MORE</span>
                    </Link>
                  );
                } else {
                  return (
                    <a href={historyLink} target="blank">
                      <span>LEARN MORE</span>
                    </a>
                  );
                }
              })()}
            </div>
          </div>
        </div>
        <div className="prize-section">
          <div className="prize-section__img-desktop">
            <GatsbyImage
              image={prizeBackgroundDesktop}
              alt={prizeBackgroundDesktopAlt}
            />
          </div>
          <div className="prize-section__img-mobile">
            <GatsbyImage
              image={prizeBackgroundMobile}
              alt={prizeBackgroundMobileAlt || ""}
            />
          </div>

          <div className="prize-section__container">
            <div className="prize-section__container__box">
              <h1>{prizeTitle}</h1>
              <div className="prize-p-container">
                <p>{prizeDescription}</p>
              </div>
              {(() => {
                const linkSlices = prizeLink.split("/");
                const sliceIndex = linkSlices.length - 2;
                if (linkSlices[sliceIndex] === "martinlysicrates.com.au") {
                  return (
                    <a href={prizeLink} target="blank">
                      <span>Visit Site</span>
                    </a>
                  );
                } else {
                  return (
                    <Link to="/prize/" activeClassName="active">
                      <span>Visit Site</span>
                    </Link>
                  );
                }
              })()}
            </div>
          </div>
        </div>
        <div className="sponsors-section">
          <div className="sponsors-section__container">
            <h3>Our Proud Sponsors</h3>
            <div className="sponsors-section__container__icon-box">
              {sponsors.map((sponsor, index) => {
                return (
                  <div key={index} className="logo-container">
                    <a href={sponsor.website_url} target="blank">
                      {sponsor.logo.gatsbyImageData ? (
                        <GatsbyImage
                          image={sponsor.logo.gatsbyImageData}
                          alt={sponsor.logo.alt}
                        />
                      ) : (
                        <img src="/x" alt="sponsor img not found" />
                      )}
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;

export const dataQuery = graphql`
  {
    seo: prismicHomePage(id: { eq: "627d29ab-72bc-5e22-bd88-26d917919b23" }) {
      data {
        title_tag
        meta_description
      }
    }
    banner: prismicHomePage(
      id: { eq: "627d29ab-72bc-5e22-bd88-26d917919b23" }
    ) {
      data {
        banner {
          title
          subtitle
          cta_text1
          banner_video
          banner_link {
            text
          }
          image {
            url
            alt
          }
        }
      }
    }
    vote: prismicHomePage(id: { eq: "627d29ab-72bc-5e22-bd88-26d917919b23" }) {
      data {
        body {
          __typename
          ... on PrismicHomePageDataBodyVoteVideo {
            id
            primary {
              main_title {
                text
              }
              title_1 {
                text
              }
              content_1 {
                html
              }
              title_2 {
                text
              }
              content_2 {
                html
              }
              video_title {
                text
              }
              video {
                url
              }
              video_voting_title {
                text
              }
              video_voting_sub_title {
                html
              }
            }
            items {
              video
              video_title {
                text
              }
              playwright {
                text
              }
            }
          }
        }
      }
    }
    carousels: prismicHomePage(
      id: { eq: "627d29ab-72bc-5e22-bd88-26d917919b23" }
    ) {
      data {
        heading_description
        list {
          page {
            document {
              __typename
              ... on PrismicEvent {
                data {
                  title {
                    text
                  }
                  featured_image {
                    alt
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
        }
      }
    }
    history: prismicHomePage(
      id: { eq: "627d29ab-72bc-5e22-bd88-26d917919b23" }
    ) {
      data {
        title1
        description
        visit_url
        background_image__mobile_ {
          url
        }
        background_image__tablet_ {
          url
        }
        background_image__desktop_ {
          url
        }
      }
    }
    prize: prismicHomePage(id: { eq: "627d29ab-72bc-5e22-bd88-26d917919b23" }) {
      data {
        title2
        description1
        visit_url1
        background_image__mobile_1 {
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
        background_image__tablet_1 {
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
        background_image__desktop_1 {
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    sponsors: prismicHomePage(
      id: { eq: "627d29ab-72bc-5e22-bd88-26d917919b23" }
    ) {
      data {
        sponsor {
          website_url
          logo {
            alt
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
