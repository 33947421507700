import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import SEO from "../components/seo";
import styled from "styled-components";
import {
  headingBold,
  headingNormal,
  mainBlack,
  mainWhite,
  mainGrey,
  transHover,
  pText,
  mainRed,
  mainGreen,
  titleBlack,
  screen,
  innerWidth,
  heading,
} from "../components/common/variables";
import { GatsbyImage } from "gatsby-plugin-image";

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  padding-bottom: 90px;

  .title-section {
    width: 100%;
    padding-top: 80px;

    &__area {
      max-width: ${innerWidth};
      margin: 0 auto;

      &__box {
        margin-left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media ${screen.small} {
          display: block;
          margin-left: 40px;
        }

        .first-line {
          display: block;
          font-family: ${headingBold};
          font-size: 2.1875rem;
          color: ${mainRed};
          margin-left: 0px;
          margin-bottom: -10px;
          text-align: center;
          @media ${screen.small} {
            margin-left: 35px;
            margin-bottom: -10px;
            text-align: left;
          }
        }

        .second-line {
          display: block;
          font-family: ${headingNormal};
          font-size: 3rem;
          color: ${titleBlack};
          text-align: center;
          @media ${screen.small} {
            font-size: 3.75rem;
            text-align: left;
          }
          @media ${screen.medium} {
            font-size: 4.375rem;
          }
        }

        .third-line {
          display: block;
          font-family: ${headingBold};
          font-size: 2.1875rem;
          color: ${mainRed};
          margin-left: 0px;
          margin-top: -10px;
          text-align: center;
          @media ${screen.small} {
            margin-left: 130px;
            margin-top: -10px;
            text-align: left;
          }
        }
      }
    }
  }

  .main-content {
    padding: 0 30px 60px 30px;
    @media ${screen.small} {
      padding: 0 45px 60px 45px;
    }
    @media ${screen.medium} {
      padding: 0 60px;
    }
    @media ${screen.large} {
      padding: 0 20px;
    }
    @media ${screen.xLarge} {
      padding: 0;
    }
  }

  .subheading {
    color: ${mainBlack};
    max-width: ${innerWidth};
    margin: 40px auto 0 auto;
    font-family: ${pText};
    font-size: 1.1rem;
    line-height: 1.5;
  }

  .accolade_list {
    max-width: ${innerWidth};
    margin: 60px auto 0 auto;
    @media ${screen.small} {
      margin: 90px auto 0 auto;
    }
    &__item {
      display: flex;
      @media ${screen.small} {
        margin-bottom: 80px;
      }

      .person-img {
        max-width: 110px;
        min-width: 110px;
        width: 100%;
        @media ${screen.small} {
          max-width: 190px;
          min-width: 190px;
        }
      }

      .text-container {
        margin-left: 20px;
        @media ${screen.small} {
          margin-left: 45px;
        }
        &__message {
          color: ${mainBlack};
          font-family: ${pText};

          p {
            font-size: 1.1rem;
            line-height: 1.5;
          }
          a {
            color: ${mainBlack};
            text-decoration: underline;
          }

          &--desktop {
            display: none;
            @media ${screen.small} {
              display: block;
            }
          }
        }

        &__person-name {
          color: ${mainGreen};
          font-family: ${heading};
          font-size: 1.45rem;
          @media ${screen.small} {
            margin-top: 10px;
            font-size: 2rem;
          }
        }

        &__person-position {
          color: ${mainGreen};
          font-family: ${pText};
          font-size: 1rem;
          margin-top: 8px;
          max-width: 250px;
          @media ${screen.small} {
            font-size: 1.1rem;
            max-width: inherit;
          }
        }
      }
    }

    .message-mobile {
      color: ${mainBlack};
      font-family: ${pText};
      display: block;
      margin-top: 15px;
      margin-bottom: 50px;
      @media ${screen.small} {
        display: none;
      }

      p {
        font-size: 1rem;
        line-height: 1.5;
      }
      a {
        color: ${mainBlack};
        text-decoration: underline;
      }
    }
  }

  .load-more-btn {
    display: block;
    max-width: ${innerWidth};
    margin: 90px auto 0 auto;

    button {
      cursor: pointer;
      color: ${mainWhite};
      border: 0;
      border-radius: 0;
      padding: 10px 40px;
      font-family: ${pText};
      font-size: 0.875rem;
      background-color: ${mainRed};
      transition: ${transHover};
      outline: none;
      @media ${screen.withCursor} {
        &:hover {
          background-color: ${mainGrey};
        }
      }
    }
  }
`;

const AboutPage = ({ data }) => {
  const titleTag = data.content.data.title_tag
    ? data.content.data.title_tag
    : "Lysicrates";
  const metaDescription = data.content.data.meta_description
    ? data.content.data.meta_description
    : "Lysicrates";
  const subheading = data.content.data.subheading;

  const [listLength, setListLength] = useState(6);
  const accoladeList = data.content.data.accolade_list.slice(0, listLength);

  const loadMoreItem = () => {
    setListLength(listLength + 6);
  };

  const currentLength = accoladeList.length;
  const totalLength = data.content.data.accolade_list.length;

  return (
    <Layout>
      <SEO title={titleTag} description={metaDescription} />
      <Wrapper>
        <div className="title-section">
          <div className="title-section__area">
            <div className="title-section__area__box">
              <h1>
                <span className="first-line">Read the</span>
                <span className="second-line">Accolades</span>
                <span className="third-line">from our supporters</span>
              </h1>
            </div>
          </div>
        </div>

        <div className="main-content">
          <h2 className="subheading">{subheading}</h2>
          <ul className="accolade_list">
            {accoladeList.map((accolade, i) => (
              <li key={i}>
                <div className="accolade_list__item">
                  <div className="person-img">
                    <GatsbyImage
                      image={accolade.image.gatsbyImageData}
                      alt={accolade.image.alt}
                    />
                  </div>
                  <div className="text-container">
                    <div
                      className="text-container__message text-container__message--desktop"
                      dangerouslySetInnerHTML={{
                        __html: accolade.message.html,
                      }}
                    />
                    <h3 className="text-container__person-name">
                      {accolade.full_name},
                    </h3>
                    <h4 className="text-container__person-position">
                      {accolade.position}
                    </h4>
                  </div>
                </div>
                <div
                  className="message-mobile"
                  dangerouslySetInnerHTML={{ __html: accolade.message.html }}
                />
              </li>
            ))}
          </ul>
          <div className="load-more-btn">
            {currentLength !== totalLength && (
              <button onClick={loadMoreItem}>LOAD MORE</button>
            )}
          </div>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default AboutPage;

export const dataQuery = graphql`
  {
    content: prismicAccoladesPage {
      data {
        subheading
        title_tag
        meta_description
        accolade_list {
          full_name
          position
          message {
            html
          }
          image {
            alt
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
