exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-accolades-js": () => import("./../../../src/pages/accolades.js" /* webpackChunkName: "component---src-pages-accolades-js" */),
  "component---src-pages-board-js": () => import("./../../../src/pages/board.js" /* webpackChunkName: "component---src-pages-board-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-james-martin-js": () => import("./../../../src/pages/james-martin.js" /* webpackChunkName: "component---src-pages-james-martin-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-orations-js": () => import("./../../../src/pages/orations.js" /* webpackChunkName: "component---src-pages-orations-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-templates-single-blog-js": () => import("./../../../src/templates/single-blog.js" /* webpackChunkName: "component---src-templates-single-blog-js" */),
  "component---src-templates-single-event-js": () => import("./../../../src/templates/single-event.js" /* webpackChunkName: "component---src-templates-single-event-js" */),
  "component---src-templates-single-playwright-js": () => import("./../../../src/templates/single-playwright.js" /* webpackChunkName: "component---src-templates-single-playwright-js" */)
}

