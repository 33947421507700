import React from "react";
import Layout from "../layout";
import SEO from "../components/seo";
import styled, { keyframes } from "styled-components";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import {
  headingBold,
  headingNormal,
  mainRed,
  mainBlack,
  mainGrey,
  pText,
  pTextBold,
  transHover,
  screen,
} from "../components/common/variables";
import mouth from "../images/404/404-page-lyscicrates-prize-mouth-v2.png";

const moveMouth = keyframes`
0% {
  transform: translateY(0);
}
73% {
  transform: translateY(-3px);
}
76% {
  transform: translateY(3px);
}
79% {
  transform: translateY(-3px);
}
82% {
  transform: translateY(3px);
}
85% {
  transform: translateY(-3px);;
}
88% {
  transform: translateY(3px);
} 91% {
  transform: translateY(-3px);
}
93% {
  transform: translateY(3px);
}
98% {
  transform: translateY(3px);
}
100% {
  transform: translateY(0);
}
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: flex-end;
  padding-bottom: 90px;
  flex-direction: column;
  padding: 90px 20px 320px 20px;
  @media ${screen.small} {
    padding: 90px 45px 200px;
    flex-direction: row;
  }
  @media ${screen.medium} {
    padding: 90px 60px 200px;
  }
  @media ${screen.large} {
    padding: 90px 20px 200px;
  }
  @media ${screen.xLarge} {
    padding: 90px 0 200px;
  }

  .img-container {
    position: absolute;
    left: 0;
    bottom: 40px;
    width: 300px;

    @media ${screen.small} {
      width: 400px;
      bottom: 50px;
    }
    @media ${screen.medium} {
      width: 600px;
      bottom: 0px;
    }

    &__mouth {
      position: absolute;
      width: 35px;
      top: 136px;
      right: 130px;
      animation: ${moveMouth} 5s ease-in 0s infinite both;
      @media ${screen.small} {
        width: 45px;
        top: 180px;
        right: 175px;
      }
      @media ${screen.medium} {
        width: 70px;
        top: 268px;
        right: 261px;
      }
    }
  }

  .texts-container {
    max-width: 490px;
    align-self: center;
    margin-right: 0px;
    @media ${screen.medium} {
      margin-right: 20px;
    }
    &__title {
      color: ${mainRed};
      font-family: ${headingBold};
      font-size: 2.4rem;
      @media ${screen.medium} {
        font-size: 3rem;
      }

      &--subtitle {
        display: block;
        color: ${mainBlack};
        font-family: ${headingNormal};
        font-weight: 900;
        font-size: 2rem;
        margin-top: 15px;
        @media ${screen.medium} {
          font-size: 2.5rem;
          margin-left: 72px;
          margin-top: 15px;
        }
      }
    }

    p {
      color: ${mainBlack};
      font-family: ${pText};
      font-size: 1.35rem;
      margin-top: 45px;
      @media ${screen.small} {
        font-size: 1.35rem;
        margin-top: 70px;
      }
      @media ${screen.medium} {
        font-size: 1.5rem;
        margin-top: 90px;
      }
    }

    a {
      color: ${mainRed};
      display: block;
      font-family: ${pTextBold};
      font-size: 1.3rem;
      margin-top: 16px;
      transition: ${transHover};
      @media ${screen.small} {
        font-size: 1.5rem;
        margin-top: 20px;
      }
      &:hover {
        color: ${mainGrey};
      }
    }
  }
`;

const NotFoundPage = ({ data }) => {
  // content from prismic
  const trivias = data.content.data.trivia_list;
  const titleTag = data.content.data.title_tag
    ? data.content.data.title_tag
    : `Lysicrates`;
  const metaDescription = data.content.data.meta_description
    ? data.content.data.meta_description
    : `Lysicrates`;
  const randomNumber = Math.floor(Math.random() * trivias.length);

  // single random trivia
  const randomTrivia = trivias[randomNumber].trivia;

  return (
    <Layout>
      <SEO title={titleTag} description={metaDescription} />
      <Wrapper>
        <div className="img-container">
          <StaticImage
            src="../images/404/404-page-lysicrates-prize-v2.png"
            alt="James Martin"
          />
          <img className="img-container__mouth" src={mouth} alt="mouth" />
        </div>
        <div className="texts-container">
          <h1 className="texts-container__title">
            Crikey! Page not found!
            <span className="texts-container__title--subtitle">
              ...but did you know?
            </span>
          </h1>
          <p>{randomTrivia}</p>
          <Link to="/james-martin/">Find out more</Link>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default NotFoundPage;

export const dataQuery = graphql`
  {
    content: prismic404Page {
      data {
        trivia_list {
          trivia
        }
        title_tag
        meta_description
      }
    }
  }
`;
