import React from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import SEO from "../components/seo";
import styled from "styled-components";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import "animate.css/animate.min.css";
import ScrollAnimation from "react-animate-on-scroll";
import {
  mainRed,
  mainBlack,
  mainWhite,
  fadeGreen,
  pTextLighter,
  pText,
  pTextBold,
  headingBold,
  headingNormal,
  screen,
  innerWidth,
} from "../components/common/variables";
import tornPaperBorder from "../images/history/torn-paper-bottom.png";
import tornPaperRed from "../images/history/torn-paper-red.png";
import greeBackground from "../images/history/lysicrates-watercolor-background.jpg";
import cityBg from "../images/history/history-page-city-background.jpg";
import maskBg1 from "../images/history/lysicrates-mask-background.jpg";
import gradientBg from "../images/history/lysicrates-statue-background.jpg";
import tornPaperBg from "../images/history/history-page-below-harbour-bridge-torn-paper.png";

const Wrapper = styled.div`
  p {
    line-height: 1.7;
  }

  .intro-section {
    padding-top: 80px;
    background-image: url(${cityBg});
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    @media ${screen.small} {
      background-position: center -270px;
    }
    @media ${screen.large} {
      background-position: center -500px;
    }

    &__title {
      color: ${mainRed};
      font-family: ${headingBold};
      font-size: 2.1rem;
      margin: 0 auto;
      padding: 0 30px;
      max-width: ${innerWidth};
      text-align: center;
      @media ${screen.small} {
        font-size: 3.32rem;
        padding: 0;
      }
      @media ${screen.medium} {
        text-align: left;
        margin: 0 0 0 120px;
      }
      @media ${screen.large} {
        margin: 0 auto;
      }

      &--top {
        display: block;
      }

      &--middle {
        color: ${mainBlack};
        font-family: ${headingNormal};
        display: block;
        font-size: 3rem;
        @media ${screen.small} {
          font-size: 5.9rem;
        }
        @media ${screen.medium} {
          margin-left: 68px;
        }
      }

      &--bottom {
        display: block;
      }
    }

    .content {
      background-color: ${mainRed};
      margin-top: 130px;
      padding-bottom: 50px;
      position: relative;
      @media ${screen.small} {
        padding-bottom: 100px;
        margin-top: 170px;
      }
      @media ${screen.medium} {
        padding-bottom: 150px;
      }
      @media ${screen.large} {
        padding-bottom: 170px;
      }

      &::before {
        content: " ";
        background-image: url(${tornPaperRed});
        background-position: center bottom;
        height: 80px;
        width: 100%;
        background-size: 100% 100%;
        margin-top: -80px;
        position: absolute;
        left: 0;
        @media ${screen.small} {
          height: 120px;
          margin-top: -120px;
        }
      }

      &__description {
        max-width: ${innerWidth};
        padding-left: 50px;
        margin: 0 auto;
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        padding: 0 30px;
        @media ${screen.small} {
          padding: 0 45px;
        }
        @media ${screen.medium} {
          padding: 0 60px;
        }
        @media ${screen.large} {
          padding: 0 20px;
        }
        @media ${screen.xLarge} {
          padding: 0;
        }

        p,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          max-width: 528px;
          color: ${mainWhite};
          margin-bottom: 16px;
          @media ${screen.small} {
            margin-bottom: 20px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-size: 1.4rem;
          font-family: ${pTextLighter};
          @media ${screen.small} {
            font-size: 1.5rem;
          }
        }

        p {
          font-size: 1.05rem;
          font-family: ${pTextLighter};
          @media ${screen.small} {
            font-size: 1.125rem;
          }
        }
      }

      &__statue {
        display: none;
        @media ${screen.large} {
          display: block;
          position: absolute;
          width: 100%;
          bottom: 0;
          right: 0;
          max-width: calc(330px + 25vw);
        }
      }
    }
  }

  .playwright-section {
    padding-top: 45px;
    padding-bottom: 45px;
    @media ${screen.small} {
      padding-top: 90px;
    }

    &__description {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 0 30px;

      @media ${screen.small} {
        padding: 0 45px;
      }
      @media ${screen.medium} {
        padding: 0 60px;
      }
      @media ${screen.large} {
        padding: 0 20px;
      }
      @media ${screen.xLarge} {
        padding: 0;
      }

      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        max-width: 528px;
        color: ${mainBlack};
        margin-bottom: 16px;

        @media ${screen.small} {
          margin-bottom: 20px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 1.4rem;
        font-family: ${pTextLighter};
        @media ${screen.small} {
          font-size: 1.5rem;
        }
      }

      p {
        color: ${mainBlack};
        font-size: 1.05rem;

        font-family: ${pTextLighter};
        @media ${screen.small} {
          font-size: 1.125rem;
        }
      }
    }

    &__quote-row {
      max-width: ${innerWidth};
      margin: 45px auto 0 auto;
      display: flex;
      flex-direction: column;
      padding: 0 30px;
      @media ${screen.small} {
        flex-direction: row;
        padding: 0 45px;
      }
      @media ${screen.medium} {
        padding: 0 60px;
      }
      @media ${screen.large} {
        padding: 0 20px;
      }
      @media ${screen.xLarge} {
        padding: 0;
      }

      .trophy {
        max-width: 550px;
        width: 100%;
      }

      .blockquote {
        max-width: 532px;
        align-self: center;

        p {
          color: ${mainRed};
          font-family: ${headingBold};
          font-size: 1.38rem;
          line-height: 1.4;
          @media ${screen.small} {
            font-size: 2.3rem;
          }
        }
      }
    }
  }

  .athens-section {
    padding-top: 10px;
    position: relative;
    @media ${screen.small} {
      padding-top: 60px;
    }

    &__first-row,
    &__second-row,
    &__third-row {
      .description {
        max-width: ${innerWidth};
        margin: 0 auto;
        position: relative;
        z-index: 2;

        p,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          max-width: 528px;
          color: ${mainBlack};
          margin-bottom: 16px;
          @media ${screen.small} {
            margin-bottom: 20px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-size: 1.4rem;
          font-family: ${pTextLighter};
          @media ${screen.small} {
            font-size: 1.5rem;
          }
        }

        p {
          font-size: 1.05rem;
          font-family: ${pTextLighter};
          @media ${screen.small} {
            font-size: 1.125rem;
          }
        }
      }
    }

    &__first-row {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 0 30px;
      @media ${screen.small} {
        padding: 0 45px;
      }
      @media ${screen.medium} {
        padding: 0 60px;
      }
      @media ${screen.large} {
        padding: 0 20px;
      }
      @media ${screen.xLarge} {
        padding: 0;
      }
    }

    &__second-row {
      display: flex;
      flex-direction: column;
      max-width: ${innerWidth};
      margin: 30px auto;
      padding: 0 30px;
      @media ${screen.small} {
        padding: 0 45px;
        flex-direction: row;
        margin: 60px auto;
      }
      @media ${screen.medium} {
        padding: 0 60px;
      }
      @media ${screen.large} {
        padding: 0 20px;
      }
      @media ${screen.xLarge} {
        padding: 0;
      }

      .lord-byron {
        width: 100%;
        max-width: 400px;
        margin-bottom: 20px;
        @media ${screen.small} {
          margin-bottom: 0;
        }
        @media ${screen.large} {
          max-width: 510px;
        }

        .caption {
          color: ${mainBlack};
          font-family: ${pText};
          font-style: italic;
          font-weight: 900;
          display: block;
          line-height: 1.5;
          font-size: 0.58rem;
          margin-top: 6px;
          @media ${screen.small} {
            font-size: 0.75rem;
            margin-top: 10px;
          }
        }
      }

      .description {
        align-self: center;
        @media ${screen.small} {
          padding-left: 30px;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: ${mainRed};
        }
      }
    }

    &__third-row {
      display: flex;
      flex-direction: column;
      max-width: ${innerWidth};
      margin: 0 auto;
      justify-content: space-between;
      padding: 0 30px;
      @media ${screen.small} {
        flex-direction: row;
        padding: 0 45px;
      }
      @media ${screen.medium} {
        padding: 0 60px;
      }
      @media ${screen.large} {
        padding: 0 20px;
      }
      @media ${screen.xLarge} {
        padding: 0;
      }

      .statue {
        width: 100%;
        max-width: 400px;
        margin-top: 20px;
        @media ${screen.small} {
          margin-top: 0;
        }
        @media ${screen.large} {
          max-width: 510px;
        }

        .caption {
          color: ${mainBlack};
          font-family: ${pText};
          font-style: italic;
          font-weight: 900;
          display: block;
          margin-left: 34px;
          line-height: 1.5;
          font-size: 0.58rem;
          margin-top: 6px;
          @media ${screen.small} {
            margin-left: 55px;
            font-size: 0.75rem;
            margin-top: 10px;
          }
        }
      }

      .description {
        align-self: center;
        max-width: 528px;
        margin: 0;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: ${mainRed};
        }
      }
    }

    .statue-sketch {
      display: none;
      @media ${screen.medium} {
        display: block;
        position: absolute;
        width: 100%;
        top: -30px;
        max-width: 500px;
        right: 0;
      }
      @media ${screen.large} {
        max-width: calc(460px + 10vw);
        top: -130px;
      }
    }
  }

  .james-martin-section {
    position: relative;
    margin-top: 30px;
    @media ${screen.small} {
      margin-top: 90px;
    }

    &__first-row,
    &__second-row,
    &__third-row,
    &__fourth-row,
    &__fifth-row {
      .description {
        p,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: ${mainBlack};
          margin-bottom: 16px;
          @media ${screen.small} {
            margin-bottom: 20px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-size: 1.4rem;
          font-family: ${pTextLighter};
          @media ${screen.small} {
            font-size: 1.5rem;
          }
        }

        p {
          font-size: 1.05rem;
          font-family: ${pTextLighter};
          @media ${screen.small} {
            font-size: 1.125rem;
          }
        }

        h3 {
          color: ${mainRed};
          font-family: ${pTextBold};
          font-size: 2rem;
          @media ${screen.small} {
            font-size: 2.25rem;
          }
        }
      }
    }

    &__first-row {
      display: flex;
      flex-direction: column;
      max-width: ${innerWidth};
      margin: 0 auto 60px auto;
      justify-content: space-between;
      padding: 0 30px;
      @media ${screen.small} {
        padding: 0 45px;
        flex-direction: row;
      }
      @media ${screen.medium} {
        padding: 0 60px;
      }
      @media ${screen.large} {
        padding: 0 20px;
      }
      @media ${screen.xLarge} {
        padding: 0;
      }

      .portrait {
        width: 100%;
        max-width: 400px;
        margin-bottom: 15px;
        @media ${screen.small} {
          max-width: 300px;
          padding-right: 30px;
          margin-bottom: 0;
        }
        @media ${screen.medium} {
          max-width: 370px;
          padding-right: 0;
        }
        @media ${screen.large} {
          max-width: 480px;
        }

        .caption {
          color: ${mainBlack};
          font-family: ${pText};
          font-style: italic;
          font-weight: 900;
          display: block;
          margin-left: 30px;
          line-height: 1.5;
          font-size: 0.58rem;
          margin-top: 6px;
          @media ${screen.small} {
            margin-left: 45px;
            font-size: 0.75rem;
            margin-top: 10px;
          }
        }
      }

      .description {
        align-self: center;
        max-width: 550px;
        margin-top: 20px;
        @media ${screen.small} {
          margin-top: 0;
        }
        strong {
          font-family: ${pTextBold};
        }
      }
    }

    &__second-row {
      display: flex;
      flex-direction: column;
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 0 30px;
      @media ${screen.small} {
        flex-direction: row;
        padding: 30px 45px;
      }
      @media ${screen.medium} {
        padding: 30px 60px;
      }
      @media ${screen.large} {
        padding: 0 20px;
      }
      @media ${screen.xLarge} {
        padding: 0;
      }

      .trophy {
        width: 100%;
        max-width: 410px;
        margin-top: 20px;
        @media ${screen.small} {
          padding-left: 30px;
        }
        @media ${screen.medium} {
          padding-left: 60px;
        }
        @media ${screen.large} {
          max-width: 520px;
        }

        .caption {
          color: ${mainBlack};
          font-family: ${pText};
          font-style: italic;
          font-weight: 900;
          font-size: 0.58rem;
          display: block;
          margin-top: 6px;
          margin-left: 22px;
          line-height: 1.5;
          @media ${screen.small} {
            margin-left: 38px;
            font-size: 0.75rem;
            margin-top: 10px;
          }
        }
      }

      .description {
        max-width: 528px;
      }
    }

    &__third-row {
      display: flex;
      flex-direction: column;
      max-width: ${innerWidth};
      margin: 45px auto 60px auto;
      justify-content: space-between;
      padding: 0 30px;
      @media ${screen.small} {
        flex-direction: row;
        padding: 0 45px;
        margin: 10px auto 60px auto;
      }
      @media ${screen.medium} {
        padding: 0 60px;
      }
      @media ${screen.large} {
        padding: 0 20px;
        margin: -75px auto 60px auto;
      }
      @media ${screen.xLarge} {
        padding: 0;
      }

      .monument {
        width: 100%;
        align-self: flex-start;
        max-width: 400px;
        margin-top: 20px;
        @media ${screen.small} {
          max-width: 300px;
          padding-left: 30px;
        }
        @media ${screen.medium} {
          align-self: flex-end;
          padding-left: 0;
          max-width: 370px;
          margin-top: 0;
        }
        @media ${screen.large} {
          max-width: 480px;
        }
        img {
          width: 100%;
        }

        .caption {
          color: ${mainBlack};
          font-family: ${pText};
          font-style: italic;
          font-weight: 900;
          font-size: 0.58rem;
          display: block;
          margin-top: 6px;
          margin-left: 5px;
          line-height: 1.5;
          @media ${screen.small} {
            margin-top: 10px;
            font-size: 0.75rem;
          }
        }
      }

      .description {
        max-width: 528px;

        h5 {
          color: ${mainRed};
          font-family: ${headingBold};
          margin-bottom: 20px;
          font-size: 1.38rem;
          @media ${screen.small} {
            font-size: 1.75rem;
            margin-bottom: 50px;
          }
        }
      }
    }

    &__fourth-row {
      display: flex;
      flex-direction: column;
      max-width: ${innerWidth};
      margin: 30px auto;
      justify-content: space-between;
      padding: 0 30px;
      @media ${screen.small} {
        padding: 0 45px;
        margin: 90px auto;
      }
      @media ${screen.medium} {
        padding: 0 60px;
        flex-direction: row;
      }
      @media ${screen.large} {
        padding: 0 20px;
      }
      @media ${screen.xLarge} {
        padding: 0;
      }

      .person-list {
        width: 100%;
        display: flex;
        justify-content: space-between;
        max-width: 510px;
        margin-bottom: 20px;
        @media ${screen.medium} {
          max-width: 550px;
          margin-bottom: 0;
        }

        .person-image {
          width: 100%;
          max-width: 96px;
          @media ${screen.small} {
            max-width: 134px;
          }
          @media ${screen.medium} {
            max-width: 172px;
          }

          .caption {
            color: ${mainBlack};
            font-family: ${pText};
            font-style: italic;
            font-weight: 900;
            font-size: 0.58rem;
            display: block;
            margin-top: 6px;
            line-height: 1.5;
            @media ${screen.small} {
              margin-top: 15px;
              font-size: 0.75rem;
            }
          }
        }
      }

      .description {
        max-width: 528px;
        margin-top: 20px;
        @media ${screen.small} {
          margin-top: 0;
        }
        @media ${screen.medium} {
          padding-left: 30px;
        }
      }
    }

    &__fifth-row {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 0 0 60px 0;
      padding: 0 30px;
      @media ${screen.small} {
        padding: 0 45px;
      }
      @media ${screen.medium} {
        padding: 0 60px;
      }
      @media ${screen.large} {
        padding: 0 20px;
      }
      @media ${screen.xLarge} {
        padding: 0;
      }
      .description {
        max-width: 528px;
        @media ${screen.medium} {
          max-width: 425px;
        }
      }
    }

    &__blockquote {
      font-family: ${pTextLighter};
      right: 0;
      max-width: 800px;
      padding: 45px 20px 60px 30px;
      margin: 10px 0 0 auto;
      @media ${screen.small} {
        padding: 90px 80px 110px 80px;
        margin: 45px 0 0 auto;
      }
      @media ${screen.medium} {
        margin: 45px 0 0 auto;
      }
      @media ${screen.large} {
        padding: 90px calc(10px + 3vw) 110px calc(10px + 3vw);
        position: absolute !important;
        margin: 0;
        bottom: 110px;
        max-width: 740px;
      }
      @media ${screen.xLarge} {
        max-width: 800px;
      }
      p {
        color: ${mainRed};
        line-height: 1.5;
        font-size: 1.36rem;
        @media ${screen.small} {
          font-size: 1.75rem;
        }
        strong {
          font-family: ${headingBold};
          font-size: 2rem;
          @media ${screen.small} {
            font-size: 2.43rem;
          }
        }
      }
    }

    .gradient-bg-wrapper {
      padding-bottom: 30px;
      @media ${screen.small} {
        padding-bottom: 60px;
      }
      @media ${screen.large} {
        padding-bottom: 190px;
      }
    }
  }

  .map-section {
    iframe {
      display: block;
      border: 0;
    }
  }

  .botanical-section {
    max-width: ${innerWidth};
    margin: 45px auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 30px;
    @media ${screen.small} {
      flex-direction: row;
      padding: 0 45px;
      margin: 90px auto;
    }
    @media ${screen.medium} {
      padding: 0 60px;
    }
    @media ${screen.large} {
      padding: 0 20px;
    }
    @media ${screen.xLarge} {
      padding: 0;
    }

    .description {
      max-width: 528px;

      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: ${mainBlack};
        margin-bottom: 16px;
        @media ${screen.small} {
          margin-bottom: 20px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 1.4rem;
        font-family: ${pTextLighter};
        @media ${screen.small} {
          font-size: 1.5rem;
        }
      }

      p {
        font-size: 1.05rem;
        font-family: ${pTextLighter};
        @media ${screen.small} {
          font-size: 1.125rem;
        }
        strong {
          font-family: ${pTextBold};
        }
      }
    }

    .monument {
      width: 100%;
      max-width: 370px;
      margin-top: 20px;
      @media ${screen.small} {
        margin-top: 0;
      }
      @media ${screen.medium} {
        max-width: 430px;
      }
      @media ${screen.large} {
        max-width: 500px;
      }

      .caption {
        color: ${mainBlack};
        font-family: ${pText};
        font-style: italic;
        font-weight: 900;
        font-size: 0.58rem;
        display: block;
        margin-top: 6px;
        margin-left: 46px;
        line-height: 1.5;
        @media ${screen.small} {
          margin-left: 86px;
          font-size: 0.75rem;
          margin-top: 10px;
        }
      }
    }
  }

  .about-lysicrates-section {
    &__motto {
      max-width: ${innerWidth};
      margin: 0 auto 20px auto;
      padding: 0 30px;
      @media ${screen.small} {
        padding: 0 45px;
        margin: 0 auto 45px auto;
      }
      @media ${screen.medium} {
        padding: 0 60px;
      }
      @media ${screen.large} {
        padding: 0 20px;
      }
      @media ${screen.xLarge} {
        padding: 0;
      }

      p {
        font-family: ${pTextLighter};
        font-size: 1.32rem;
        margin-bottom: 15px;
        @media ${screen.small} {
          font-size: 1.62rem;
        }
      }
      h6 {
        color: ${fadeGreen};
        font-family: ${headingNormal};
        font-size: 1.45rem;
        @media ${screen.small} {
          font-size: 3rem;
        }
        @media ${screen.medium} {
          font-size: 3.25rem;
        }
      }
    }

    &__green-bg {
      padding: 90px 0 70px 0;
      position: relative;
      background-image: url(${greeBackground});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      @media ${screen.small} {
        padding: 140px 0 120px 0;
      }
      @media ${screen.large} {
        background-attachment: fixed;
      }

      .row-content {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        max-width: ${innerWidth};
        margin: 0 auto;
        padding: 0 30px;
        @media ${screen.small} {
          flex-direction: row;
          padding: 0 45px;
        }
        @media ${screen.medium} {
          padding: 0 60px;
        }
        @media ${screen.large} {
          padding: 0 20px;
        }
        @media ${screen.xLarge} {
          padding: 0;
        }

        .group-photo {
          width: 100%;
          max-width: 320px;
          @media ${screen.medium} {
            max-width: 400px;
          }
        }

        .description {
          max-width: 550px;
          align-self: center;
          margin-top: 20px;
          @media ${screen.small} {
            padding-left: 30px;
            margin-top: 0;
          }
          @media ${screen.large} {
            padding-left: 0;
          }
          p,
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            color: ${mainWhite};
            margin-bottom: 16px;
            @media ${screen.small} {
              margin-bottom: 20px;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            font-size: 1.61rem;
            font-family: ${headingNormal};
            @media ${screen.small} {
              font-size: 2rem;
            }
          }

          p {
            font-size: 1.05rem;
            font-family: ${pTextLighter};
            @media ${screen.small} {
              font-size: 1.125rem;
            }
          }
        }
      }

      &::before {
        position: absolute;
        background: url(${tornPaperBorder}) no-repeat center bottom;
        transform: rotate(180deg);
        content: " ";
        top: 0;
        height: 60px;
        width: 100%;
        background-size: 100% 100%;
        margin-top: 0;
        z-index: 1;
        @media ${screen.small} {
          height: 100px;
        }
      }

      &::after {
        position: absolute;
        background: url(${tornPaperBorder}) no-repeat center bottom;
        content: " ";
        bottom: 0;
        height: 60px;
        width: 100%;
        background-size: 100% 100%;
        margin-top: 0;
        z-index: 1;
        @media ${screen.small} {
          height: 100px;
        }
      }
    }

    &__vision {
      display: flex;
      flex-direction: column;
      max-width: ${innerWidth};
      margin: 15px auto 0 auto;
      justify-content: space-between;
      padding: 0 30px;
      @media ${screen.small} {
        flex-direction: row;
        padding: 0 45px;
        margin: 45px auto 0 auto;
      }
      @media ${screen.medium} {
        padding: 0 60px;
      }
      @media ${screen.large} {
        padding: 0 20px;
      }
      @media ${screen.xLarge} {
        padding: 0;
      }

      .col-1 {
        max-width: 528px;
        .description {
          max-width: 528px;
          align-self: center;

          p,
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            color: ${mainBlack};
            margin-bottom: 16px;
            @media ${screen.small} {
              margin-bottom: 20px;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            font-size: 2rem;
            font-family: ${headingNormal};
          }

          h2 {
            color: ${fadeGreen};
            font-family: ${headingBold};
            font-size: 2.24rem;
            margin-bottom: 30px;
            line-height: 0.6;
            @media ${screen.small} {
              font-size: 3rem;
            }
            @media ${screen.medium} {
              margin-left: 40px;
              font-size: 3.25rem;
            }
          }

          h4 {
            color: ${fadeGreen};
            font-size: 1.7rem;
            @media ${screen.small} {
              font-size: 2rem;
            }
          }

          p {
            font-size: 1.05rem;
            font-family: ${pTextLighter};
            @media ${screen.small} {
              font-size: 1.125rem;
            }
          }
        }
      }

      .col-2 {
        max-width: 528px;
        margin-top: 18px;
        @media ${screen.small} {
          padding-left: 60px;
          margin-top: 122px;
        }
        @media ${screen.large} {
          padding-left: 20px;
        }
        .description {
          max-width: 528px;
          align-self: center;
          p,
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            color: ${mainBlack};
            margin-bottom: 16px;
            @media ${screen.small} {
              margin-bottom: 20px;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            font-size: 2rem;
            font-family: ${headingNormal};
          }

          p {
            font-size: 1.05rem;
            font-family: ${pTextLighter};
            @media ${screen.small} {
              font-size: 1.125rem;
            }
          }
        }

        .trophy {
          margin-top: 30px;
          .caption {
            color: ${mainBlack};
            font-family: ${pText};
            font-style: italic;
            font-weight: 900;
            font-size: 0.58rem;
            display: block;
            margin-top: 10px;
            margin-left: 16px;
            line-height: 1.5;
            @media ${screen.small} {
              margin-left: 25px;
              font-size: 0.75rem;
              margin-top: 15px;
            }
          }
        }
      }
    }
  }

  .torn-city {
    margin-top: 45px;
    visibility: hidden;
    @media ${screen.medium} {
      visibility: visible;
    }
  }
`;

const HistoryPage = ({ data }) => {
  // prismic content
  const titleTag = data.content.data.title_tag
    ? data.content.data.title_tag
    : "Lysicrates";
  const metaDescription = data.content.data.meta_description
    ? data.content.data.meta_description
    : "Lysicrates";
  const introDescription = data.content.data.introduction.html;
  const playwrightDescription = data.content.data.description.html;
  const playwrightQuote = data.content.data.blockquote.html;
  const athens1stRowDescription = data.content.data.first_row_description.html;
  const athens2ndRowDescription = data.content.data.second_row_description.html;
  const athens3rdRowDescription = data.content.data.third_row_description.html;
  const jamesMartin1stRowDescription =
    data.content.data.first_row_description1.html;
  const jamesMartin2ndRowDescription =
    data.content.data.second_row_description1.html;
  const jamesMartin3rdRowDescription =
    data.content.data.third_row_description1.html;
  const jamesMartin4thRowDescription =
    data.content.data.fourth_row_description.html;
  const jamesMartin5thRowDescription =
    data.content.data.fifth_row_description.html;
  const jamesMartinQuote = data.content.data.blockquote1.html;
  const botanicalDescription = data.content.data.description1.html;
  const aboutLysicratesMotto = data.content.data.motto;
  const aboutLysicratesDescription = data.content.data.main_description.html;
  const visionCol1Description =
    data.content.data.vision_column_1_description.html;
  const visionCol2Description =
    data.content.data.vision_column_2_description.html;

  return (
    <Layout>
      <SEO title={titleTag} description={metaDescription} />
      <Wrapper>
        <div className={`intro-section`}>
          <h1 className="intro-section__title">
            <span className="intro-section__title--top">The Lysicrates</span>{" "}
            <span className="intro-section__title--middle">Story</span>
            <span className="intro-section__title--bottom">
              from ancient Athens to
              <br />
              Sydney today
            </span>
          </h1>
          <div className="content">
            <div
              className="content__description"
              dangerouslySetInnerHTML={{ __html: introDescription }}
            />
            <div className="content__statue">
              <StaticImage
                src="../images/history/lysicrates-dionysos-statue.png"
                alt="Lysicrates Statue"
              />
            </div>
          </div>
        </div>
        <div
          className="playwright-section"
          style={{
            backgroundSize: "cover",
            backgroundPosition: "0 -150px",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${maskBg1})`,
          }}
        >
          <div
            className="playwright-section__description"
            dangerouslySetInnerHTML={{ __html: playwrightDescription }}
          />
          <div className="playwright-section__quote-row">
            <ScrollAnimation
              animateIn="slideInLeft"
              animateOnce={true}
              className="trophy"
            >
              <StaticImage
                src="../images/history/history-page-trophy-sketchh.png"
                alt="Lysicrates sketch trophy"
              />
            </ScrollAnimation>
            <div
              className="blockquote"
              dangerouslySetInnerHTML={{ __html: playwrightQuote }}
            />
          </div>
        </div>
        <section className="athens-section">
          <div className="athens-section__first-row">
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: athens1stRowDescription }}
            />
          </div>
          <div className="athens-section__second-row">
            <ScrollAnimation
              animateIn="fadeIn"
              animateOnce={true}
              className="lord-byron"
            >
              <StaticImage
                src="../images/history/history-page-byron.jpg"
                alt="Lysicrates Lord Byron"
              />
              <span className="caption">Poet Lord Byron</span>
            </ScrollAnimation>

            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: athens2ndRowDescription }}
            />
          </div>
          <div className="athens-section__third-row">
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: athens3rdRowDescription }}
            />
            <ScrollAnimation
              animateIn="fadeIn"
              animateOnce={true}
              className="statue"
            >
              <StaticImage
                src="../images/history/history-page-statues-transparent.png"
                alt="Lysicrates Museum"
              />
              <span className="caption">
                Engravings of the Lysicrates monument
                <br />
                made by James Stuart and Nicholas Revett
              </span>
            </ScrollAnimation>
          </div>
          <ScrollAnimation
            animateIn="slideInRight"
            animateOnce={true}
            className="statue-sketch"
          >
            <StaticImage
              src="../images/history/history-page-statue-sketch-transparent.png"
              alt="Lysicrates Statue"
            />
          </ScrollAnimation>
        </section>
        <section className="james-martin-section">
          <div className="james-martin-section__first-row">
            <ScrollAnimation
              animateIn="fadeIn"
              animateOnce={true}
              className="portrait"
            >
              <StaticImage
                src="../images/history/history-page-james-martin.png"
                alt="Lysicrates James Martin"
              />
              <span className="caption">
                Sir James Martin during his time
                <br /> as Chief Justice of NSW
              </span>
            </ScrollAnimation>
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: jamesMartin1stRowDescription }}
            />
          </div>
          <div
            className="gradient-bg-wrapper"
            style={{
              backgroundSize: "cover",
              backgroundPosition: "right top",
              backgroundRepeat: "no-repeat",
              backgroundImage: `url(${gradientBg})`,
            }}
          >
            <div className="james-martin-section__second-row">
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: jamesMartin2ndRowDescription,
                }}
              />
              <ScrollAnimation
                animateIn="fadeIn"
                animateOnce={true}
                className="trophy"
              >
                <StaticImage
                  src="../images/history/lysicrates-james-martin-trophy.png"
                  alt="Lysicrates Trophy"
                />
                <span className="caption">
                  Statue of James Martin walking to school as a child
                  <br />– commissioned by The Lysicrates Foundation in
                  <br />
                  2017 to commemorate his achievements
                </span>
              </ScrollAnimation>
            </div>
            <div className="james-martin-section__third-row">
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: jamesMartin3rdRowDescription,
                }}
              />
              <ScrollAnimation
                animateIn="fadeIn"
                animateOnce={true}
                className="monument"
              >
                <StaticImage
                  src="../images/history/history-page-monument-old-photo.png"
                  alt="Lysicrates Monument"
                />
                <span className="caption">
                  The Lysicrates Monument replica where
                  <br />
                  it stood in Martin’s garden in Potts Point
                </span>
              </ScrollAnimation>
            </div>
            <div className="james-martin-section__fourth-row">
              <div className="person-list">
                <ScrollAnimation
                  animateIn="slideInUp"
                  animateOnce={true}
                  className="person-image"
                >
                  <StaticImage
                    src="../images/history/history-page-fritz.jpg"
                    alt="Fritz Burnell"
                  />
                  <span className="caption">Fritz Burnell</span>
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="slideInUp"
                  animateOnce={true}
                  delay={200}
                  className="person-image"
                >
                  <StaticImage
                    src="../images/history/history-page-drummond.jpg"
                    alt="David Drummond"
                  />
                  <span className="caption">David Drummond</span>
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="slideInUp"
                  animateOnce={true}
                  delay={300}
                  className="person-image"
                >
                  <StaticImage
                    src="../images/history/history-page-evatt.jpg"
                    alt="Clive Evatt"
                  />
                  <span className="caption">Clive Evatt</span>
                </ScrollAnimation>
              </div>
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: jamesMartin4thRowDescription,
                }}
              />
            </div>
            <div className="james-martin-section__fifth-row">
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: jamesMartin5thRowDescription,
                }}
              />
            </div>
            <div
              className="james-martin-section__blockquote"
              style={{
                backgroundSize: "100% 100%",
                backgroundPosition: "",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${tornPaperBg})`,
              }}
            >
              <div
                className="texts"
                dangerouslySetInnerHTML={{ __html: jamesMartinQuote }}
              />
            </div>
          </div>
        </section>
        {/* <section className="map-section">
          <iframe
            src="https://snazzymaps.com/embed/175429"
            width="100%"
            height="450px"
            title="map"
          />
        </section> */}
        <section className="botanical-section">
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: botanicalDescription }}
          />

          <ScrollAnimation
            animateIn="slideInUp"
            animateOnce={true}
            className="monument"
          >
            <StaticImage
              src="../images/history/lysicrates-monument.png"
              alt="Lysicrates Monument"
            />
            <span className="caption">
              The Lysicrates monument replica, located in the
              <br />
              Royal Botanical Gardens, Sydney, NSW
            </span>
          </ScrollAnimation>
        </section>
        <section className="about-lysicrates-section">
          <div className="about-lysicrates-section__motto">
            <p>The motto of the Lysicrates Foundation is</p>
            <h6>{aboutLysicratesMotto}</h6>
          </div>
          <div className="about-lysicrates-section__green-bg">
            <div className="row-content">
              <div className="group-photo">
                <StaticImage
                  src="../images/history/lysicrates-monument-shot.jpg"
                  alt="Lysicrates Group"
                />
              </div>
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: aboutLysicratesDescription }}
              />
            </div>
          </div>
          <div className="about-lysicrates-section__vision">
            <div className="col-1">
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: visionCol1Description }}
              />
            </div>
            <div className="col-2">
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: visionCol2Description }}
              />

              <ScrollAnimation
                animateIn="fadeIn"
                animateOnce={true}
                className="trophy"
              >
                <StaticImage
                  src="../images/history/lysicrates-prize-2015-trophy.jpg"
                  alt="Lysicrates Trophy"
                />
                <span className="caption">
                  Katie-Ann Houghton, Glass Trophy Artist
                  <br />
                  Illustrations kindly created by Jacquelyn Pennay
                </span>
              </ScrollAnimation>
            </div>
          </div>
        </section>
        <div className="torn-city">
          <StaticImage
            src="../images/history/history-page-torn-city.png"
            alt="Lysicrates City"
          />
        </div>
      </Wrapper>
    </Layout>
  );
};

export default HistoryPage;

export const dataQuery = graphql`
  {
    content: prismicHistoryPage {
      data {
        introduction {
          html
        }
        description {
          html
        }
        blockquote {
          html
        }
        first_row_description {
          html
        }
        second_row_description {
          html
        }
        third_row_description {
          html
        }
        first_row_description1 {
          html
        }
        second_row_description1 {
          html
        }
        third_row_description1 {
          html
        }
        fourth_row_description {
          html
        }
        fifth_row_description {
          html
        }
        blockquote1 {
          html
        }
        description1 {
          html
        }
        motto
        main_description {
          html
        }
        vision_column_1_description {
          html
        }
        vision_column_2_description {
          html
        }
        title_tag
        meta_description
      }
    }
  }
`;
