import React from "react";
import MainNav from "./main-nav";
import MobileNav from "./mobile-nav";
import { graphql, useStaticQuery } from "gatsby";
import { toSlug } from "../../functions";

const Header = () => {
  const data = useStaticQuery(dataQuery);

  // Return sorted slugs to use for links
  const events = data.events.edges
    .map(data => toSlug(data.node.data.title.text))
    .sort((a, b) => b - a);

  return (
    <div>
      <MainNav events={events} />
      <MobileNav events={events} />
    </div>
  );
};

export default Header;

const dataQuery = graphql`
  {
    events: allPrismicEvent {
      edges {
        node {
          data {
            title {
              text
            }
          }
        }
      }
    }
  }
`;
