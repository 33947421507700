import React, { useState } from "react";
import { graphql, Link, StaticQuery } from "gatsby";
import Layout from "../layout";
import SEO from "../components/seo";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import {
  headingBold,
  headingNormal,
  headerBlue,
  mainBlack,
  mainWhite,
  mainGrey,
  transHover,
  pText,
  mainRed,
  mainGreen,
  fadeGreen,
  titleBlack,
  screen,
  innerWidth,
  heading,
  pTextLighter,
  pTextBold,
  lightGrey,
} from "../components/common/variables";
import { Link as SpyLink } from "react-scroll";
import Fade from "react-reveal/Fade";
import Reveal from "react-reveal/Reveal";
import jamesBanner from "../images/james-martin/lysicrates-james-martin-banner-image.png";
import jamesBannerBg from "../images/james-martin/lysicrates-james-martin-banner-paper-background.png";
import { Waypoint } from "react-waypoint";
import Arrow1 from "../images/svg-assets/arrow1.svg";
import DashedLine from "../images/svg-assets/dashed-line.svg";
import longGrungeBg from "../images/james-martin/grunge-long-scroll.png";
import greenBg from "../images/history/lysicrates-watercolor-background.jpg";
import { moveDash, enterTooltip } from "../components/common/animations";
import ReactAudioPlayer from "react-audio-player";
import audio from "../audio/the-buried-chief-woman.wav";
import { IoIosPlay, IoIosPause } from "react-icons/io";
import map from "../images/james-martin/map-james-martin.png";
import longScrollBg from "../images/james-martin/lysicrates-james-martin-paper-scroll.png";
import redRipTop from "../images/james-martin/red-rip-top.png";
import redRipBottom from "../images/james-martin/red-rip-bottom.png";
import whiteRipTop from "../images/james-martin/white-rip-top.png";
import whiteRipBottom from "../images/james-martin/white-rip-bottom.png";
import greyRipTop from "../images/james-martin/grey-rip-top.png";
import greyRipBottom from "../images/james-martin/grey-rip-bottom.png";
import jamesStatue from "../images/james-martin/lysicrates-james-martin-statue.png";
import bottomBanner from "../images/james-martin/lysicrates-james-martin-waverley-cemetery-vault.png";

const Wrapper = styled.div`
  position: relative;
  color: ${mainBlack};

  .test {
    transform: ;
  }

  .intro-bg-wrapper {
    background-image: url(${jamesBannerBg});
    background-size: cover;
    background-position: top right;
    background-repeat: no-repeat;
    background-color: ${mainWhite};
    position: relative;
    overflow: hidden;

    &__james-img {
      display: none;
      @media ${screen.medium} {
        display: block;
        position: absolute;
        max-width: 870px;
        width: 100%;
        right: 1vw;
        bottom: 0;
      }
      @media ${screen.large} {
        max-width: 930px;
        right: 2vw;
      }
    }
  }

  .title-section {
    width: 100%;
    padding-top: 80px;
    position: relative;
    z-index: 2;

    &__area {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 0 30px 0 30px;
      @media ${screen.small} {
        padding: 0 45px 0 45px;
      }
      @media ${screen.medium} {
        padding: 0 60px 0 60px;
      }
      @media ${screen.large} {
        padding: 0 20px 0 20px;
      }
      @media ${screen.xLarge} {
        padding: 0 0 0 0;
      }

      &__box {
        margin-left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media ${screen.small} {
          display: block;
        }

        .first-line {
          display: block;
          font-family: ${headingBold};
          font-size: 2.1875rem;
          color: ${mainRed};
          margin-left: 0;
          text-align: center;
          @media ${screen.small} {
            margin-bottom: -10px;
            text-align: left;
          }
        }

        .second-line {
          display: block;
          font-family: ${headingNormal};
          font-size: 3rem;
          color: ${titleBlack};
          text-align: center;
          @media ${screen.small} {
            font-size: 3.75rem;
            text-align: left;
            margin-left: 25px;
          }
          @media ${screen.medium} {
            font-size: 4.375rem;
          }
        }
      }
    }
  }

  .introduction {
    position: relative;
    overflow: hidden;
    z-index: 2;
    max-width: ${innerWidth};
    margin: 20px auto 0 auto;
    padding: 0 30px 90px 30px;
    @media ${screen.small} {
      padding: 0 45px 90px 45px;
      margin: 30px auto 0 auto;
    }
    @media ${screen.medium} {
      padding: 0 60px 90px 60px;
    }
    @media ${screen.large} {
      padding: 0 20px 90px 20px;
    }
    @media ${screen.xLarge} {
      padding: 0 0 90px 0;
    }

    .introduction-content {
      max-width: 700px;

      &__p {
        color: ${mainBlack};
        font-family: ${pTextLighter};
        margin-bottom: 16px;
        font-size: 1.05rem;
        @media ${screen.small} {
          margin-bottom: 20px;
          font-size: 1.125rem;
        }

        &--med {
          font-size: 1.4rem;
          @media ${screen.small} {
            font-size: 1.6rem;
          }
        }

        &--lg {
          font-family: ${heading};
          font-size: 1.9rem;
          @media ${screen.small} {
            font-size: 2.2rem;
          }
        }
      }
    }
  }

  .red-row {
    background: ${mainRed};
    padding: 45px 0;
    position: relative;
    z-index: 2;
    @media ${screen.small} {
      padding: 90px 0;
    }

    .red-row-content {
      display: flex;
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 0 30px 0 30px;
      @media ${screen.small} {
        padding: 0 45px 0 45px;
      }
      @media ${screen.medium} {
        padding: 0 60px 0 60px;
      }
      @media ${screen.large} {
        padding: 0 20px 0 20px;
      }
      @media ${screen.xLarge} {
        padding: 0 0 0 0;
      }

      &__img {
        display: none;
        @media ${screen.medium} {
          display: block;
          padding-right: 50px;
          align-self: center;
          width: 100%;
          max-width: 500px;
        }

        .caption {
          display: block;
          color: ${mainWhite};
          font-family: ${pText};
          font-size: 0.7rem;
          font-style: italic;
          margin-top: 12px;
        }
      }

      &__description {
        max-width: 700px;

        .heading {
          color: ${mainWhite};
          font-family: ${headingBold};
          font-size: 2.3rem;
          margin-bottom: 20px;
          @media ${screen.small} {
            font-size: 2.6rem;
            margin-bottom: 32px;
          }
        }

        .subheading {
          color: ${mainWhite};
          font-family: ${heading};
          font-size: 1.5rem;
          margin-bottom: 16px;
          margin-top: 20px;
          @media ${screen.small} {
            margin-top: 32px;
          }
        }

        p {
          color: ${mainWhite};
          margin-bottom: 16px;
          font-size: 1.05rem;
          font-family: ${pTextLighter};
          @media ${screen.small} {
            margin-bottom: 20px;
            font-size: 1.125rem;
          }
        }
      }
    }

    &__rip-top-bg {
      position: absolute;
      top: -10px;
      width: 100%;
      @media ${screen.small} {
        top: -26px;
      }
      @media ${screen.medium} {
        top: -42px;
      }
      @media ${screen.xLarge} {
        top: -56px;
      }
    }

    &__rip-bottom-bg {
      position: absolute;
      bottom: -10px;
      width: 100%;
      @media ${screen.small} {
        bottom: -26px;
      }
      @media ${screen.medium} {
        bottom: -42px;
      }
      @media ${screen.xLarge} {
        bottom: -56px;
      }
    }
  }

  .sticky-row {
    position: relative;
    background-image: url(${longScrollBg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    @media ${screen.large} {
      overflow: visible;
    }

    .sticky-row-content {
      display: flex;
      justify-content: flex-end;
      max-width: ${innerWidth};
      padding: 0 30px 0 30px;
      @media ${screen.small} {
        height: 4000px;
        margin: 0 0 0 auto;
        padding: 0 45px 0 45px;
      }
      @media ${screen.medium} {
        height: 3500px;
        margin: 0 auto;
        padding: 0 60px 0 60px;
        max-width: ${innerWidth};
      }
      @media ${screen.large} {
        height: 3800px;
        padding: 0 20px 0 20px;
      }
      @media ${screen.xLarge} {
        padding: 0 0 0 0;
      }

      .sticky-left {
        display: none;
        @media ${screen.small} {
          display: block;
          top: 0;
          padding-top: 200px;
          z-index: 5;
        }
        @media ${screen.medium} {
          position: absolute;
          max-width: 400px;
          width: 100%;
          left: 150px;
          top: 170px;
        }
        @media ${screen.large} {
          max-width: inherit;
          top: 0;
          width: 700px;
          position: sticky;
          height: 1200px;
        }

        &__img {
          position: absolute;
          bottom: 400px;
          top: 30%;
          transform: translateY(-50%) translateX(-90px) scale(0.75);
          @media ${screen.small} {
            transform: translateY(-50%) translateX(-200px) scale(0.62);
            top: 23%;
          }
          @media ${screen.medium} {
            transform: translateY(-50%) translateX(-220px) scale(0.6);
            top: 10%;
          }
          @media ${screen.large} {
            top: 30%;
            transform: translateY(-50%) translateX(-105px) scale(0.6);
          }
          @media ${screen.xLarge} {
            transform: translateY(-50%) translateX(-90px) scale(0.75);
          }
        }
      }

      &__description {
        max-width: 700px;
        max-width: 850px;
        width: 100%;
        @media ${screen.small} {
        }
        @media ${screen.medium} {
          max-width: 840px;
          padding-left: 86px;
        }
        @media ${screen.large} {
          max-width: 700px;
        }

        .plain-description {
          color: ${mainBlack};

          &__heading {
            font-family: ${headingBold};
            font-size: 1.7rem;
            margin: 45px 0 20px 0;
            @media ${screen.small} {
              font-size: 2.2rem;
              margin: 80px 0 50px 0;
            }

            &--red {
              color: ${mainRed};
            }

            &--top {
              @media ${screen.small} {
                max-width: 520px;
                margin: 80px 0 50px auto;
              }
              @media ${screen.medium} {
                margin: 80px 0 50px 0;
              }
            }
          }

          p {
            margin-bottom: 16px;
            font-size: 1.05rem;
            font-family: ${pTextLighter};
            @media ${screen.small} {
              margin-bottom: 20px;
              font-size: 1.125rem;
            }
          }

          h6 {
            font-family: ${pTextLighter};
            font-size: 1.4rem;
            margin-top: 20px;
            font-weight: 300;
            @media ${screen.small} {
              font-size: 1.6rem;
            }
          }

          .gov-house {
            max-width: 570px;
            transform: rotate(-4deg);
            display: block;
            margin: 0 auto;

            .caption {
              display: block;
              color: ${mainBlack};
              font-family: ${pText};
              font-size: 0.7rem;
              font-style: italic;
              margin-top: 10px;
              max-width: 200px;
              transform: rotate(4deg);
              @media ${screen.small} {
                margin-top: 20px;
              }
            }

            .pointing-text {
              position: absolute;
              bottom: -80px;
              font-family: ${headingBold};
              font-size: 1.25rem;
              font-weight: 400;
              max-width: 200px;
              right: -40px;
              @media ${screen.small} {
                right: -110px;
                font-size: 1.5rem;
                bottom: -35px;
                max-width: 250px;
              }

              &__arrow {
                position: absolute;
                display: block;
                max-width: 75px;
                width: 100%;
                transform: scaleX(-1) rotate(120deg);
                top: -55px;
                right: 70px;
                @media ${screen.small} {
                  max-width: 120px;
                  top: -82px;
                  right: 50px;
                  transform: scaleX(-1) rotate(120deg);
                }
              }
            }

            &--desktop {
              display: none;
              @media ${screen.medium} {
                display: block;
              }
            }

            &--mobile {
              margin-top: 20px;
              @media ${screen.small} {
                margin-top: 25px;
              }
              @media ${screen.medium} {
                display: none;
              }
            }
          }

          &--top {
            @media ${screen.small} {
              max-width: 520px;
              margin: 0 0 0 auto;
            }
            @media ${screen.medium} {
              max-width: inherit;
              margin: 0;
            }
          }
        }

        .paper-description {
          padding-top: 120px;
          position: relative;
          z-index: 2;

          &__heading {
            font-family: ${pText};
            font-size: 1.4rem;
            margin-bottom: 20px;
            @media ${screen.small} {
              font-size: 1.6rem;
              margin-bottom: 40px;
            }

            &--red {
              color: ${mainRed};
            }
          }

          p {
            font-family: ${heading};
            font-size: 1.3rem;
            margin-bottom: 16px;
            @media ${screen.small} {
              font-size: 1.6rem;
            }
            @media ${screen.medium} {
              font-size: 1.5rem;
            }
            @media ${screen.large} {
              font-size: 1.6rem;
            }
          }
        }

        .map-description {
          padding-top: 100px;
          position: relative;
          z-index: 2;
          max-width: 540px;
          @media ${screen.small} {
            margin-left: 200px;
          }
          @media ${screen.medium} {
            padding-top: 130px;
            max-width: 560px;
            margin-left: 190px;
          }
          @media ${screen.large} {
            padding-top: 100px;
            max-width: inherit;
            margin-left: 0;
          }

          &__heading {
            font-family: ${headingBold};
            margin-bottom: 16px;
            position: relative;
            z-index: 2;
            font-size: 1.3rem;
            @media ${screen.small} {
              font-size: 1.6rem;
            }

            &--top {
              margin-top: 155px;
              @media ${screen.small} {
                margin-top: 210px;
              }
              @media ${screen.medium} {
                margin-top: 0;
              }
            }
          }

          p {
            margin-bottom: 16px;
            font-size: 1.05rem;
            font-family: ${pTextLighter};
            position: relative;
            z-index: 2;
            @media ${screen.small} {
              margin-bottom: 20px;
              font-size: 1.125rem;
            }
          }

          &__red-p1 {
            color: ${mainRed};
            display: block;
            font-family: ${headingBold};
            font-size: 1.6rem;
            margin-top: 50px;
            @media ${screen.small} {
              font-size: 1.95rem;
            }
          }

          &__red-p2 {
            color: ${mainRed};
            display: block;
            font-family: ${headingBold};
            font-size: 1.6rem;
            margin-left: 110px;
            margin-top: 30px;
            @media ${screen.small} {
              font-size: 1.95rem;
              margin-left: 140px;
              margin-top: 50px;
            }
          }

          &__red-p3 {
            color: ${mainRed};
            display: block;
            font-family: ${headingBold};
            font-size: 1.6rem;
            margin-left: 160px;
            margin-top: 30px;
            @media ${screen.small} {
              font-size: 1.95rem;
              margin-left: 220px;
              margin-top: 50px;
            }
          }

          &__house-bg {
            position: absolute;
            width: 100%;
            left: 0;
            top: -20px;
            z-index: 1;
            max-width: 400px;
            @media ${screen.small} {
              left: -210px;
              top: -10px;
            }
            @media ${screen.medium} {
              max-width: 410px;
              left: -440px;
              top: 0;
            }
            @media ${screen.large} {
              max-width: 400px;
              left: -420px;
              top: -65px;
            }
          }

          &__arrow {
            max-width: 75px;
            position: absolute;
            left: 220px;
            top: 205px;
            z-index: 2;
            transform: scaleY(-1) rotate(-50deg);
            @media ${screen.small} {
              max-width: 140px;
              position: absolute;
              left: 180px;
              top: 245px;
              z-index: 2;
              transform: scaleY(-1) rotate(-35deg);
            }
            @media ${screen.medium} {
              max-width: 140px;
              position: absolute;
              left: -180px;
              top: 290px;
              transform: scaleY(1);
            }
            @media ${screen.large} {
              top: 245px;
            }
          }
        }
      }
    }

    &__paper-bg {
      position: absolute;
      width: 1570px;
      top: 1684px;
      z-index: 1;
      left: -120px;
      display: none;
      @media ${screen.small} {
        display: block;
        width: 1500px;
        right: inherit;
        left: -80px;
        top: 1630px;
        max-width: inherit;
      }
      @media ${screen.medium} {
        width: 100%;
        right: -220px;
        left: inherit;
        top: 1500px;
      }
      @media ${screen.large} {
        right: 1vw;
        top: 1665px;
        width: 100%;
        max-width: 1370px;
      }
      @media ${screen.xLarge} {
        right: 7vw;
      }
    }

    .map-container {
      right: 0;
      width: 100%;
      z-index: 0;
      overflow: hidden;
      margin-top: -120px;
      @media ${screen.small} {
        bottom: 270px;
        position: absolute;
        right: auto;
        margin-top: 0;
      }
      @media ${screen.medium} {
        bottom: 255px;
      }

      img {
        display: block;
        margin: 0 0 0 -90px;
        width: 650px;
        width: 620px;
        @media ${screen.small} {
          padding-right: 30px;
          width: 1200px;
          margin: 0 auto;
        }
        @media ${screen.medium} {
          margin: 0 auto;
          width: 1200px;
        }
        @media ${screen.large} {
          width: 1700px;
        }
      }

      &__dashed-line {
        position: absolute;
        right: 150px;
        bottom: 80px;
        left: calc(50% - 10px);
        transform: translateX(-50%);
        width: 270px;
        @media ${screen.small} {
          width: 505px;
          left: calc(50% - 58px);
          right: inherit;
          bottom: 70px;
        }
        @media ${screen.medium} {
          bottom: 90px;
          width: 770px;
          left: calc(50% - 90px);
        }
        @media ${screen.large} {
          bottom: 100px;
          width: ${innerWidth};
          left: calc(50% - 100px);
        }

        svg {
          .dashed {
            stroke-dasharray: 20;
            stroke-width: 10;
            @media ${screen.small} {
              stroke-width: 3;
              stroke-dasharray: 10;
            }
          }

          .path {
            stroke-dasharray: 1300;
            stroke-dashoffset: 1300;
            animation: ${moveDash} 4s linear forwards;
            stroke-width: 9;
            @media ${screen.small} {
              stroke-width: 2;
            }
          }
        }

        .start-text {
          color: ${mainRed};
          font-family: ${headingBold};
          font-size: 1rem;
          position: absolute;
          top: -23px;
          left: -5px;
          @media ${screen.small} {
            font-size: 1.4rem;
            top: -30px;
            left: -40px;
          }
          @media ${screen.medium} {
            top: -40px;
            left: -40px;
            font-size: 1.6rem;
          }
        }

        .end-text {
          color: ${mainRed};
          font-family: ${headingBold};
          font-size: 1rem;
          position: absolute;
          right: 0;
          bottom: 20px;
          @media ${screen.small} {
            font-size: 1.4rem;
            right: -30px;
            bottom: 30px;
          }
          @media ${screen.medium} {
            right: -30px;
            bottom: 50px;
            font-size: 1.6rem;
          }
        }
      }
    }
  }

  .henry-parkes-row {
    background: ${mainWhite};
    padding-top: 1px;
    padding-bottom: 50px;
    position: relative;
    @media ${screen.small} {
      margin-top: -250px;
      padding-top: 10px;
      padding-bottom: 100px;
    }
    @media ${screen.medium} {
      padding-top: 90px;
    }
    @media ${screen.large} {
      padding-bottom: 250px;
    }

    .henry-parkes-row-content {
      max-width: ${innerWidth};
      margin: 0 auto;
      display: block;
      padding-left: 30px;
      padding-right: 30px;
      @media ${screen.small} {
        padding-left: 45px;
        padding-right: 45px;
      }
      @media ${screen.medium} {
        display: flex;
        padding-left: 60px;
        padding-right: 60px;
      }
      @media ${screen.large} {
        padding-left: 20px;
        padding-right: 20px;
      }
      @media ${screen.xLarge} {
        padding: 0;
      }

      &__col1 {
        width: 100%;

        .images {
          display: flex;
          margin-top: 70px;
          margin-bottom: 35px;

          &__painting {
            width: 100%;
            max-width: 380px;
            @media ${screen.medium} {
              max-width: 520px;
            }
          }

          &__portrait {
            width: 100%;
            max-width: 151px;
            transform: translateX(-15px) rotate(5deg);
            margin-top: 40px;
            position: relative;
            z-index: 5;
            @media ${screen.small} {
              max-width: 310px;
            }
            @media ${screen.medium} {
              margin-top: 50px;
              max-width: 250px;
            }
          }
        }

        p {
          color: ${mainBlack};
          font-family: ${pTextLighter};
          font-size: 0.7rem;
          font-style: italic;
          margin-bottom: 16px;
          max-width: 430px;
        }
      }

      &__col2 {
        max-width: 1000px;

        .p1 {
          font-size: 1.05rem;
          font-family: ${pTextLighter};
          padding-left: 10px;
          margin-bottom: 30px;
          @media ${screen.small} {
            font-size: 1.125rem;
          }
        }

        .p2 {
          font-size: 1.05rem;
          font-family: ${pTextLighter};
          padding-left: 10px;
          margin-top: 20px;
          @media ${screen.small} {
            font-size: 1.125rem;
            margin-top: 30px;
          }
          @media ${screen.medium} {
            margin-top: 60px;
          }
        }

        .p3 {
          font-size: 1.3rem;
          font-family: ${pTextLighter};
          padding-left: 10px;
          margin-top: 20px;
          @media ${screen.small} {
            margin-top: 35px;
            font-size: 1.6rem;
          }
        }

        .signature {
          position: relative;
          max-width: 520px;
          @media ${screen.medium} {
            transform: translateX(-30px);
            max-width: 450px;
          }

          p {
            position: absolute;
            top: calc(50% - 5px);
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 260px;
            width: 100%;
            font-family: ${heading};
            font-size: 1.15rem;
            font-weight: 400;
            line-height: 1.75;
            @media ${screen.small} {
              font-size: 1.6rem;
              max-width: 360px;
              line-height: 1.4;
            }
            @media ${screen.medium} {
              max-width: 375px;
            }
          }
        }
      }
    }

    &__rip-top-bg {
      position: absolute;
      top: -10px;
      width: 100%;
      @media ${screen.small} {
        top: -26px;
      }
      @media ${screen.medium} {
        top: -42px;
      }
      @media ${screen.xLarge} {
        top: -56px;
      }
    }
  }

  .school-row {
    position: relative;
    background: ${lightGrey};
    padding-bottom: 50px;
    padding-top: 50px;
    z-index: 2;
    @media ${screen.medium} {
      padding-top: 120px;
      padding-bottom: 25px;
    }
    @media ${screen.large} {
      padding-bottom: 200px;
    }

    &__rip-top-bg {
      position: absolute;
      top: 0;
      width: 100%;
    }

    &__rip-bottom-bg {
      position: absolute;
      bottom: -10px;
      width: 100%;
      @media ${screen.small} {
        bottom: -26px;
      }
      @media ${screen.medium} {
        bottom: -42px;
      }
      @media ${screen.xLarge} {
        bottom: -56px;
      }
    }

    .school-row-wrapper {
      .school-row-content {
        max-width: ${innerWidth};
        margin: 0 auto;
        position: relative;
        z-index: 2;
        padding-left: 30px;
        padding-right: 30px;
        @media ${screen.small} {
          padding-left: 45px;
          padding-right: 45px;
        }
        @media ${screen.medium} {
          padding-left: 60px;
          padding-right: 60px;
        }
        @media ${screen.large} {
          padding-left: 20px;
          padding-right: 20px;
        }
        @media ${screen.xLarge} {
          padding-left: 0;
          padding-right: 0;
        }

        h6 {
          font-family: ${headingBold};
          font-size: 1.7rem;
          max-width: 530px;
          @media ${screen.small} {
            font-size: 2.2rem;
          }
          @media ${screen.medium} {
            max-width: 560px;
          }
          @media ${screen.large} {
            max-width: 630px;
          }

          span {
            color: ${mainRed};
          }
        }

        p {
          display: none;
          @media ${screen.medium} {
            color: ${mainBlack};
            font-family: ${pTextLighter};
            font-size: 0.85rem;
            font-style: italic;
            max-width: 430px;
            display: block;
            max-width: 430px;
            margin-top: 20px;
          }
          @media ${screen.large} {
            max-width: 400px;
            margin-left: 70px;
            margin-top: 120px;
          }
        }

        &__school-bg {
          width: 100%;
          max-width: 900px;
          right: 0;
          z-index: 1;
          @media ${screen.medium} {
            position: absolute;
            max-width: 570px;
            top: -100px;
          }
          @media ${screen.large} {
            max-width: 900px;
            top: -240px;
          }

          p {
            color: ${mainBlack};
            font-family: ${pTextLighter};
            font-size: 0.55rem;
            font-style: italic;
            max-width: 600px;
            padding: 0 40px;
            @media ${screen.small} {
              padding: 0;
              max-width: 530px;
              font-size: 0.7rem;
              margin-left: 140px;
            }
            @media ${screen.medium} {
              display: none;
            }
          }
        }
      }
    }
  }

  .sticky-num {
    position: relative;
    background-image: url(${longGrungeBg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;

    .sticky-num-content {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 500px;
      @media ${screen.small} {
        padding-left: 45px;
        padding-right: 45px;
        padding-bottom: 500px;
      }
      @media ${screen.medium} {
        padding-bottom: 860px;
        padding-left: 0;
        padding-right: 0;
      }
      @media ${screen.large} {
        height: 6360px;
        display: flex;
        padding-bottom: 0;
        justify-content: flex-end;
      }

      .sticky-left {
        display: none;
        @media ${screen.large} {
          display: block;
          position: sticky;
          top: 0;
          padding-top: 200px;
          height: 1200px;
          width: 700px;
          z-index: 2;
        }

        &__num {
          position: absolute;
          width: 100%;
          top: 300px;
          transform: translateY(-50%) translateX(48px);
          z-index: 3;
          color: ${mainRed};
          font-family: ${heading};
          font-size: 12rem;
        }
      }

      .content {
        &__each-year {
          position: relative;
          @media ${screen.small} {
            display: flex;
          }

          .year {
            display: none;
            @media ${screen.large} {
              display: block;
              pointer-events: none;
              z-index: 6;
              margin-top: 92px;
              max-width: 210px;
              width: 100%;
              transform: translateX(-80px);
            }

            span {
              color: ${mainBlack};
              font-family: ${heading};
              font-size: 12rem;
              transition: color 0.14s ease-out;
            }

            &--tablet {
              @media ${screen.medium} {
                display: block;
              }
              @media ${screen.large} {
                display: none;
              }

              span {
                color: ${mainRed};
                font-family: ${heading};
                font-size: 5.9rem;
                @media ${screen.small} {
                  font-size: 10rem;
                }
              }
            }

            &--first {
              span {
                color: ${mainRed};
              }
            }
          }

          .description {
            width: 100%;
            margin-top: 41px;
            @media ${screen.small} {
              margin-top: 29px;
            }
            @media ${screen.medium} {
              max-width: 600px;
              margin-top: 41px;
            }
            @media ${screen.large} {
              max-width: 1950px;
              margin-top: 92px;
            }

            h6 {
              font-size: 1.35rem;
              font-family: ${pTextBold};
              padding-left: 10px;
              margin-top: 10px;
              @media ${screen.small} {
                font-size: 1.4rem;
                margin-top: 30px;
              }
            }

            p {
              font-size: 1.05rem;
              font-family: ${pTextLighter};
              padding-left: 10px;
              margin-bottom: 20px;
              @media ${screen.small} {
                font-size: 1.125rem;
                margin-bottom: 30px;
              }
            }
          }

          .active {
            span {
              color: ${mainRed};
            }
          }
        }

        .yr-38 {
          position: relative;
          padding-top: 30px;
          @media ${screen.small} {
            padding-top: 90px;
          }

          .description {
            max-width: 800px;
          }

          h6 {
            margin-bottom: 20px;
            max-width: 550px;
            @media ${screen.medium} {
              max-width: 600px;
            }
            @media ${screen.large} {
              max-width: inherit;
            }
          }

          p {
            @media ${screen.small} {
              max-width: 470px;
            }
            @media ${screen.medium} {
              max-width: 600px;
            }
            @media ${screen.large} {
              max-width: 400px;
            }

            .tooltip {
              font-family: ${pTextBold};
              position: relative;
              display: inline-block;

              .title {
                font-family: ${pTextBold};
              }

              &__content {
                animation: ${enterTooltip} 0.4s ease-out forwards;
                font-family: ${pTextLighter};
                position: absolute;
                background: ${mainRed};
                display: block;
                width: 195px;
                font-size: 0.65rem;
                color: ${mainWhite};
                padding: 10px 10px;
                top: -64px;
                transform: scale(0);
                @media ${screen.small} {
                  font-size: 0.85rem;
                  width: 220px;
                  top: -75px;
                  padding: 10px 15px;
                }
              }

              &__arrow {
                display: block;
                width: 0;
                height: 0;
                border-left: 18px solid transparent;
                border-right: 18px solid transparent;
                border-top: 18px solid ${mainRed};
                position: absolute;
                bottom: -8px;
                left: -5px;
                transform: rotate(-90deg);
              }

              &__close {
                position: absolute;
                right: 8px;
                top: 2px;
                cursor: pointer;
                font-family: sans-serif;
              }
            }
          }

          .pointing-text {
            font-family: ${headingBold};
            font-size: 1.5rem;
            font-weight: 400;
            margin-top: 70px;
            margin-left: 70px;
            position: relative;
            display: none;
            @media ${screen.small} {
              margin-left: 150px;
              margin-top: 10px;
              display: block;
            }
            @media ${screen.medium} {
              margin-left: 400px;
              margin-top: 70px;
            }
            @media ${screen.large} {
              margin-left: 110px;
            }

            &__arrow {
              position: absolute;
              width: 100%;
              max-width: 120px;
              transform: scaleX(-1);
              right: 0;
              bottom: -10px;
              z-index: 2;
              @media ${screen.small} {
                right: 105px;
                bottom: 40px;
                transform: scaleX(-1) rotate(16deg);
              }
              @media ${screen.medium} {
                right: 0;
                bottom: -10px;
                transform: scaleX(-1);
              }
            }
          }

          .caption {
            display: none;
            color: ${mainBlack};
            font-family: ${pText};
            font-size: 0.7rem;
            font-style: italic;
            margin-left: 200px;
            margin-top: 70px;
            @media ${screen.small} {
              display: block;
              margin-left: 158px;
              margin-top: -15px;
            }
            @media ${screen.medium} {
              margin-left: 550px;
              margin-top: 70px;
            }
            @media ${screen.large} {
              margin-left: 220px;
            }
          }

          &__book-bg {
            position: absolute;
            width: 100%;
            max-width: 210px;
            transform: rotate(8deg);
            display: none;
            @media ${screen.small} {
              display: block;
              right: 2px;
              bottom: 120px;
            }
            @media ${screen.medium} {
              right: 50px;
            }
            @media ${screen.large} {
              right: -150px;
            }

            &--mobile {
              display: flex;
              justify-content: flex-end;
              position: relative;
              @media ${screen.small} {
                display: none;
              }

              .book-img {
                max-width: 180px;
                width: 100%;
                transform: rotate(8deg);

                .book-caption {
                  transform: rotate(-8deg);
                  display: block;
                  color: ${mainBlack};
                  font-family: ${pTextLighter};
                  font-size: 0.55rem;
                  font-style: italic;
                  margin-top: 5px;
                }
              }

              .pointer-text {
                font-family: ${headingBold};
                font-size: 1.25rem;
                font-weight: 400;
                margin-top: 70px;
                margin-left: 70px;
                position: absolute;
                top: 0;
                left: -58px;
                z-index: 2;
                max-width: 150px;

                &__arrow {
                  position: absolute;
                  width: 100%;
                  max-width: 75px;
                  transform: scaleX(-1);
                  right: 0;
                  bottom: -25px;
                  z-index: 2;
                }
              }
            }
          }
        }

        .yr-39 {
          position: relative;

          h6 {
            margin-bottom: 20px;
            max-width: 500px;
          }

          p {
            @media ${screen.large} {
              max-width: 400px;
            }
          }

          &__nichols-bg {
            position: absolute;
            width: 100%;
            max-width: 190px;
            top: -20px;
            transform: rotate(-4deg);
            display: none;
            @media ${screen.small} {
              display: block;
              right: 50px;
              top: -100px;
            }
            @media ${screen.medium} {
              right: 70px;
              top: -20px;
            }
            @media ${screen.large} {
              right: -120px;
            }

            .caption {
              display: block;
              transform: rotate(4deg);
              color: ${mainBlack};
              font-family: ${pText};
              font-size: 0.7rem;
              font-style: italic;
              margin-top: 15px;
              @media ${screen.small} {
                margin-top: 62px;
              }
              @media ${screen.medium} {
                margin-top: 15px;
              }
            }

            &--mobile {
              position: relative;
              @media ${screen.small} {
                display: none;
              }

              .nichols-img {
                max-width: 165px;
                width: 100%;
                transform: rotate(-8deg);

                .nichols-caption {
                  transform: rotate(8deg);
                  display: block;
                  color: ${mainBlack};
                  font-family: ${pTextLighter};
                  font-size: 0.55rem;
                  font-style: italic;
                  margin-top: 10px;
                }
              }

              .pointer-text {
                font-family: ${headingBold};
                font-size: 1.25rem;
                font-weight: 400;
                margin-top: 70px;
                margin-left: 70px;
                position: absolute;
                bottom: 35px;
                right: -14px;
                z-index: 2;
                max-width: 150px;

                &__arrow {
                  position: absolute;
                  width: 100%;
                  max-width: 75px;
                  left: -30px;
                  top: -54px;
                  transform: scaleY(-1) rotate(-70deg);
                  z-index: 2;
                }
              }
            }
          }

          .pointing-text {
            position: absolute;
            font-family: ${headingBold};
            font-size: 1.5rem;
            font-weight: 400;
            max-width: 250px;
            display: none;
            @media ${screen.small} {
              display: block;
              bottom: 400px;
              right: -20px;
            }
            @media ${screen.medium} {
              bottom: 225px;
              right: 20px;
            }
            @media ${screen.large} {
              right: -160px;
              bottom: 160px;
            }

            &__arrow {
              position: absolute;
              top: -90px;
              right: 0;
              max-width: 120px;
              width: 100%;
              transform: scaleX(-1) rotate(90deg);
            }
          }
        }

        .yr-40 {
          h6 {
            margin-bottom: 20px;
          }

          p {
            .tooltip {
              font-family: ${pTextBold};
              position: relative;
              display: inline-block;

              &__content {
                animation: ${enterTooltip} 0.4s ease-out forwards;
                font-family: ${pTextLighter};
                position: absolute;
                background: ${mainRed};
                display: block;
                color: ${mainWhite};
                padding: 10px 10px;
                font-size: 0.65rem;
                top: -118px;
                left: -30px;
                transform: scale(0);
                width: 195px;
                @media ${screen.small} {
                  font-size: 0.85rem;
                  width: 250px;
                  top: -170px;
                  left: auto;
                  padding: 10px 15px;
                }

                .title {
                  display: block;
                  font-family: ${pTextBold};
                }
              }

              &__arrow {
                display: block;
                width: 0;
                height: 0;
                border-left: 18px solid transparent;
                border-right: 18px solid transparent;
                border-top: 18px solid ${mainRed};
                position: absolute;
                bottom: -8px;
                left: 25px;
                transform: rotate(-90deg);
                @media ${screen.small} {
                  bottom: -8px;
                  left: -5px;
                }
              }

              &__close {
                position: absolute;
                right: 8px;
                top: 2px;
                cursor: pointer;
                font-family: sans-serif;
              }
            }
          }

          .p-lg {
            font-size: 1.35rem;
            font-family: ${pTextLighter};
            @media ${screen.small} {
              font-size: 1.7rem;
            }
          }

          .p-red {
            color: ${mainRed};
            font-family: ${headingBold};
            font-size: 1.7rem;
            display: flex;
            justify-content: flex-end;
            @media ${screen.small} {
              font-size: 2.2rem;
            }
          }
        }

        .yr-48 {
          position: relative;

          h6 {
            margin-bottom: 20px;
          }

          .p-red {
            color: ${mainRed};
            font-family: ${headingBold};
            font-size: 1.7rem;
            @media ${screen.small} {
              font-size: 2.2rem;
            }
          }
        }

        .yr-54 {
          h6 {
            margin-bottom: 20px;
          }
        }

        .yr-56 {
          .description {
            max-width: 800px;
          }

          h6 {
            margin-bottom: 20px;
            @media ${screen.medium} {
              max-width: 600px;
            }
            @media ${screen.large} {
              max-width: inherit;
            }
          }

          p {
            @media ${screen.medium} {
              max-width: 600px;
            }
            @media ${screen.large} {
              max-width: inherit;
            }
          }

          .p-quotes {
            font-family: ${headingBold};
            font-size: 1.5rem;
            @media ${screen.small} {
              margin-left: 45px;
              font-size: 1.9rem;
            }
          }

          .p-lg {
            font-size: 1.35rem;
            @media ${screen.small} {
              font-size: 1.7rem;
            }
          }

          .clarens-images {
            position: relative;
            height: 260px;
            margin-top: 40px;
            max-width: 750px;
            @media ${screen.small} {
              height: 390px;
              margin-top: 85px;
            }
            @media ${screen.medium} {
              margin-top: 50px;
            }
            @media ${screen.large} {
              margin-top: 0;
            }

            &__monument {
              max-width: 140px;
              width: 100%;
              position: absolute;
              transform: rotate(-7deg);
              z-index: 1;
              @media ${screen.small} {
                max-width: 250px;
              }
            }

            &__interior {
              max-width: 150px;
              width: 100%;
              position: absolute;
              left: 90px;
              top: 100px;
              z-index: 2;
              transform: rotate(-9deg);
              @media ${screen.small} {
                max-width: 260px;
                left: 160px;
                top: 150px;
              }
            }

            &__exterior {
              max-width: 160px;
              width: 100%;
              position: absolute;
              top: -10px;
              right: 0;
              transform: rotate(10deg);
              z-index: 1;
              @media ${screen.small} {
                max-width: 280px;
                right: 185px;
                top: -40px;
              }
              @media ${screen.medium} {
                right: 185px;
                top: -20px;
              }
              @media ${screen.large} {
                right: -40px;
                top: -40px;
              }
            }
          }

          .caption {
            display: block;
            color: ${mainBlack};
            font-family: ${pText};
            font-size: 0.7rem;
            font-style: italic;
            margin-left: 10px;
            margin-bottom: 40px;
            margin-top: -15px;
            @media ${screen.small} {
              margin-top: 0;
              margin-bottom: 60px;
            }
          }

          .p-history {
            max-width: 370px;
          }
        }

        .yr-63 {
          .description {
            max-width: 800px;
          }

          h6 {
            margin-bottom: 20px;
            @media ${screen.medium} {
              max-width: 600px;
            }
            @media ${screen.large} {
              max-width: inherit;
            }
          }

          p {
            @media ${screen.medium} {
              max-width: 600px;
            }
            @media ${screen.large} {
              max-width: inherit;
            }
          }
        }

        .yr-68 {
          @media ${screen.large} {
            margin-top: 500px;
            position: relative;
          }

          h6 {
            margin-bottom: 20px;
            max-width: 550px;
          }

          .history-monument-bg {
            position: absolute;
            width: 100%;
            bottom: -315px;
            right: 0;
            max-width: 240px;
            @media ${screen.small} {
              right: -35px;
              bottom: -360px;
              max-width: 380px;
            }
            @media ${screen.medium} {
              right: 0;
              bottom: -305px;
            }
            @media ${screen.large} {
              right: -220px;
            }

            .pointing-text {
              color: ${mainGreen};
              font-family: ${headingBold};
              font-size: 1.25rem;
              font-weight: 400;
              margin-top: 90px;
              margin-left: 10px;
              position: absolute;
              max-width: 400px;
              z-index: 2;
              top: 220px;
              left: -70px;
              @media ${screen.small} {
                font-size: 1.5rem;
                left: -320px;
                top: 200px;
              }

              &__arrow {
                position: absolute;
                width: 100%;
                max-width: 75px;
                transform: scale(-1) rotate(-45deg);
                right: 220px;
                top: -50px;
                @media ${screen.small} {
                  transform: scale(-1) rotate(-30deg);
                  right: 40px;
                  max-width: 120px;
                  top: -60px;
                }

                .stroke {
                  stroke: ${mainGreen};
                }
              }

              a {
                color: ${mainGrey};
                display: table;
                font-family: ${pTextBold};
                font-size: 1.05rem;
                text-decoration: underline;
                margin-top: 20px;
                @media ${screen.small} {
                  margin-left: 210px;
                  margin-top: 30px;
                  font-size: 1.2rem;
                }
              }
            }
          }
        }
      }
    }

    &__nsw-map-bg {
      max-width: 790px;
      width: 100%;
      position: absolute;
      bottom: 545px;
      padding-left: 20px;
      padding-right: 20px;
      display: none;
      @media ${screen.small} {
        max-width: 730px;
        bottom: 920px;
        left: 30px;
      }
      @media ${screen.medium} {
        left: 50px;
        bottom: 1310px;
        max-width: 710px;
      }
      @media ${screen.large} {
        display: block;
        max-width: 790px;
        bottom: 1110px;
        left: calc(50% + 250px);
        transform: translateX(-50%);
      }

      .caption {
        display: block;
        color: ${mainBlack};
        font-family: ${pText};
        font-size: 0.7rem;
        font-style: italic;
        margin-left: 35px;
        margin-top: 7px;
      }

      &--tablet {
        @media ${screen.large} {
          display: none;
        }

        .caption {
          display: block;
          color: ${mainBlack};
          font-family: ${pText};
          font-size: 0.7rem;
          font-style: italic;
          margin-left: 35px;
          margin-top: 7px;
        }
      }
    }
  }

  .accomplishments-row {
    position: relative;
    z-index: 2;
    padding-top: 45px;
    padding-bottom: 90px;
    padding-left: 30px;
    padding-right: 30px;
    background: ${mainWhite};
    @media ${screen.small} {
      margin-top: 0;
      padding-top: 90px;
      padding-left: 0;
      padding-right: 0;
    }
    @media ${screen.medium} {
      padding-bottom: 15px;
      margin-top: -400px;
    }
    @media ${screen.large} {
      padding-bottom: 90px;
    }

    &__rip-top-bg {
      position: absolute;
      top: -10px;
      width: 100%;
      display: none;
      @media ${screen.small} {
        top: -30px;
      }
      @media ${screen.medium} {
        top: -40px;
        display: block;
      }
      @media ${screen.large} {
        top: -50px;
      }
      @media ${screen.xLarge} {
        top: -70px;
      }
    }

    .accomplishments-row-content {
      max-width: ${innerWidth};
      margin: 0 auto;

      &__description {
        color: ${mainGreen};
        font-family: ${pText};
        font-size: 1.1rem;
        text-align: center;
        max-width: 560px;
        margin: 0 auto;
        @media ${screen.small} {
          font-size: 1.15rem;
        }
      }

      &__subheading {
        color: ${mainGreen};
        position: relative;
        font-family: ${headingBold};
        font-size: 1.7rem;
        display: table;
        margin: 20px auto 0 auto;
        text-align: center;
        z-index: 2;
        @media ${screen.small} {
          font-size: 2.2rem;
          text-align: left;
        }

        .arrow {
          position: absolute;
          width: 100%;
          max-width: 75px;
          transform: scale(-1) rotate(105deg);
          bottom: -34px;
          right: -5px;
          @media ${screen.small} {
            max-width: 120px;
            bottom: -54px;
            right: -55px;
            transform: scale(-1) rotate(110deg);
          }

          .stroke {
            stroke: ${mainGreen};
          }
        }
      }

      &__notes {
        position: relative;
        margin-top: 25px;
        z-index: 1;
        @media ${screen.small} {
          height: 710px;
          margin: 45px auto 0 auto;
          max-width: 830px;
        }
        @media ${screen.medium} {
          height: 600px;
          margin: 45px 0 0 0;
          max-width: inherit;
        }

        p {
          font-family: ${heading};
          font-size: 1.2rem;
          position: absolute;
          max-width: 220px;
          width: 100%;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          @media ${screen.small} {
            font-size: 1.45rem;
            max-width: 270px;
          }
          @media ${screen.medium} {
            font-size: 1.4rem;
            max-width: 230px;
          }
          @media ${screen.large} {
            font-size: 1.7rem;
            max-width: 300px;
          }
        }

        .note1 {
          position: relative;
          max-width: 280px;
          margin: 0 auto;
          width: 100%;
          z-index: 4;
          transform: rotate(3deg);
          @media ${screen.small} {
            position: absolute;
            max-width: 370px;
            left: 55px;
          }
          @media ${screen.medium} {
            max-width: 350px;
            left: 30px;
          }
          @media ${screen.large} {
            max-width: 400px;
            left: -50px;
          }
        }

        .note2 {
          position: relative;
          max-width: 390px;
          width: 100%;
          z-index: 1;
          transform: rotate(-3deg);
          @media ${screen.small} {
            position: absolute;
            max-width: 360px;
            left: 420px;
            top: -16px;
          }
          @media ${screen.medium} {
            max-width: 340px;
            left: 370px;
          }
          @media ${screen.large} {
            max-width: 390px;
            left: 345px;
          }
        }

        .note3 {
          position: relative;
          max-width: 450px;
          width: 100%;
          z-index: 3;
          transform: rotate(5deg);
          @media ${screen.small} {
            position: absolute;
            max-width: 390px;
            left: 40px;
            top: 230px;
          }
          @media ${screen.medium} {
            max-width: 400px;
            right: 0;
            top: 0;
            left: auto;
          }
          @media ${screen.large} {
            max-width: 450px;
            right: -72px;
          }
        }

        .note4 {
          position: relative;
          max-width: 450px;
          width: 100%;
          z-index: 3;
          transform: rotate(-5deg);
          @media ${screen.small} {
            position: absolute;
            top: 230px;
            left: 400px;
            max-width: 400px;
          }
          @media ${screen.medium} {
            max-width: 380px;
            left: 30px;
          }
          @media ${screen.large} {
            max-width: 450px;
            left: -120px;
          }

          p {
            font-size: 1.2rem;
            max-width: 220px;
            @media ${screen.small} {
              max-width: 260px;
              font-size: 1.45rem;
            }
            @media ${screen.medium} {
              max-width: 260px;
              font-size: 1.4rem;
            }
            @media ${screen.large} {
              max-width: 340px;
              font-size: 1.7rem;
            }
          }
        }

        .note5 {
          position: relative;
          max-width: 520px;
          width: 100%;
          z-index: 2;
          transform: rotate(2deg);
          @media ${screen.small} {
            position: absolute;
            max-width: 430px;
            left: 42px;
            top: 490px;
          }
          @media ${screen.medium} {
            max-width: 430px;
            left: 360px;
            top: 230px;
          }
          @media ${screen.large} {
            max-width: 520px;
            left: 300px;
          }

          p {
            font-size: 1.2rem;
            max-width: 220px;
            @media ${screen.small} {
              max-width: 330px;
              font-size: 1.45rem;
            }
            @media ${screen.medium} {
              max-width: 330px;
              font-size: 1.4rem;
            }
            @media ${screen.large} {
              max-width: 400px;
              font-size: 1.7rem;
            }
          }
        }

        .note6 {
          position: relative;
          max-width: 270px;
          width: 100%;
          z-index: 4;
          transform: rotate(-2deg);
          margin: 0 auto;
          @media ${screen.small} {
            position: absolute;
            left: 450px;
            top: 500px;
            max-width: 342px;
            margin: 0;
          }
          @media ${screen.medium} {
            max-width: 332px;
            right: 0;
            top: 230px;
            left: auto;
          }
          @media ${screen.large} {
            max-width: 380px;
            right: -92px;
          }
        }
      }
    }
  }

  .vision-row-desktop {
    display: none;
    @media ${screen.medium} {
      display: block;
    }
    .vision-row-desktop-content {
      display: flex;
      max-width: calc(${innerWidth} + 270px);
      margin: 90px auto 0 auto;
      padding: 0 135px;
      position: relative;
      z-index: 3;

      &::before {
        content: " ";
        background: ${lightGrey};
        border-radius: 20px;
        height: 370px;
        width: 100%;
        position: absolute;
        left: 50%;
        top: -50px;
        transform: translateX(-50%);
        @media ${screen.medium} {
          height: 410px;
        }
        @media ${screen.large} {
          height: 370px;
        }
      }

      &__col1 {
        width: 50%;
        position: relative;
        z-index: 2;

        .top-text {
          max-width: 600px;
          &__p1 {
            color: ${mainGreen};
            font-family: ${headingBold};
            font-size: 2.2rem;
          }

          &__p2 {
            font-family: ${heading};
            font-size: 1.7rem;
            margin: 26px 0;
          }
        }

        .bottom-text {
          margin-top: 150px;
          max-width: 550px;

          &__p1 {
            font-family: ${headingBold};
            font-size: 2.2rem;

            span {
              color: ${mainRed};
            }
          }

          &__p2 {
            font-size: 1.05rem;
            font-family: ${pTextLighter};
            margin-top: 26px;
            @media ${screen.small} {
              font-size: 1.125rem;
            }
          }
        }
      }

      &__col2 {
        width: 50%;
        margin-top: -100px;
        padding-left: 70px;

        .portrait-frame-img {
          max-width: 900px;
          margin-bottom: 30px;
          position: relative;
        }
      }

      .caption {
        display: block;
        color: ${mainBlack};
        font-family: ${pText};
        font-size: 0.7rem;
        font-style: italic;
        max-width: 350px;
        margin: 0 auto 10px auto;
        margin: 0 auto 10px auto;
        position: relative;
        @media ${screen.medium} {
          top: -16px;
          display: none;
        }
        @media ${screen.large} {
          top: auto;
          display: block;
        }

        &--tablet {
          display: none;
          @media ${screen.medium} {
            display: block;
            top: auto;
            max-width: 420px;
            margin: 0 0 10px 0;
          }
          @media ${screen.large} {
            display: none;
          }
        }
      }
    }
  }

  .vision-row-tablet {
    @media ${screen.medium} {
      display: none;
    }

    .vision-row-tablet-content {
      position: relative;
      padding-left: 30px;
      padding-right: 30px;

      @media ${screen.small} {
        padding-left: 45px;
        padding-right: 45px;
      }
      @media ${screen.medium} {
        padding-left: 60px;
        padding-right: 60px;
      }
      @media ${screen.large} {
        padding-left: 20px;
        padding-right: 20px;
      }
      @media ${screen.xLarge} {
        padding-left: 0;
        padding-right: 0;
      }

      &::before {
        content: " ";
        background: ${lightGrey};
        height: 700px;
        width: 100%;
        position: absolute;
        left: 50%;
        top: 0;
        padding-bottom: 10px;
        transform: translateX(-50%);
        @media ${screen.small} {
          height: 630px;
        }
      }

      &__top-description {
        position: relative;
        z-index: 2;
        padding-top: 45px;

        .p-black {
          color: ${mainBlack};
          font-family: ${heading};
          font-size: 1.3rem;
          margin-top: 16px;
          margin-bottom: 36px;
          max-width: 550px;
          @media ${screen.small} {
            font-size: 1.7rem;
            margin-top: 26px;
          }
        }

        .p-green {
          color: ${mainGreen};
          font-family: ${headingBold};
          font-size: 1.7rem;
          @media ${screen.small} {
            font-size: 2.2rem;
          }
        }
      }

      &__portrait {
        align-items: center;
        position: relative;
        z-index: 2;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        @media ${screen.small} {
          flex-direction: row;
        }

        .captions {
          order: 2;
          width: 100%;
          margin-top: 12px;
          @media ${screen.small} {
            margin-top: 0;
            width: 40%;
            order: 0;
          }

          p {
            color: ${mainBlack};
            font-family: ${pText};
            font-size: 0.7rem;
            font-style: italic;
            margin-bottom: 16px;
            @media ${screen.small} {
              margin-bottom: 20px;
            }
          }
        }

        .james-img {
          width: 100%;
          @media ${screen.small} {
            width: 60%;
          }

          img {
            width: 100%;
          }
        }
      }

      &__bottom-description {
        margin-top: 50px;
        @media ${screen.small} {
          margin-top: 10px;
        }

        .p-top {
          font-family: ${headingBold};
          font-size: 1.7rem;
          max-width: 650px;
          @media ${screen.small} {
            font-size: 2.2rem;
          }

          span {
            color: ${mainRed};
          }
        }

        .p-bottom {
          font-size: 1.05rem;
          font-family: ${pTextLighter};
          margin-top: 26px;
          max-width: 650px;
          @media ${screen.small} {
            font-size: 1.125rem;
          }
        }
      }
    }
  }

  .numantia-row {
    margin-top: 45px;
    background-image: url(${greenBg});
    padding: 55px 0 45px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
    @media ${screen.small} {
      padding: 160px 0 160px 0;
      margin-top: 90px;
    }
    @media ${screen.medium} {
      padding: 140px 0 140px 0;
    }
    @media ${screen.large} {
      background-attachment: fixed;
      padding: 160px 0 260px 0;
    }

    &__rip-top-bg {
      position: absolute;
      top: 0;
      width: 100%;
      transform: scaleX(-1);
    }

    &__rip-bottom-bg {
      position: absolute;
      bottom: -1px;
      width: 100%;
      transform: scaleX(-1);
    }

    .numantia-row-content {
      max-width: ${innerWidth};
      margin: 0 auto;
      position: relative;
      color: ${mainWhite};
      padding-left: 30px;
      padding-right: 30px;
      @media ${screen.small} {
        padding-left: 45px;
        padding-right: 45px;
      }
      @media ${screen.medium} {
        padding-left: 60px;
        padding-right: 60px;
      }
      @media ${screen.large} {
        padding-left: 20px;
        padding-right: 20px;
      }
      @media ${screen.xLarge} {
        padding-left: 0;
        padding-right: 0;
      }

      .description {
        @media ${screen.medium} {
          width: 50%;
        }

        &__heading {
          font-family: ${pTextLighter};
          margin-bottom: 20px;
          font-size: 1.35rem;
          @media ${screen.small} {
            font-size: 1.7rem;
            margin-bottom: 40px;
          }
        }

        &__subheading {
          font-family: ${headingBold};
          font-size: 1.7rem;
          margin-bottom: 26px;
          margin-top: 70px;
          @media ${screen.small} {
            font-size: 2.2rem;
          }
          @media ${screen.medium} {
            margin-top: 0;
          }
        }

        p {
          margin-bottom: 20px;
          font-size: 1.05rem;
          font-family: ${pTextLighter};
          @media ${screen.small} {
            font-size: 1.125rem;
          }
        }
      }

      &__numantia-img-bg {
        max-width: 740px;
        width: 100%;
        margin-top: 70px;
        @media ${screen.small} {
          margin-top: 0;
        }
        @media ${screen.medium} {
          position: absolute;
          top: 0;
          right: 0;
        }
        @media ${screen.large} {
          right: -250px;
        }

        .caption {
          display: block;
          font-family: ${pText};
          font-size: 0.7rem;
          font-style: italic;
          margin-left: 15px;
        }

        .numantia-top-img {
          position: relative;
          display: flex;
          align-items: flex-end;
          flex-direction: column;

          .img {
            max-width: 260px;
            width: 100%;
            @media ${screen.small} {
              max-width: 440px;
            }
            @media ${screen.medium} {
              max-width: 370px;
            }
            @media ${screen.large} {
              max-width: 440px;
            }
          }

          .pointing-text {
            position: absolute;
            top: -46px;
            left: 0;
            font-family: ${headingBold};
            font-size: 1.25rem;
            font-weight: 400;
            max-width: 150px;
            @media ${screen.small} {
              font-size: 1.5rem;
              top: 200px;
              left: 100px;
              max-width: 200px;
            }
            @media ${screen.medium} {
              left: 180px;
              top: 100px;
            }
            @media ${screen.large} {
              left: 100px;
              top: 200px;
            }

            &__arrow {
              position: absolute;
              display: block;
              max-width: 75px;
              width: 100%;
              transform: scaleX(-1) rotate(-25deg);
              top: 60px;
              right: 70px;
              @media ${screen.small} {
                max-width: 120px;
                top: 52px;
                right: -5px;
              }

              .stroke {
                stroke: ${mainWhite};
              }
            }
          }

          .caption {
            margin-left: 55px;
            margin-top: 5px;
            @media ${screen.small} {
              margin-right: 30px;
            }
          }
        }

        .numantia-bottom-img {
          margin-top: 20px;
          @media ${screen.small} {
            margin-top: 0;
          }
          @media ${screen.medium} {
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            margin-top: 12px;
          }
          @media ${screen.large} {
            display: block;
            margin-top: 0;
          }

          .img {
            max-width: 520px;
            width: 100%;
            @media ${screen.medium} {
              max-width: 470px;
            }
            @media ${screen.large} {
              max-width: 520px;
            }
          }

          .caption {
            margin-top: 5px;
            margin-left: 20px;
            @media ${screen.small} {
              margin-left: 50px;
            }
            @media ${screen.medium} {
              margin-right: 25px;
              margin-left: 0;
            }
            @media ${screen.large} {
              margin-left: 58px;
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  .audio-row {
    padding-top: 55px;
    padding-bottom: 100px;
    @media ${screen.small} {
      padding-top: 90px;
      padding-bottom: 120px;
    }

    .react-audio-player {
      display: none;
    }

    .caption {
      display: block;
      max-width: ${innerWidth};
      font-family: ${pText};
      font-size: 0.7rem;
      font-style: italic;
    }

    .audio-row-content {
      max-width: ${innerWidth};
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: relative;
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 860px;
      overflow: hidden;
      @media ${screen.small} {
        overflow: visible;
        flex-direction: row;
        padding-left: 45px;
        padding-right: 45px;
        padding-bottom: 848px;
        padding-top: 330px;
      }
      @media ${screen.medium} {
        padding-left: 60px;
        padding-right: 60px;
        padding-bottom: 0;
        padding-top: 0;
      }
      @media ${screen.large} {
        padding-left: 20px;
        padding-right: 20px;
      }
      @media ${screen.xLarge} {
        padding-left: 0;
        padding-right: 0;
      }

      &__description {
        margin-top: 45px;
        @media ${screen.medium} {
          width: 50%;
        }

        .heading {
          font-family: ${headingBold};
          font-size: 1.7rem;
          margin-bottom: 20px;
          @media ${screen.small} {
            font-size: 2.2rem;
            margin-bottom: 26px;
          }

          span {
            color: ${mainRed};
          }
        }

        p {
          font-size: 1.05rem;
          font-family: ${pTextLighter};
          margin-bottom: 20px;
          @media ${screen.small} {
            font-size: 1.125rem;
          }
        }

        .p-bold {
          font-family: ${pTextBold};
          font-size: 1.15rem;
          margin: 20px 0;
          @media ${screen.small} {
            margin: 40px 0;
          }
        }

        .p-green {
          color: ${mainGreen};
          font-family: ${headingBold};
          font-size: 1.7rem;
          margin: 20px 0;
          @media ${screen.small} {
            font-size: 2.2rem;
            margin: 40px 0;
          }
        }

        .martin-place-img {
          position: relative;
          @media ${screen.small} {
            height: 650px;
          }
          @media ${screen.medium} {
            height: 570px;
          }
          @media ${screen.large} {
            height: 430px;
          }

          .old-img {
            max-width: 400px;
            width: 100%;
            @media ${screen.small} {
              position: absolute;
              left: 0;
              top: 0;
            }
            @media ${screen.medium} {
              max-width: 370px;
              left: -30px;
              top: 0;
            }
            @media ${screen.large} {
              max-width: 400px;
            }

            .caption {
              max-width: 260px;
              margin-left: 10px;
              font-size: 0.7rem;
              @media ${screen.medium} {
                max-width: 190px;
              }
              @media ${screen.large} {
                max-width: 260px;
              }
            }
          }

          .new-img {
            max-width: 400px;
            width: 100%;
            margin-top: 14px;
            margin-bottom: 18px;
            @media ${screen.small} {
              position: absolute;
              right: 0;
              top: 275px;
              margin-top: 0;
              margin-bottom: 0;
            }
            @media ${screen.medium} {
              max-width: 370px;
              right: -40px;
              top: 220px;
            }
            @media ${screen.large} {
              max-width: 400px;
              right: -160px;
              top: 45px;
            }

            .caption {
              max-width: 300px;
              margin-left: 10px;
              font-size: 0.7rem;
              @media ${screen.medium} {
                max-width: 200px;
              }
              @media ${screen.large} {
                max-width: 300px;
              }
            }
          }
        }
      }
    }

    &__family-img {
      width: 100%;
      max-width: 450px;
      transform: rotate(1deg);
      @media ${screen.small} {
        transform: rotate(5deg);
        position: absolute;
        left: 60px;
        top: -16px;
      }
      @media ${screen.medium} {
        left: 0;
        top: -8px;
      }
      @media ${screen.large} {
        left: -35px;
      }

      .captions-container {
        margin-top: 12px;
        margin-left: 10px;
        @media ${screen.small} {
          margin-top: 0;
          margin-left: 0;
          position: absolute;
          right: -250px;
          bottom: 60px;
        }
        @media ${screen.medium} {
          position: static;
        }

        .caption {
          margin-top: 5px;
          transform: rotate(-1deg);
          @media ${screen.small} {
            transform: rotate(-5deg);
          }
          @media ${screen.medium} {
            margin-left: 7px;
          }
          @media ${screen.large} {
            margin-left: 0;
          }
        }
      }
    }

    &__audio-container {
      position: absolute;
      width: 560px;
      transform: rotate(-4deg) translateX(-50%);
      left: 50%;
      bottom: 0;
      @media ${screen.small} {
        width: 100%;
        max-width: 660px;
        top: auto;
        bottom: 0;
        left: 50%;
        transform: rotate(-4deg) translateX(-50%);
      }
      @media ${screen.medium} {
        top: 410px;
        bottom: auto;
        left: -140px;
        transform: rotate(-4deg);
      }
      @media ${screen.large} {
        top: 370px;
      }

      .audio {
        position: absolute;
        top: -100px;
        left: 80px;
        transform: rotate(4deg);
        @media ${screen.small} {
          top: 62px;
          transform: none;
          left: 100px;
        }

        &__heading {
          color: ${mainGreen};
          font-family: ${headingBold};
          font-size: 1.8rem;
          margin-left: 80px;
          @media ${screen.small} {
            font-size: 2.3rem;
            margin-left: 0;
          }
          @media ${screen.medium} {
            margin-left: 80px;
            font-size: 2.1rem;
          }
          @media ${screen.large} {
            margin-left: 0;
            font-size: 2.3rem;
          }
        }

        &__control {
          border: 3px ${mainGreen} solid;
          color: ${mainGreen};
          padding: 7px 30px 8px 20px;
          font-family: ${pTextBold};
          font-size: 1.05rem;
          position: relative;
          margin-top: 12px;
          margin-left: 80px;
          @media ${screen.small} {
            font-size: 1.15rem;
            margin-left: 0;
          }
          @media ${screen.medium} {
            margin-left: 80px;
          }
          @media ${screen.large} {
            margin-left: 0;
          }

          .icon {
            cursor: pointer;
            color: ${mainWhite};
            display: inline-block;
            background: ${mainGreen};
            position: absolute;
            font-size: 2.2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: calc(100% + 6px);
            top: 50%;
            right: -50px;
            transform: translateY(-50%);
            transition: ${transHover};

            &:hover {
              background: ${fadeGreen};
            }
          }
        }

        &__arrow {
          max-width: 75px;
          position: absolute;
          transform: rotate(75deg) scaleX(-1);
          bottom: -55px;
          left: 48px;
          @media ${screen.small} {
            max-width: 120px;
            bottom: -90px;
            left: -14px;
          }
          @media ${screen.medium} {
            left: -4px;
            bottom: -60px;
            transform: rotate(90deg) scaleX(-1);
          }
          @media ${screen.large} {
            left: -14px;
            bottom: -90px;
            transform: rotate(75deg) scaleX(-1);
          }

          .stroke {
            stroke: ${headerBlue};
          }
        }
      }

      .description {
        position: absolute;
        top: 75px;
        left: 170px;
        max-width: 500px;
        width: 100%;
        transform: rotate(-1deg);
        @media ${screen.small} {
          left: 200px;
          top: 192px;
          transform: rotate(-1deg);
        }

        h6 {
          font-family: ${heading};
          font-size: 1.7rem;
          margin-bottom: 20px;
        }

        p {
          font-family: ${heading};
          font-size: 1.04rem;
          margin-bottom: 15px;
        }
      }
    }
  }

  .post-script-row {
    background-image: url(${bottomBanner});
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100%;
    height: 650px;
    position: relative;
    @media ${screen.small} {
      background-position: center;
      background-size: cover;
    }
    @media ${screen.medium} {
      height: 650px;
    }
    @media ${screen.large} {
      height: 900px;
    }

    .post-script-row-content {
      background: ${lightGrey};
      width: 100%;
      position: absolute;
      right: 0;
      top: -70px;
      padding-top: 40px;
      padding-bottom: 20px;
      padding-left: 40px;
      padding-right: 40px;
      @media ${screen.small} {
        max-width: calc(80px + 50vw);
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 70px;
        padding-right: 0;
      }

      &__title {
        color: green;
        display: block;
        font-size: 1.6rem;
        margin-bottom: 20px;
        @media ${screen.small} {
          font-size: 2rem;
        }

        &--red {
          color: ${mainRed};
          font-family: ${headingBold};
          display: block;
        }

        &--black {
          color: ${mainBlack};
          margin-left: 12px;
          font-family: ${headingNormal};
          font-weight: 700;
        }
      }

      p {
        font-size: 1.05rem;
        font-family: ${pTextLighter};
        font-weight: 400;
        margin-bottom: 18px;
        max-width: 500px;
        @media ${screen.small} {
          font-size: 1.125rem;
        }
      }
    }
  }

  .bottom-quote-row {
    position: relative;
    z-index: 2;
    padding: 45px 0 115px 0;
    @media ${screen.small} {
      padding: 90px 0;
    }

    .bottom-quote-row-content {
      background: ${mainWhite};
      max-width: ${innerWidth};
      margin: 0 auto;
      padding-left: 30px;
      padding-right: 30px;
      @media ${screen.small} {
        padding-left: 45px;
        padding-right: 45px;
      }
      @media ${screen.medium} {
        padding-left: 60px;
        padding-right: 60px;
      }
      @media ${screen.large} {
        padding-left: 20px;
        padding-right: 20px;
      }
      @media ${screen.xLarge} {
        padding-left: 0;
        padding-right: 0;
      }

      p {
        font-family: ${heading};
        font-size: 1.7rem;
        max-width: 700px;
        @media ${screen.small} {
          font-size: 2.2rem;
        }
      }
    }

    &__rip-top {
      transform: scaleX(-1);
      position: absolute;
      top: -10px;
      width: 100%;
      @media ${screen.small} {
        top: -26px;
      }
      @media ${screen.medium} {
        top: -40px;
      }
      @media ${screen.large} {
        top: -50px;
      }
    }

    &__rip-bottom {
      transform: scaleX(-1);
      position: absolute;
      bottom: -10px;
      width: 100%;
      display: none;
      @media ${screen.small} {
        bottom: -26px;
        display: block;
      }
      @media ${screen.medium} {
        bottom: -42px;
      }
      @media ${screen.xLarge} {
        bottom: -56px;
      }
    }
  }

  .shadow {
    filter: drop-shadow(3px 0px 5px rgba(0, 0, 0, 0.3));
  }
`;

const JamesMartinPage = ({ data }) => {
  const [startDashedLine, setStartDashedLine] = useState(false);
  const [audioPlaying, setAudio] = useState(false);
  const [toolTip1, setToolTip1] = useState(false);
  const [toolTip2, setToolTip2] = useState(false);

  let audioControl;

  const playAudio = () => {
    audioControl.audioEl.current.play();
    setAudio(true);
  };

  const pauseAudio = () => {
    audioControl.audioEl.current.pause();
    setAudio(false);
  };

  const openToolTip1 = () => {
    setToolTip1(true);
  };

  const openToolTip2 = () => {
    setToolTip2(true);
  };

  const closeToolTip1 = () => {
    setToolTip1(false);
  };

  const closeToolTip2 = () => {
    setToolTip2(false);
  };

  return (
    <Layout>
      <SEO title="James Martin" description="The story of the third man" />
      <Wrapper>
        {/*================ START INTRO ================*/}
        <div className="intro-bg-wrapper">
          <img
            className="intro-bg-wrapper__james-img"
            src={jamesBanner}
            alt="James Martin Statue"
          />
          <div className="title-section">
            <div className="title-section__area">
              <div className="title-section__area__box">
                <h1>
                  <span className="first-line">The story of</span>
                  <span className="second-line">the Third Man</span>
                </h1>
              </div>
            </div>
          </div>

          <div className="introduction">
            <div className="introduction-content">
              <div className="introduction-content__subcontainer">
                <p className="introduction-content__p">
                  Hardly anyone knows the story of the third man. But what a
                  story it is.
                </p>
                <p className="introduction-content__p">
                  You probably know who William Charles Wentworth and Henry
                  Parkes were – Wentworth the leading advocate for
                  self-government for the colony, and Parkes the father of
                  Australian Federation.
                </p>
                <p className="introduction-content__p">
                  But in between, there was a man, mentored by Wentworth and
                  loved and admired by Parkes, a man who, with them, helped to
                  lay the foundations of Australian society and values.
                </p>
                <p className="introduction-content__p introduction-content__p--med">
                  Parkes named the very centre of Sydney after this man, and his
                  name is spoken thousands of times a day, but he himself
                  remains invisible and uncelebrated.
                </p>
                <p className="introduction-content__p introduction-content__p--lg">
                  It’s high time he was better known.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/*================ END INTRO ================*/}
        {/*================ START RED ROW ================*/}
        <div className="red-row">
          <img
            className="red-row__rip-top-bg"
            src={redRipTop}
            alt="Rip paper"
          />
          <img
            className="red-row__rip-bottom-bg"
            src={redRipBottom}
            alt="Rip paper"
          />
          <div className="red-row-content">
            <div className="red-row-content__img">
              <div className="shadow">
                <StaticImage
                  src="../images/james-martin/lysicrates-james-martin-castle-hyde.png"
                  alt="Castle Hyde"
                />
              </div>
              <span className="caption">
                Castle Hyde, County Cork, Ireland.
              </span>
              <span className="caption">
                Image Source: State Library of NSW, a1939062 / PXA 420, f.28a
              </span>
            </div>

            <div className="red-row-content__description">
              <h2 className="heading">James Martin</h2>
              <p>Born May 1820 near Castle Hyde, County Cork, Ireland.</p>
              <p>
                His father John, 24, a skilled horseman, was the respected
                steward of the Hyde family’s 12,000-acre estate.{" "}
              </p>
              <p>
                Not far from Castle Hyde stood the British army barracks at
                Fermoy, commanded by General Thomas Brisbane, a great horse
                lover. In late 1820, Brisbane had finally been offered the post
                he had coveted for years – Governor of New South Wales.{" "}
              </p>
              <p>
                Just after getting his offer, Brisbane visited Castle Hyde. He
                noted with interest young John Martin’s expertise with horses.
                In New South Wales he would need a horse groom. He offered the
                job to Martin, the steady, decent husband of a strong-minded,
                aspiring wife.
              </p>
              <h3 className="subheading">Horse groom!</h3>
              <p>
                Quite a step down socially, but John and Mary Martin now had
                baby James. Although New South Wales was still a penal colony,
                it was a new society, a land of opportunity, and opportunities,
                that little James could never have in Ireland. They accepted.
                Perhaps it was Mary who urged the move.
              </p>
              <p>
                Their voyage from Cork to Port Jackson, in the convict ship John
                Barry, took four months, during which nearly all the 180
                convicts suffered from seasickness.
              </p>
            </div>
          </div>
        </div>
        {/*================ END RED ROW ================*/}
        {/*================ START TOP STICKY  ================*/}
        <div className="sticky-row">
          <div className="sticky-row__paper-bg shadow">
            <StaticImage
              src="../images/james-martin/plain-paper.png"
              alt="James Martin Paper"
            />
          </div>

          <div className="sticky-row-content">
            <div className="sticky-left">
              <div className="sticky-left__img">
                <img src={jamesStatue} alt="James Martin Statue" />
              </div>
            </div>
            <div className="sticky-row-content__description">
              <div className="plain-description">
                <h2 className="plain-description__heading plain-description__heading--top">
                  <span className="plain-description__heading--red">
                    10 November 1821,
                  </span>{" "}
                  the day the John Barry finally reached the port of Sydney,
                  James was one and a half years old.
                </h2>
                <p className="plain-description--top">
                  The little family was immediately taken to the servants’
                  quarters next to the stables at Parramatta Government House,
                  where they would all live for the next 14 years. John would
                  spend those long years brushing horses and mucking out
                  stables, as a servant.
                </p>
                <p className="plain-description--top">
                  Little James started school in a tiny establishment run by an
                  ex-convict.
                </p>
                <p className="plain-description--top">
                  Very soon, it became clear that this was no ordinary child.
                  Bright, articulate, hard-working, the boy had a facility with
                  words, and a burning ambition, that set him apart from his
                  fellows.
                </p>
                <p className="plain-description--top">
                  By the time he was 12, it was obvious that this lad should go
                  on with his education. Quitting now, like nearly all his
                  school friends, would mean spending his life brushing horses
                  and mucking out stables like his father, all his potential
                  lost.
                </p>

                <div className="gov-house gov-house--desktop">
                  <Reveal effect="slide-right">
                    <div className="shadow">
                      <StaticImage
                        src="../images/james-martin/lysicrates-james-martin-govt-house.png"
                        alt="James Martin Government House"
                      />
                    </div>
                  </Reveal>
                  <p className="caption">
                    Image Source: State Library NSW, Painting by Charles Rodius
                  </p>
                  <Reveal effect="slide-r">
                    <p className="pointing-text">
                      Government House Parramatta, 1838
                      <span className="pointing-text__arrow">
                        <Arrow1 />
                      </span>
                    </p>
                  </Reveal>
                </div>

                <h2 className="plain-description__heading">
                  <span className="plain-description__heading--red">
                    In 1832,
                  </span>{" "}
                  there was no high school in Parramatta.
                </h2>
                <p>
                  Of course in Sydney, there were several. And the best one was
                  W. T. Cape’s Academy in King St, headed by the 26-year-old
                  William Timothy Cape, who would become the first headmaster of
                  Sydney College, the forerunner of Sydney Grammar.
                </p>
                <p>
                  The family took stock. There seemed only one way out. So that
                  James could go to the Cape school, John had to find a job in
                  Sydney. They were simply too poor to afford the regular
                  carriage fare. Time and again, John tried, but to no avail. No
                  job offers in the city, no success at all. The future looked
                  black for the brilliant boy.
                </p>
                <h6>
                  Until the boy himself spoke up. If his father couldn’t find a
                  job in the city, why, he told his parents, he would just walk.
                  The distance was only 13 miles.
                </h6>
                <div className="gov-house gov-house--mobile">
                  <div className="shadow">
                    <StaticImage
                      src="../images/james-martin/lysicrates-james-martin-govt-house.png"
                      alt="James Martin Government House"
                    />
                  </div>
                  <p className="caption">
                    Image Source: State Library NSW, Painting by Charles Rodius
                  </p>
                  <p className="pointing-text">
                    Government House Parramatta, 1838
                    <span className="pointing-text__arrow">
                      <Arrow1 />
                    </span>
                  </p>
                </div>
              </div>

              <div className="paper-description">
                <h4 className="paper-description__heading">
                  <span className="paper-description__heading--red">
                    In the 1950s,
                  </span>{" "}
                  Elena Grainger, Martin’s biographer got a charming scene,
                  directly from Martin’s last surviving daughter, Mary:
                </h4>
                <p>
                  Suddenly James pushed through the screening bushes, and ran up
                  the hill.
                </p>
                <p>His father had been to Sydney to look for work.</p>
                <p>Had he been successful?</p>
                <p>
                  Seething with excitement he rushed into the house. But no, his
                  dear simple, honest father and mother were grieved that never
                  a bit of high school would he see. No work in Sydney and the
                  coach fare too dear and all.
                </p>
                <p>But James was not discouraged, not in the least.</p>
                <p>
                  He would walk to school every day until the family moved to
                  Sydney. Yes, it was every bit of 13 miles and he was only 12
                  years old, but had they forgotten he’d not have the road to
                  himself? Bushrangers? But father, mother, what would a
                  bushranger want with books on algebra or arithmetic?
                </p>
                <p>And so it was agreed.</p>
              </div>

              <div className="map-description">
                <h5 className="map-description__heading map-description__heading--top">
                  Mary, along with her husband John Inman, founded Tudor House
                  School in Moss Vale. The Tudor House School is a private, day
                  and boarding, preparatory school for boys and girls.
                </h5>
                <h5 className="map-description__heading">
                  The school is Australia’s only preparatory boarding school and
                  became co-educational in 2017.
                </h5>
                <p>
                  For two years, till John did finally find work in Sydney, that
                  was just what the lad did.
                </p>
                <p>
                  He walked, he hitched rides, he slept overnight at school, and
                  he walked again. His parents made sacrifices to pay for his
                  schoolbooks and uniforms. His fees were paid by friends.
                </p>
                <Fade delay={200}>
                  <span className="map-description__red-p1">
                    And the boy walked
                  </span>
                </Fade>
                <Fade delay={600}>
                  <span className="map-description__red-p2">...and walked</span>
                </Fade>
                <Fade delay={1200}>
                  <span className="map-description__red-p3">...and walked</span>
                </Fade>

                <div className="map-description__house-bg shadow">
                  <StaticImage
                    src="../images/james-martin/lysicrates-james-martin-old-tudor-house.png"
                    alt="James Martin Tudor House"
                  />
                </div>
                <Arrow1 className="map-description__arrow" />
              </div>
            </div>
          </div>
          <div className="map-container">
            <img src={map} alt="James Maritin Map" />
            <Waypoint onEnter={setStartDashedLine}>
              <div>
                {startDashedLine && (
                  <div className="map-container__dashed-line">
                    <p className="start-text">Parramatta</p>
                    <DashedLine />
                    <p className="end-text">Sydney</p>
                  </div>
                )}
              </div>
            </Waypoint>
          </div>
        </div>
        {/*================ END TOP STICKY  ================*/}
        {/*================ START HENRY PARKES ROW ================*/}
        <div className="henry-parkes-row">
          <img
            className="henry-parkes-row__rip-top-bg"
            src={whiteRipTop}
            alt="Rip paper"
          />

          <div className="henry-parkes-row-content">
            <div className="henry-parkes-row-content__col1">
              <Fade>
                <div className="images">
                  <div className="images__painting shadow">
                    <StaticImage
                      src="../images/james-martin/henry-parkes-painting.png"
                      alt="Henry"
                    />
                  </div>
                  <div className="images__portrait shadow">
                    <StaticImage
                      src="../images/james-martin/henry-parkes-portrait.png"
                      alt="Henry"
                    />
                  </div>
                </div>
              </Fade>
              <p>Sir Henry Parkes (1815-1896), Politician and Journalist.</p>
              <p>
                Image Source: Painting from State Library of NSW, c. 1887 / P.
                Spence;
                <br />
                Portrait from National Library of Australia, obj.136671812.
              </p>
            </div>
            <div className="henry-parkes-row-content__col2">
              <p className="p1">
                Henry Parkes, deeply moved on the day of James Martin’s funeral,
                wrote a poem about those early struggles of his friend. Here is
                one of its verses:
              </p>
              <Fade>
                <div className="signature">
                  <div className="shadow">
                    <StaticImage
                      src="../images/james-martin/henry-parkes-signature.png"
                      alt="Signature"
                    />
                  </div>
                  <p>
                    How bravely did the stripling climb
                    <br />
                    From step to step the rugged hill
                    <br />
                    His gaze thro’ that benighted time
                    <br />
                    Fix’d on the far-off beacon still.
                  </p>
                </div>
              </Fade>
              <p className="p2">
                Somehow, through all of these struggles, perhaps because of
                them, he shone at W. T. Cape’s School. His facility with words,
                his curiosity, his drive, all flowered. His brilliant promise
                began to be fulfilled. He learned Latin and Greek, and began his
                lifelong love of the classics.
              </p>
              <p className="p3">
                His school essays were scintillating, personal, eloquent.
              </p>
            </div>
          </div>
        </div>
        {/*================ END HENRY PARKES ROW ================*/}
        {/*================ START SCHOOL ROW ================*/}
        <div className="school-row">
          <img
            className="school-row__rip-top-bg"
            src={whiteRipBottom}
            alt="Rip paper"
          />
          <img
            className="school-row__rip-bottom-bg"
            src={greyRipTop}
            alt="Rip paper"
          />
          <div className="school-row-wrapper">
            <div className="school-row-content">
              <h6>
                <span>In 1834,</span> Cape’s School and Sydney College in
                College Street merged, later on becoming Sydney Grammar.
              </h6>
              <p>
                Despite a need to expand its facilities in the nineteenth and
                twentieth centuries, Sydney Grammar School has remained on its
                original site in College Street, Darlinghurst, although it
                maintains preparatory schools in Edgecliff and St Ives. Image
                Source: State Library of NSW, a1939062 / PXA 420, f.28a
              </p>
            </div>
            <div className="school-row-content__school-bg">
              <Reveal effect="slide-right">
                <StaticImage
                  src="../images/james-martin/lysicrates-james-martin-school.png"
                  alt="James Martin School"
                />
              </Reveal>
              <p>
                Despite a need to expand its facilities in the nineteenth and
                twentieth centuries, Sydney Grammar School has remained on its
                original site in College Street, Darlinghurst, although it
                maintains preparatory schools in Edgecliff and St Ives. Image
                Source: State Library of NSW, a1939062 / PXA 420, f.28a
              </p>
            </div>
          </div>
        </div>
        {/*================ END SCHOOL ROW ================*/}
        {/*================ START STICKY NUMBER ROW ================*/}
        <div className="sticky-num">
          <div className="sticky-num-content">
            <div className="sticky-left">
              <div className="sticky-left__num">18</div>
            </div>
            <div className="content">
              <Waypoint onEnter={openToolTip1} bottomOffset={600}>
                <Fade>
                  <div className="content__each-year yr-38" id="1838">
                    <div className="year year--first">
                      <span>38</span>
                    </div>

                    <div className="description">
                      <div className="year--tablet">
                        <span>1838</span>
                      </div>
                      <h6>
                        While still at school, 18 year-old James started writing
                        articles in The Australian.
                      </h6>
                      <p>
                        This was an{" "}
                        <span className="tooltip">
                          <span
                            onClick={openToolTip1}
                            style={{ cursor: `pointer` }}
                          >
                            annus mirabilis
                          </span>
                          {toolTip1 && (
                            <span className="tooltip__content shadow">
                              <span className="title">
                                annus mirabilis (n);
                              </span>
                              <br /> a remarkable or notable year
                              <span className="tooltip__arrow"></span>
                              <span
                                className="tooltip__close"
                                onClick={closeToolTip1}
                              >
                                x
                              </span>
                            </span>
                          )}
                        </span>{" "}
                        for James, publishing both The Australian Sketchbook (we
                        can smile at the youthful confidence of that “The”), and
                        a bitter article on “the ignorance of the Molesworth
                        committee on convict transportation”.
                      </p>
                      <p>
                        Already the lines of his future passions were emerging:
                        an ardent patriotism, fierce support for the
                        self-government of the colony, a wish to see it grow
                        stronger and more independent, and impatience with
                        sycophants, blockheads and snobs.
                      </p>
                      <p className="pointing-text">
                        A book of miscellaneous
                        <br />
                        essays on the young colony
                        <span className="pointing-text__arrow">
                          <Arrow1 />
                        </span>
                      </p>
                      <span className="caption">
                        Image Source: National Library of Australia
                      </span>
                    </div>

                    <div className="yr-38__book-bg">
                      <Reveal effect="slide-right">
                        <div className="shadow">
                          <StaticImage
                            src="../images/james-martin/lysicrates-james-martin-sketchbook.png"
                            alt="James Martin book"
                          />
                        </div>
                      </Reveal>
                    </div>
                    <div className="yr-38__book-bg--mobile">
                      <p className="pointer-text">
                        A book of miscellaneous essays on the young colony
                        <span className="pointer-text__arrow">
                          <Arrow1 />
                        </span>
                      </p>
                      <div className="book-img">
                        <div className="shadow">
                          <StaticImage
                            src="../images/james-martin/lysicrates-james-martin-sketchbook.png"
                            alt="James Martin book"
                          />
                        </div>
                        <span className="book-caption">
                          Image Source: National Library of Australia
                        </span>
                      </div>
                    </div>
                  </div>
                </Fade>
              </Waypoint>
              <Fade>
                <div className="content__each-year yr-39" id="1839">
                  <SpyLink className="year" to="1839" spy={true} offset={-120}>
                    <span>39</span>
                  </SpyLink>
                  <div className="description">
                    <div className="year--tablet">
                      <span>1839</span>
                    </div>
                    <h6>
                      By the age of 19, he was the Acting Editor of The
                      Australian.
                    </h6>
                    <p>
                      A feared crusader, he was mentored by the editor, the
                      legendary G R Nichols, a lawyer who would soon change the
                      course of Martin’s life.
                    </p>
                    <p>
                      G R Nichols had established a law firm in the city (it
                      became the present-day Clayton Utz).
                    </p>
                    <p>Ambition swelled in Martin.</p>
                    <p>
                      He knew quite well that he could be more than a
                      journalist. A poor Irish boy facing snobbery every day, he
                      wanted a qualification and a standing that would prove his
                      capacities beyond doubt.
                    </p>
                  </div>
                  <div className="yr-39__nichols-bg">
                    <Reveal effect="slide-left">
                      <div className="shadow">
                        <StaticImage
                          src="../images/james-martin/lysicrates-james-martin-gr-nichols.png"
                          alt="James Martin Nichols"
                        />
                      </div>
                    </Reveal>
                    <span className="caption">
                      Image Source: State Library NSW
                    </span>
                  </div>
                  <div className="yr-39__nichols-bg--mobile">
                    <p className="pointer-text">
                      G R Nichols was James Martin’s mentor
                      <span className="pointer-text__arrow">
                        <Arrow1 />
                      </span>
                    </p>
                    <div className="nichols-img">
                      <div className="shadow">
                        <StaticImage
                          src="../images/james-martin/lysicrates-james-martin-gr-nichols.png"
                          alt="James Martin Nichols"
                        />
                      </div>
                      <span className="nichols-caption">
                        Image Source: State Library NSW
                      </span>
                    </div>
                  </div>
                  <p className="pointing-text">
                    G R Nichols was James Martin’s mentor
                    <span className="pointing-text__arrow">
                      <Arrow1 />
                    </span>
                  </p>
                </div>
              </Fade>

              <Waypoint onEnter={openToolTip2} bottomOffset={600}>
                <Fade>
                  <div className="content__each-year yr-40" id="1840">
                    <SpyLink
                      className="year"
                      to="1840"
                      spy={true}
                      offset={-120}
                    >
                      <span>40</span>
                    </SpyLink>
                    <div className="description">
                      <div className="year--tablet">
                        <span>1840</span>
                      </div>
                      <h6>
                        At 20, Martin was articled to Nichols in his chambers.
                      </h6>
                      <p>
                        Martin was the most brilliant member of his graduating
                        year, and was admitted as a solicitor.
                      </p>
                      <p>
                        He would go on to become Australia’s first
                        locally-trained{" "}
                        <span className="tooltip">
                          <span
                            onClick={openToolTip2}
                            style={{ cursor: `pointer` }}
                          >
                            QC
                          </span>
                          {toolTip2 && (
                            <span className="tooltip__content shadow">
                              <span className="title">
                                Queen’s Counsel (QC);
                              </span>{" "}
                              The title of QC is a very old term which connotes
                              great respect within the legal profession. It is
                              the highest level of professional recognition that
                              a barrister can reach.
                              <span className="tooltip__arrow"></span>
                              <span
                                className="tooltip__close"
                                onClick={closeToolTip2}
                              >
                                x
                              </span>
                            </span>
                          )}
                        </span>
                        , a wealthy, successful barrister with rooms in
                        Parramatta and Sydney by the time he was 40.
                      </p>
                      <p>
                        But now in his twenties, the struggle was still intense.
                      </p>
                      <p>
                        While practising law, he never stopped writing articles
                        in the press, or lost his passionate interest in the
                        constitution and the political development of the
                        colony.
                      </p>
                      <p className="p-lg">
                        Offence was often taken at opinions and his often
                        combative style, but they were never counterfeit or
                        trimmed…
                      </p>
                      <p className="p-red">…far from it.</p>
                    </div>
                  </div>
                </Fade>
              </Waypoint>
              <Fade>
                <div className="content__each-year yr-48" id="1848">
                  <SpyLink className="year" to="1848" spy={true} offset={-120}>
                    <span>48</span>
                  </SpyLink>
                  <div className="description">
                    <div className="year--tablet">
                      <span>1848</span>
                    </div>
                    <h6>
                      Now he knew he could be more than a journalist and a
                      lawyer.
                    </h6>
                    <p>
                      At the age of 28, he went into politics. Backed by
                      Wentworth, he won the Legislative Council seat of Cook and
                      Westmoreland.
                    </p>
                    <p>
                      A year later, he was unseated, purportedly because he
                      lacked the necessary property qualification.
                    </p>
                    <p className="p-red">Not enough money, Mr Martin!</p>
                    <p>That would have stung any poor, ambitious Irish boy.</p>
                  </div>
                </div>
              </Fade>
              <Fade>
                <div className="content__each-year yr-54" id="1854">
                  <SpyLink className="year" to="1854" spy={true} offset={-120}>
                    <span>54</span>
                  </SpyLink>
                  <div className="description">
                    <div className="year--tablet">
                      <span>1854</span>
                    </div>
                    <h6>
                      At the age of 34, he further offended polite society by
                      marrying Isabella Long.
                    </h6>
                    <p>
                      Isabella was the daughter of an ex-convict who had become
                      wealthy in the most reliable way in the infant colony –
                      through the liquor trade. They went on to have 16
                      children.
                    </p>
                  </div>
                </div>
              </Fade>
              <Fade>
                <div className="content__each-year yr-56" id="1856">
                  <SpyLink className="year" to="1856" spy={true} offset={-120}>
                    <span>56</span>
                  </SpyLink>
                  <div className="description">
                    <div className="year--tablet">
                      <span>1856</span>
                    </div>
                    <h6>
                      He was able to regain his seat the next year, and in
                      August 1856.
                    </h6>
                    <p>
                      At 36 years old, he became Attorney General of New South
                      Wales, but the government fell soon after.
                    </p>
                    <p>
                      At the time, Philip Gidley King, a wealthy pastoralist,
                      son of a naval captain of impeccably English stock, wrote
                      about Martin:
                    </p>
                    <p className="p-quotes">
                      “What a vulgar fellow [he] is.
                      <br />
                      I should think no decent person
                      <br />
                      would ever cross his threshold
                      <br />
                      again or send their cards!”
                    </p>
                    <p>
                      Martin was blackballed three times from the Australian
                      Club while King was its president. And the wife of
                      Governor Hercules Robinson refused to have him in her
                      genteel, exclusive salon.
                    </p>
                    <p className="p-lg">
                      Martin’s abilities and passion carried him through all the
                      slights, the snobbery and the jealousies.
                    </p>
                    <p className="p-lg">His star continued to rise.</p>
                    <Reveal effect="slide-right">
                      <div className="clarens-images">
                        <div className="clarens-images__monument shadow">
                          <StaticImage
                            src="../images/james-martin/lysicrates-james-martin-clarens-house-monument.png"
                            alt="Clarens House Monument"
                          />
                        </div>
                        <div className="clarens-images__interior shadow">
                          <StaticImage
                            src="../images/james-martin/lysicrates-james-martin-clarens-house-interior.png"
                            alt="Clarens House Interior"
                          />
                        </div>
                        <div className="clarens-images__exterior shadow">
                          <StaticImage
                            src="../images/james-martin/lysicrates-james-martin-clarens-house-exterior.png"
                            alt="Clarens House Exterior"
                          />
                        </div>
                      </div>
                    </Reveal>
                    <span className="caption">
                      Image Source: dictionaryofsydney.org
                    </span>
                    <p className="p-history">
                      He bought Clarens, a fine house on the site of the
                      present-day HMAS Kuttabul in Potts Point.
                    </p>
                  </div>
                </div>
              </Fade>
              <Fade>
                <div className="content__each-year yr-63" id="1863">
                  <SpyLink className="year" to="1863" spy={true} offset={-120}>
                    <span>63</span>
                  </SpyLink>
                  <div className="description">
                    <div className="year--tablet">
                      <span>1863</span>
                    </div>
                    <h6>
                      In October 1863 he became Premier of New South Wales for
                      the first time.
                    </h6>
                    <p>
                      His government fell in 1865 but he became Premier twice
                      more, ending in 1872.
                    </p>
                    <div className="sticky-num__nsw-map-bg--tablet">
                      <div className="shadow">
                        <StaticImage
                          src="../images/james-martin/lysicrates-james-martin-nsw-map.png"
                          alt="James Martin NSW Map"
                        />
                      </div>
                      <span className="caption">
                        Image Source: dictionaryofsydney.org
                      </span>
                    </div>
                  </div>
                </div>
              </Fade>
              <Fade>
                <div className="content__each-year yr-68" id="1868">
                  <SpyLink className="year" to="1868" spy={true} offset={-120}>
                    <span>68</span>
                  </SpyLink>
                  <div className="description">
                    <div className="year--tablet">
                      <span>1868</span>
                    </div>
                    <h6>
                      In 1868, while he was living in “Clarens” in Potts Point
                    </h6>
                    <p>
                      Martin also commissioned a lovely sandstone copy of
                      <br />
                      the Lysicrates Monument built in 334 B.C. in Athens.
                    </p>
                  </div>
                  <div className="history-monument-bg">
                    <Reveal effect="slide-right">
                      <div className="shadow">
                        <StaticImage
                          src="../images/james-martin/lysicrates-history-monument.png"
                          alt="Lysicrates Monument"
                        />
                      </div>
                    </Reveal>
                    <p className="pointing-text">
                      Today the copy is standing in Sydney’s Royal Botanic
                      Garden.
                      <span className="pointing-text__arrow">
                        <Arrow1 />
                      </span>
                      <Link to="/history">Learn more</Link>
                    </p>
                  </div>
                </div>
              </Fade>
            </div>
          </div>

          <div className="sticky-num__nsw-map-bg">
            <Reveal effect="slide-left">
              <div className="shadow">
                <StaticImage
                  src="../images/james-martin/lysicrates-james-martin-nsw-map.png"
                  alt="James Martin NSW Map"
                />
              </div>
            </Reveal>
            <span className="caption">
              Image Source: dictionaryofsydney.org
            </span>
          </div>
        </div>
        {/*================ END STICKY NUMBER ROW ================*/}
        {/*================ START ACCOMPLISHMENTS ROW ================*/}
        <div className="accomplishments-row">
          <img
            className="accomplishments-row__rip-top-bg"
            src={greyRipBottom}
            alt="Rip paper"
          />
          <div className="accomplishments-row-content">
            <p className="accomplishments-row-content__description">
              Throughout all his time in office, Martin’s over-riding passion
              for the independence of the colony remained strong.
            </p>
            <p className="accomplishments-row-content__subheading">
              Some of James Martin’s accomplishments include
              <span className="arrow">
                <Arrow1 />
              </span>
            </p>
            <Fade>
              <div className="accomplishments-row-content__notes">
                <div className="note1 shadow">
                  <StaticImage
                    src="../images/james-martin/lysicrates-james-martin-old-paper1.png"
                    alt="Old paper"
                  />
                  <p>He spent years evolving a constitution for the colony.</p>
                </div>
                <div className="note2 shadow">
                  <StaticImage
                    src="../images/james-martin/lysicrates-james-martin-old-paper3.png"
                    alt="Old paper"
                  />
                  <p>
                    He created the Mint, so that the colony would not have to
                    depend on English coinage.
                  </p>
                </div>
                <div className="note3 shadow">
                  <StaticImage
                    src="../images/james-martin/lysicrates-james-martin-old-paper1.png"
                    alt="Old paper"
                  />
                  <p>
                    He was a fervent advocate of nurturing New South Wales’
                    infant industries against the might of English competition.
                  </p>
                </div>
                <div className="note4 shadow">
                  <StaticImage
                    src="../images/james-martin/lysicrates-james-martin-old-paper3.png"
                    alt="Old paper"
                  />
                  <p>
                    He created programs to train for useful professions the
                    street urchins running wild in the streets, by introducing
                    the Industrial Schools Act.
                  </p>
                </div>
                <div className="note5 shadow">
                  <StaticImage
                    src="../images/james-martin/lysicrates-james-martin-old-paper2.png"
                    alt="Old paper"
                  />
                  <p>
                    With Henry Parkes, James Martin oversaw the introduction of
                    the Public Schools Act of 1866 giving greater authority to
                    the new Council of Education.
                  </p>
                </div>
                <div className="note6 shadow">
                  <StaticImage
                    src="../images/james-martin/lysicrates-james-martin-old-paper4.png"
                    alt="Old paper"
                  />
                  <p>He helped found Sydney University.</p>
                </div>
              </div>
            </Fade>
          </div>
        </div>
        {/*================ END ACCOMPLISHMENTS ROW ================*/}
        {/*================ START VISION ROW ================*/}
        <div className="vision-row-desktop">
          <div className="vision-row-desktop-content">
            <div className="vision-row-desktop-content__col1">
              <div className="top-text">
                <p className="top-text__p1">His vision for the new country –</p>
                <p className="top-text__p2">
                  strong, with healthy and well-trained youth, growing
                  industries, a literate people, and political independence from
                  the apron strings of the English governess.
                </p>
                <span className="caption caption--tablet">
                  Sir James Martin, fourth Chief Justice of NSW (1873-1886) in
                  W. F. Morrison, The Aldine Centennial History of New South
                  Wales, Illustrated (Syndey 1888).
                </span>
                <span className="caption caption--tablet">
                  Image Source: Inaugural Martin Oration 2016. Distributed by
                  The Lysicrates Foundation.
                </span>
              </div>
              <div className="bottom-text">
                <p className="bottom-text__p1">
                  <span>In 1873,</span> he was appointed Chief Justice of NSW by
                  Parkes, who was then the Premier.
                </p>
                <p className="bottom-text__p2">
                  Martin is the only person in the history of the state to have
                  occupied all three roles – Attorney General, Premier, and
                  Chief Justice.
                </p>
              </div>
            </div>
            <div className="vision-row-desktop-content__col2">
              <div className="portrait-frame-img">
                <Reveal effect="slide-right">
                  <div className="shadow">
                    <StaticImage
                      src="../images/james-martin/portrait-frame.png"
                      alt="James Martin Portrait Frame"
                    />
                  </div>
                </Reveal>
              </div>
              <span className="caption">
                Sir James Martin, fourth Chief Justice of NSW (1873-1886) in W.
                F. Morrison, The Aldine Centennial History of New South Wales,
                Illustrated (Syndey 1888).
              </span>
              <span className="caption">
                Image Source: Inaugural Martin Oration 2016. Distributed by The
                Lysicrates Foundation.
              </span>
            </div>
          </div>
        </div>
        <div className="vision-row-tablet">
          <div className="vision-row-tablet-content">
            <div className="vision-row-tablet-content__top-description">
              <p className="p-green">His vision for the new country –</p>
              <p className="p-black">
                strong, with healthy and well-trained youth, growing industries,
                a literate people, and political independence from the English
                apron strings –
              </p>
            </div>

            <div className="vision-row-tablet-content__portrait">
              <div className="captions">
                <p>
                  Sir James Martin, fourth Chief Justice of NSW (1873-1886) in
                  W. F. Morrison, The Aldine Centennial History of New South
                  Wales, Illustrated (Syndey 1888).
                </p>
                <p>
                  Image Source: Inaugural Martin Oration 2016. Distributed by
                  The Lysicrates Foundation.
                </p>
              </div>
              <div className="james-img shadow">
                <StaticImage
                  src="../images/james-martin/portrait-frame.png"
                  alt="James Martin Portrait Frame"
                />
              </div>
            </div>

            <div className="vision-row-tablet-content__bottom-description">
              <p className="p-top">
                <span>In 1873,</span> he was appointed Chief Justice of NSW by
                Parkes, who was then the Premier.
              </p>
              <p className="p-bottom">
                Martin is the only person in the history of the state to have
                occupied all three roles – Attorney General, Premier, and Chief
                Justice.
              </p>
            </div>
          </div>
        </div>
        {/*================ END VISION ROW ================*/}
        {/*================ START NUMANTIA ROW ================*/}
        <div className="numantia-row">
          <img
            className="numantia-row__rip-top-bg"
            src={whiteRipBottom}
            alt="Rip paper"
          />
          <img
            className="numantia-row__rip-bottom-bg"
            src={whiteRipTop}
            alt="Rip paper"
          />
          <div className="numantia-row-content">
            <div className="description">
              <h6 className="description__heading">
                In the Blue Mountains, he built a simple weatherboard family
                weekender which he called “Numantia”.
              </h6>
              <div className="numantia-row-content__numantia-img-bg">
                <div className="numantia-top-img">
                  <div className="img shadow">
                    <Reveal effect="slide-right">
                      <StaticImage
                        src="../images/james-martin/numantia1.png"
                        alt="Numantia"
                      />
                    </Reveal>
                  </div>
                  <p className="pointing-text">
                    James Martin’s Numantia
                    <span className="pointing-text__arrow">
                      <Arrow1 />
                    </span>
                  </p>
                  <span className="caption">
                    Photograph of James Martin’s Numantia in the Blue Mountains.
                  </span>
                  <span className="caption">
                    Image Source: sorianitelaimaginas.com/en/patrimonio/numantia
                  </span>
                </div>
                <div className="numantia-bottom-img">
                  <div className="img shadow">
                    <Reveal effect="slide-left">
                      <StaticImage
                        src="../images/james-martin/numantia2.png"
                        alt="Numantia"
                      />
                    </Reveal>
                  </div>
                  <span className="caption">
                    Birdseye view of the Numantia ruins in the municipality of
                    Garray
                  </span>
                  <span className="caption">
                    Image Source: sorianitelaimaginas.com/en/patrimonio/numantia
                  </span>
                </div>
              </div>
              <h6 className="description__subheading">Why Numantia?</h6>
              <p>The name is a clue to Martin’s most deeply held opinions.</p>
              <p>
                Numantia was a hill town in Spain. In 133 B. C., after a
                two-year siege, it fell to the invading Roman army. But the
                Celtic inhabitants refused to accept Roman rule. Except for the
                last man, they all committed suicide. The last man stood on the
                battlements and cried down to the Roman commander, “You can
                enter the city now”, and then committed suicide himself.
              </p>
              <h6 className="description__heading">
                It does not need much imagination to see why the story would
                appeal to an Irish-born, Australian-bred man.{" "}
              </h6>
              <p>
                Martin’s neighbour in the mountains was Henry Parkes, whose
                house today is still where it was then. They would often travel
                up together, and sit on the verandah in the sunset sipping their
                drinks and talking about politics, Federation and the
                development of Australia.
              </p>
            </div>
          </div>
        </div>
        {/*================ END NUMANTIA ROW ================*/}
        {/*================ START AUDIO ROW ================*/}
        <div className="audio-row">
          <h2>
            <ReactAudioPlayer
              src={audio}
              controls
              ref={element => {
                audioControl = element;
              }}
            />
          </h2>

          <div className="audio-row-content">
            <div className="audio-row__family-img">
              <Reveal effect="slide-left">
                <div className="shadow">
                  <StaticImage
                    src="../images/james-martin/lysicrates-james-martin-family.png"
                    alt="James Martin Family"
                  />
                </div>
              </Reveal>

              <div className="captions-container">
                <span className="caption">
                  James and Isabella Martin and family at Clarens,
                </span>
                <span className="caption">Parramatta Heritage Centre</span>
                <span className="caption">
                  Potts Point, Sydney, around 1860. Image Source:
                </span>
              </div>
            </div>

            <div className="audio-row-content__description">
              <h6 className="heading">
                <span>In 1882,</span> Isabella left Martin.
              </h6>
              <p>
                For some time she had been objecting to what she believed to be
                the unhealthy air around Clarens, and had attributed to that the
                death of a favourite daughter. She moved to Vaucluse, then to
                Point Piper. The beach near her house is now called Lady
                Martin’s Beach.
              </p>
              <p>
                Martin was deeply shocked. No longer a stripling of 12, he
                himself was unable to move so far away from his demanding legal
                work in the city. From then on, the man who had loved being
                surrounded by children, had laughed with them and cosseted them,
                lived mostly alone.
              </p>
              <p>Just four years later, at only 66, James Martin died.</p>
              <p>
                On the day of his funeral, Henry Parkes, full of emotion,
                sitting in his office in Parliament House, wrote the fitting
                tribute, The Buried Chief.
              </p>
              <p className="p-bold">
                Still, writing a poem, Parkes felt, was not enough.
              </p>
              <div className="martin-place-img">
                <div className="old-img">
                  <Reveal effect="slide-left">
                    <div className="shadow">
                      <StaticImage
                        src="../images/james-martin/martin-place-old.png"
                        alt="Martin Place"
                      />
                    </div>
                  </Reveal>
                  <span className="caption">
                    Published by the Sydney firm Charles Kerry & Co. Image
                    Source: The Museum of Applied Arts and Sciences
                  </span>
                </div>
                <div className="new-img">
                  <Reveal effect="slide-right">
                    <div className="shadow">
                      <StaticImage
                        src="../images/james-martin/martin-place-new.png"
                        alt="Martin Place"
                      />
                    </div>
                  </Reveal>
                  <span className="caption">
                    Recent photograph of Martin Place. Image Source: Ultimately
                    Sydney Website
                  </span>
                </div>
              </div>

              <p>
                His eye fell on the plans for a grand boulevard in the centre of
                city. Of course, he would name it Martin Place.
              </p>
              <p>
                He made a formal pledge to honour Martin by placing, at the top
                of the magnificent avenue, a statue of the man whose motto had
                been:
              </p>
              <p className="p-green">
                Aut viam inveniam aut faciam:
                <br />
                I’ll either find the road, or make it.
              </p>
              <p>And make it he did.</p>
              <p>
                The pledge was later dishonoured by another Premier as an
                economy measure, for a saving of £3,000.
              </p>
            </div>
            <div className="audio-row__audio-container">
              <div className="shadow">
                <StaticImage
                  src="../images/james-martin/lysicrates-james-martin-paper-signed.png"
                  alt="James Martin Paper"
                  layout="constrained"
                  width={2000}
                />
              </div>
              <div className="audio">
                <h6 className="audio__heading">Listen now</h6>
                <p className="audio__control">
                  The Buried Chief{" "}
                  {audioPlaying ? (
                    <span className="icon" onClick={pauseAudio}>
                      <IoIosPause />
                    </span>
                  ) : (
                    <span className="icon" onClick={playAudio}>
                      <IoIosPlay />
                    </span>
                  )}
                </p>
                <Arrow1 className="audio__arrow" />
              </div>
              <div className="description">
                <h6>The Buried Chief</h6>
                <p>
                  With speechless lips and solemn tread,
                  <br />
                  They brought the Lawyer-Statesman home;
                  <br />
                  They laid him with the gathered dead,
                  <br />
                  Where rich and poor like brothers come.
                </p>
                <p>
                  How bravely did the stripling climb
                  <br />
                  From step to step the rugged hill,
                  <br />
                  His gaze thro’ that benighted time
                  <br />
                  Fix’d on the far-off beacon still.
                </p>
                <p>
                  He faced the storm that o’er him burst
                  <br />
                  With pride to match the proudest born;
                  <br />
                  He bore unblenched Detraction’s worst –<br />
                  Paid blow for blow, and scorn for scorn.
                </p>
                <p>
                  He scaled the summit while the sun
                  <br />
                  Yet shone upon his conquered track,
                  <br />
                  Nor faltered till the goal was won
                  <br />
                  Nor, struggling upward, once looked back.
                </p>
                <p>
                  But what avails the “pride of place”,
                  <br />
                  Or winged chariot rolling past?
                  <br />
                  He heeds not now who wins the race
                  <br />
                  Alike to him the first and last.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/*================ END AUDIO ROW ================*/}
        {/*================ START POST SCRIPT ROW ================*/}
        <div className="post-script-row">
          <Reveal effect="slide-right">
            <div className="post-script-row-content">
              <h6 className="post-script-row-content__title">
                <span className="post-script-row-content__title--red">
                  Isabella,
                </span>
                <span className="post-script-row-content__title--black">
                  A post script
                </span>
              </h6>
              <p>Some years later, Isabella began to feel guilty.</p>
              <p>
                She had a large structure built in the classical style in
                Waverley Cemetery to house Martin’s remains, and took a decision
                to be buried there too.
              </p>
              <p>
                On the vault is a plaque commemorating Charles Badham Martin
                (named after Martin’s best friend Charles Badham, second
                Professor of Classics at Sydney University, and one of the
                foremost hellenists of his generation), the eighth son of James
                and Isabella, who was killed in action in 1918 in France, only a
                few days before the Armistice.
              </p>
            </div>
          </Reveal>
        </div>
        {/*================ END POST SCRIPT ROW ================*/}
        {/*================ START BOTTOM QUOTE ROW ================*/}
        <div className="bottom-quote-row">
          <img
            className="bottom-quote-row__rip-top"
            src={whiteRipTop}
            alt="rip paper"
          />
          <div className="bottom-quote-row-content">
            <p>
              Knowing James Martin’s love of Greek culture, Isabella
              commissioned this grand vault in the Grecian style as their joint
              resting place, and had it positioned so that they would forever
              look out together over the majestic Pacific Ocean.
            </p>
          </div>
          <img
            className="bottom-quote-row__rip-bottom"
            src={whiteRipBottom}
            alt="rip paper"
          />
        </div>
        {/*================ END BOTTOM QUOTE ROW ================*/}`{" "}
      </Wrapper>
    </Layout>
  );
};

export default JamesMartinPage;
