/*===== Store all commonly use value here =====*/
export const darkBlue = `#152344`;
export const brightGreen = `#7aae28`;
export const headerGrey = `#eeeeee`;
export const scrollerGrey = `#333333`;
export const inputGrey = `#aaa9a9`;
export const headerBlue = `#162345`;
export const backgroundGrey = `#f4f4f4`;
export const lightGrey = `#f7f7f7`;
export const menuBorderGrey = `#c1c1c1`;
export const borderGrey = `#ebeaea`;
export const titleBlack = `#262626`;
export const mainRed = `#a9351d`;
export const mainWhite = `#ffffff`;
export const dirtyWhite = `#efefef`;
export const innerWidth = `1100px`;
export const mainBlack = `#1c1c1c`;
export const mainGreen = `#5a926d`;
export const mainBlue = `#333563`;
export const mainOrange = `#fe8000`;
export const mainGrey = `#747474`;
export const fadeGreen = `#589692`;
// Media queries for devices
export const screen = {
  xSmall: `(min-width: 413px)`, // larger phones
  small: `(min-width: 650px)`, // tablet vertical
  medium: `(min-width: 1160px)`, // tablet horizontal
  large: `(min-width: 1278px)`, // laptop and regular size desktop
  xLarge: `(min-width: 1690px)`, // huge size desktop
  withCursor: `not all and (pointer: coarse)`, // device with cursor. not touch screen devices
};

// Font for heading texts
export const heading = `marydale-regular, sans-serif; font-weight: 400;`;
export const headingBold = `marydale-bold, sans-serif; font-weight: 900;`;
export const headingNormal = `georgia, sans-serif; font-weight: 400;`;
export const headingItalic = `ccbiffbamboom, sans-serif; font-weight: 400;`;

// Font for paragraph texts
export const pText = `open-sans-regular, sans-serif; font-weight: 400;`;
export const pTextLighter = `open-sans-lighter, sans-serif; font-weight: 300;`;
export const pTextBold = `open-sans-bold, sans-serif; font-weight: 900;`;

// Font for buttons
export const fontButton = `Arial, Helvetica, sans-serif;`;

// Font for header tag
export const fontTagLight = `montserrat, sans-serif; font-weight: 300;`;
export const fontTagNormal = `montserrat, sans-serif; font-weight: 400;`;
export const fontTagBold = `montserrat, sans-serif; font-weight: 700;`;

// hover trasition
export const transHover = `all 0.3s ease-out`;

// regext for email validation
export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
