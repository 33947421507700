// Every page container
import React from "react";
import PropTypes from "prop-types";
import "./css/global.css";
import "./css/normalize.css";
import Header from "./components/header";
import Footer from "./components/footer";
import Scroller from "./components/scroller";
import NewsletterPopup from "./components/newsletter-popup";
import styled from "styled-components";

const PageContainer = styled.div``;

const Layout = ({ children }) => {
  return (
    <PageContainer>
      <Header />
      {children}
      <Footer />
      <Scroller />
      <NewsletterPopup />
    </PageContainer>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
